import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'cta-md-form-gas-station',
  templateUrl: './md-form-gas-station.component.html',
  styleUrls: ['./md-form-gas-station.component.scss']
})

export class MdFormGasStationComponent {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,

  ){
    if(data && data.values){
      this.showButton = true
    }
   }
  dispachForm: boolean;
  showButton: boolean;
  success: boolean;
  addOther: boolean;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }


}
