import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboarPagedModule),
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'empresas',
    loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'motoristas',
    loadChildren: () => import('./pages/drivers/drivers.module').then(m => m.DriversModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'frentistas',
    loadChildren: () => import('./pages/attendants/attendants.module').then(m => m.AttendantsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'garagens',
    loadChildren: () => import('./pages/gas-station/gas-station.module').then(m => m.GasStationModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'tanques',
    loadChildren: () => import('./pages/tank/tank.module').then(m => m.TankModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'bombas',
    loadChildren: () => import('./pages/pumps/pumps.module').then(m => m.PumpsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'veiculos',
    loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'preferencias',
    loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'notificacoes',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'carregamentos',
    loadChildren: () => import('./pages/deliveries/deliveries.module').then(m => m.DeliveriesModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'chips',
    loadChildren: () => import('./pages/chips/chips.module').then(m => m.ChipsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'distribuidor',
    loadChildren: () => import('./pages/distributor/distributor.module').then(m => m.DistributorModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: `reload`
  })],

  exports: [RouterModule],

})
export class AppRoutingModule { }
