import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'cta-table-distributors',
  templateUrl: './table-distributors.component.html',
  styleUrls: ['./table-distributors.component.scss']
})
export class TableDistributorsComponent implements OnChanges, OnInit {

  @Input() listDistributor: any[] = [];
  @Input() filtersDinamics: any;
  @Output() actionEvent = new EventEmitter();
  @Input() defaultDateVal: object;
  @Output() eventPagination = new EventEmitter();
  dataDistributor: any[];
  countSelecteds: number;
  selectedsItens: any[] = [];
  pagination: Pagination;

  disableActionsEvent = true;
  constructor() { }
  headers: any[] = [];

  configsTable = [
    {
      name: 'editTank',
      icon: 'mdi-pencil-box',
      tooltip: 'Editar Tanque'
    },
  ];

  ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
    this.getDistributors();
  }

  ngOnInit() {

    this.getHeadersTable();
    this.getDistributors();
  }

  getDistributors = () => {
    this.dataDistributor = [];
    if (this.listDistributor.length) {
      this.dataDistributor = this.listDistributor.map(tank => ({
        id: tank.id,
        client: tank.fuel_tank.gas_station.client.name,
        tank: tank.fuel_tank.name,
        product: tank.fuel_tank.product.name,
        capacity: tank.fuel_tank.capacity,
        estimate_occupation: Math.round(tank.estimate_occupation),
        reliability: tank.reliability,
        dt_last_update: this.convertDateUtc(tank.dt_last_update),
        free_space: Math.round(tank.free_space),
        contract_type: tank.fuel_tank.contract_type ? tank.fuel_tank.contract_type.name : '',
        asset_number: tank.asset_number
      }));
    }
  }

  getHeadersTable = () => {

      this.headers = [
      {
          id: 1,
          title: 'Cliente',
          value: 'client'
      },
      {
        id: 2,
        title: 'Tanque',
        value: 'tank'
      },
      {
        id: 3,
        title: 'Produto',
        value: 'product'
      },
      {
        id: 4,
        title: 'Capacidade Total',
        value: 'capacity'
      },
      {
        id: 5,
        title: 'Estimativa Atual',
        value: 'estimate_occupation'
      },
      {
        id: 6,
        title: 'Confiabilidade',
        value: 'reliability'
      },
      {
        id: 7,
        title: 'Data de atualização',
        value: 'dt_last_update'
      },
      {
        id: 8,
        title: 'Espaço Livre',
        value: 'free_space'
      },
      {
        id: 9,
        title: 'Tipo de Contrato',
        value: 'contract_type'
      },
      {
        id: 10,
        title: 'Nr. Patrimônio',
        value: 'asset_number'
      }
    ];
  }

  convertDateUtc(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return '';
    }
  }

  setSelected = (event) => {

  }

  dispareAction = (event) => {
    this.actionEvent.emit(event);
  }

  getDataPage = (value) => {
    this.eventPagination.emit(value);
    this.getDistributors();
  }
}

