import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cta-date-input-value',
  templateUrl: './date-input-value.component.html',
  styleUrls: ['./date-input-value.component.scss']
})
export class DateInputValueComponent implements OnInit {

  @Output() dateHourEvent = new EventEmitter();
  @Input() title: string;
  @Input() DefaultDateVal: number;
  @Input() labelInputed: string;
  @Input() required = true;
  dateInputed: Date
  dateValues: FormGroup;
  openCalendar = false;
  date: Date = new Date();
  dateInitialPicker: any;
  settings: FlatpickrOptions
  utcValueDate: any
  dateValue: any
  ngOnInit(): void {

    if(this.DefaultDateVal && this.DefaultDateVal !== undefined){
      this.dateInputed =  new Date(this.DefaultDateVal)
    }

    const settingsPiker = {
      defaultDate:  this.dateInputed  ?  this.dateInputed  : new Date(),
      locale: Portuguese,
      altInputClass: 'input-date-values',
      time_24hr: true,
      enableTime: true,
      dateFormat: 'd/m/Y H:i:S',
      defaultHour: Number,
      clickOpens: true,
      plugins:  [
         confirmDatePlugin({
          confirmIcon: `<i class='fa fa-check confirm-calendar'></i>`,
          confirmText: `CONFIRMAR`,
          showAlways: true,
          theme: 'dark'
        }
      ),
    ],
      enableSeconds: true,
      allowInput: true,
      key: 'calendar-filter',
      shorthandCurrentMonth: true,
      onClose: () => this.eventCloseCalenadar(),
      onOpen: () => this.eventOpenCalendar(),
    };

    this.settings = settingsPiker;
    this.dateHourEvent.emit();

  }

  constructor() {}

  eventCloseCalenadar = () => {
    const initDate =  (document.getElementsByClassName('ng2-flatpickr-input')[0] as HTMLInputElement).value;
    const andDate  =  (document.getElementsByClassName('ng2-flatpickr-input')[1] as HTMLInputElement).value;
    const startUtcDate = this.convertUtc(initDate);
    const andDateUtc = this.convertUtc(andDate);

    const objDate = {
      initUtcDate: startUtcDate,
      andUtcDate: andDateUtc
    }

    this.dateHourEvent.emit(objDate);
  }

  convertUtc = (date) => {
    const valuesDate = date.split('/');
    const yearDate = valuesDate[2].split(" ")
    const hourDate = yearDate[1].split(":")

    const year = parseInt(yearDate[0])
    const month = parseInt(valuesDate[1])
    const day = parseInt(valuesDate[0])
    const hour = parseInt(hourDate[0])
    const minutes = parseInt(hourDate[1])
    const secounds = parseInt(hourDate[2])

    const utcValueDate = Date.UTC(year, month, day, hour, minutes, secounds);
    return utcValueDate;
  }

  eventOpenCalendar = () => {
    this.openCalendar = true;
  }
}
