import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule} from '@angular/common';
import { TableComponent } from './table/table.component';
import { SearchComponent } from './search/search.component';
import { TableSearchComponent } from './table-search/table-search.component';
import { PipesModule } from 'src/app/services/helpers/pipes/pipes.module';
import { PaginationComponent } from './pagination/pagination.component';
import { GenerateFilesModule } from '../generate-files/generate-files.module';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { registerLocaleData } from '@angular/common';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import localePt from '@angular/common/locales/pt';
import { ComponentsModule } from '../components.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import { ReportsFilterComponent } from './reports-filter/reports-filter.component';
import { TableReportComponent } from './table-report/table-report.component';
import { MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxLoadingModule } from 'ngx-loading';
import { TableDeliveriesComponent } from './tables-pages/table-deliveries/table-deliveries.component';
import { TableDistributorsComponent } from './tables-pages/table-distributors/table-distributors.component';
import { TdCaseComponent } from './td-case/td-case.component';
import { ListFiltersComponent } from './list-filters/list-filters.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ConfigHeaderComponent } from './config-header/config-header.component'
import { ReportPdfComponent } from './report-pdf/report-pdf.component';
import { DndModule } from 'ngx-drag-drop';
registerLocaleData(localePt, 'pt-BR');
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  declarations: [
    TableComponent,
    SearchComponent,
    TableSearchComponent,
    PaginationComponent,
    ReportsFilterComponent,
    TableReportComponent,
    TableDeliveriesComponent,
    TableDistributorsComponent,
    TdCaseComponent,
    ListFiltersComponent,
    ConfigHeaderComponent,
    ReportPdfComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    GenerateFilesModule,
    AngularDateTimePickerModule,
    Ng2FlatpickrModule,
    TooltipModule,
    ComponentsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    DndModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxLoadingModule.forRoot({}),
  ],
  exports: [
      TableComponent,
      TableSearchComponent,
      ReportsFilterComponent,
      TableReportComponent,
      SearchComponent,
      PaginationComponent,
      TableDeliveriesComponent,
      TableDistributorsComponent,
      ListFiltersComponent,
      ConfigHeaderComponent,
      ReportPdfComponent
  ],
  entryComponents: [ConfigHeaderComponent, ReportPdfComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ]
})
export class TablesModule { }
