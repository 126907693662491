import { Component, OnInit, Inject, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cta-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent {

  constructor(private dialogRef: MatDialogRef<ConfirmActionComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
   }

   data: any;
   @Input() title: any[];
   @Output() actionButton = new EventEmitter();

   submitActionButton = event => {
     this.actionButton.emit(event);
   }

}
