import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-md-adjust-stock',
  templateUrl: './md-adjust-stock.component.html',
  styleUrls: ['./md-adjust-stock.component.scss']
})
export class MdAdjustStockComponent {

  constructor() { }
  dispachForm: boolean;
  showButton: boolean;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }
}
