import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TopButtons } from '../../models/top-buttons';

@Component({
  selector: 'cta-customizer-top-layout',
  templateUrl: './customizer-top-layout.component.html',
  styleUrls: ['./customizer-top-layout.component.scss']
})
export class CustomizerTopLayoutComponent implements OnInit {
  messageAction: any;
  showNotifications: boolean;
  user = {timezone: 'America/Araguaina'};
  placeholderString = 'Selecione seu timezone';
  constructor() {}
  @Output() eventTopButton = new EventEmitter();
  @Input() styleButtons: TopButtons[];
  ngOnInit() {
  }

  changeTimezone(timezone) {
    this.user.timezone = timezone;
  }

  eventButton = (event: string) => {
    this.eventTopButton.emit(event);
  }

  changeSelectedFilter = (event) => {
    const eventSelect = {
      action: 'select',
      value: event.target.value
    }
    this.eventTopButton.emit(eventSelect);
  }

  toogleNotifications = () => {
   this.showNotifications = !this.showNotifications;
  }
}
