import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'cta-filters-distributors',
  templateUrl: './filters-distributors.component.html',
  styleUrls: ['./filters-distributors.component.scss']
})
export class FiltersDistributorsComponent implements OnInit {
  filtrar: FormGroup;

  dinamicSelects: any[] = [];
  filtersDinamics: any[];
  valueClient: any;
  gasStation: any;
  valueTank: any;
  valueProduct: any;
  valueCapacity: any;
  valueContractType: any;
  valueDtLastUpdate: any;
  valueFreeSpace: any;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FiltersDistributorsComponent>,

  ) {

    this.filtrar = this.fb.group({
      client_name: this.fb.control(null),
      product_name: this.fb.control(null),
      capacity: this.fb.control(null),
      contract_description: this.fb.control(null),
      dt_last_update: this.fb.control(null),
      free_space: this.fb.control(null),
    })

    let getFiltersSession = sessionStorage.getItem('sessionDistributorFilters');
    let filtersSession = JSON.parse(getFiltersSession);
    if(filtersSession && this.filtrar){

      this.valueClient = filtersSession.client_name;
      this.valueProduct = filtersSession.product_name;
      this.valueContractType = filtersSession.contract_description

      this.filtrar.setValue({
        client_name: filtersSession.client_name,
        product_name: filtersSession.product_name,
        capacity: filtersSession.capacity,
        contract_description: filtersSession.contract_description,
        dt_last_update: filtersSession.dt_last_update,
        free_space: filtersSession.free_space,
      })
    }
  }


  ngOnInit() {

  }

  selectedEvents = (event, value) => {

    if (event === 'client_name') {
      this.valueClient = value;
    }

    if (event === 'product_name') {
      this.valueProduct = value;
    }

    if (event === 'capacity') {
      this.valueCapacity = value;
    }

    if (event === 'contract_description') {
      this.valueContractType = value;
    }

    if (event === 'dt_last_update') {
      this.valueDtLastUpdate = value;
    }

    this.filtrar.setValue({
      client_name: this.valueClient !== undefined ? this.valueClient : this.filtrar.value.client_name,
      product_name: this.valueProduct !== undefined ? this.valueProduct : this.filtrar.value.product_name,
      capacity: this.valueCapacity !== undefined ? this.valueCapacity : this.filtrar.value.capacity,
      contract_description: this.valueContractType !== undefined ? this.valueContractType : this.filtrar.value.contract_description,
      dt_last_update: this.filtrar.value.dt_last_update,
      free_space: this.filtrar.value.free_space
    });
  }

  onSubmit = () => {
    sessionStorage.setItem('sessionDistributorFilters', JSON.stringify(this.filtrar.value));
    this.dialogRef.close(this.filtrar.value);
  }

  getDateHour = (event) => {

    console.log("DATE LAST UPDATE");
    console.log(event);

    const andDate = event ? event.andUtcDate : null;
    this.filtrar.setValue({
      client_name: this.filtrar.value.client_name,
      product_name: this.filtrar.value.product_name,
      capacity: this.filtrar.value.capacity,
      contract_description: this.filtrar.value.contract_description,
      dt_last_update: andDate,
      free_space: this.filtrar.value.free_space,
    })
  }
}
