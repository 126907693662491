import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './table/filter.pipe';
import { PaginationPipe } from './table/pagination.pipe';
import { OrderPipe } from './table/order.pipe';
import { FormatnumberPipe } from './formatnumber.pipe';

@NgModule({
  declarations: [FilterPipe, PaginationPipe, OrderPipe, FormatnumberPipe],
  imports: [
    CommonModule
  ],
  exports: [FilterPipe, PaginationPipe, OrderPipe, FormatnumberPipe]
})
export class PipesModule { }
