import { Component, OnInit, Input } from '@angular/core';
import * as jsPDF from 'jspdf'
import { } from 'protractor';

@Component({
  selector: 'cta-pdf-file',
  templateUrl: './pdf-file.component.html',
  styleUrls: ['./pdf-file.component.scss']
})
export class PdfFileComponent implements OnInit {


  constructor() { }
  @Input() list: any[];
  @Input() filename = 'relatorio';
  headList: any[] = [];
  bodyTable: any[] = [];

  ngOnInit() {

  }

  generateFile() {

    if (this.list.length) {
      this.headList = this.list.filter(item => item.head)[0]
      const dataTable = this.list.filter(item => !item.head)

      let myProps = [];

      for (const item of dataTable) {
        let propsIn = Object.keys(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            myProps.push(element);
          }
        });
      }

      const bodyFile: any[] = [];
      for (const item of this.list) {
        let line = [];
        for (const prop of myProps) {

          line.push(item[prop]);

          if (line.length === myProps.length) {
            if (!bodyFile.includes(line)) {
              line.shift();
              bodyFile.push(line);
            }
            line = [];
          }
        }
      }

      let doc = new jsPDF();
      doc.autoTable({
        head: [this.headList['head']],
        body: bodyFile
      })

      doc.save(`${this.filename}.pdf`);
    }


  }
}
