import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {

  transform(data: any[], prop: any): any[] {
    if (data.length && prop) {
      data.sort( (a, b) => {
        if (a[prop] > b[prop]) {
          return 1;
        }
        if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      });

      return data;
    }
    return data;
  }

}
