import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cta-tooltip-data-icon',
  templateUrl: './tooltip-data-icon.component.html',
  styleUrls: ['./tooltip-data-icon.component.scss']
})
export class TooltipDataIconComponent implements OnInit {

  constructor() { }
  @Input() valueMarker: any;
  ngOnInit() {
  }

}
