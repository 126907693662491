export class Pagination {

  maxPerpage: number;
  currentPage: number;
  constructor(maxPerpage: number = 10, currentPage: number = 1) {
    this.maxPerpage = maxPerpage;
    this.currentPage = currentPage;
  }

}
