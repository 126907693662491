const date = new Date();
const StartDateUtc = Date.UTC(
  date.getUTCFullYear(),
  date.getUTCMonth()  -1,
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds()
);

const StopDateUtc = Date.UTC(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds()
);

export class ConfigurationDate{
  startDate: number
  stopDate: number

  constructor(startDate = StartDateUtc, stopDate = StopDateUtc){
    this.startDate = startDate
    this.stopDate = stopDate
  }
}
