import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';
import { Pagination } from '../../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class PumpsService extends CrudService {
  getFilters(filters: any, pagination: Pagination) {
    if (
      filters && filters.hasOwnProperty('parameter')
      && filters.hasOwnProperty('search')
      && filters.search !== ""
      && filters.search !== null
  ) {
    this.apiPath = `${URLS_DEFAULT.PUMPS}?${filters.parameter}=${filters.search}&sort=-name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`;
  } else {
    this.apiPath = `${URLS_DEFAULT.PUMPS}?sort=+name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`;
  }

  return this.findItem()
  }

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  createPumps(pump): Observable<any> {
    this.apiPath = URLS_DEFAULT.PUMPS;
    return this.create(pump)
  }

  getPumpsAll(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.PUMPS;
    return this.findItem();
  }

  getPumpById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.PUMPS;
    return this.getById(id);
  }

  getPumpByTank (idTank: number): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.PUMPS}?fuel_tank=${idTank}`
    return this.getAll();
  }

  deletePump = (id) => {
    this.apiPath = URLS_DEFAULT.PUMPS;
    return this.delete(id)
  }
}
