import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PumpsService } from 'src/app/services/pumps/pumps.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cta-form-pump',
  templateUrl: './form-pump.component.html',
  styleUrls: ['./form-pump.component.scss']
})

export class FormPumpComponent implements OnChanges, OnInit {

  cadastrar: FormGroup;
  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Output() validForm = new EventEmitter()
  @Input() fuelTankId: number
  @Input() values: any;
  valueClient: number;
  valueGasStation: number;
  valueFuelTank: number;
  constructor(
    private fb: FormBuilder,
    private toastService: ToastrService,
    private pumpService: PumpsService

  ) {

  }


  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }

  submitValues = () => {
    if(this.values){
      this.pumpService
        .update(this.cadastrar.value)
        .subscribe(
          () => {
            this.toastService.success("Sucesso ao editar a bomba")
          },
          err => {
            this.toastService.warning("Ocorreu uma falha ao tentar salvar a bomba")
          }
        )

    }else{
      this.pumpService.createPumps(this.cadastrar.value).subscribe(
        () => {
          this.toastService.success("Bomba cadastrada com sucesso")
        },
        err => {
          this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o posto")
          console.log('Ocorreu uma falha ao tentar salvar o posto => ', err)
        }
      )
    }

  }

  ngOnInit() {
    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      name: this.fb.control('', [Validators.required]),
      short_name: this.fb.control(''),
      fuel_tank: this.fb.control(this.fuelTankId)
    })

    if(this.values){
      this.setValuesPump(this.values)
    }
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar && this.cadastrar.valid) {
        this.submitValues();
      }
    }
  }

  setValuesPump = (values) => {
    this.cadastrar.setValue({
      id: values.id,
      name: values.name,
      short_name: values.short_name,
      fuel_tank: values && values.fuel_tank ? values.fuel_tank.id : null
    })
  }

  eventFire = event => {
    this.showButton = event;
  }

  eventSelects($event, select){
    console.log("EVENT SELECT PUMP FORM");
    console.log($event);
    console.log(select);
    switch (select) {
      case 'client':
        this.valueClient = $event
        break;
      case 'gasStation':
        this.valueGasStation = $event
        break;
      case 'fuelTank':
        this.valueFuelTank = $event
        break;
      default:
        break;
    }

    if(this.valueFuelTank){
      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        name: this.cadastrar.value.name,
        short_name: this.cadastrar.value.short_name,
        fuel_tank: this.valueFuelTank
      });
    }
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

}
