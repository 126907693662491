import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { MdFormClientComponent } from '../../forms-pages/md-form-client/md-form-client.component';

@Component({
  selector: 'cta-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss']
})
export class SelectClientComponent implements OnInit, OnChanges {
  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private clientsService: ClientsService
  ) { }


  @Input() selectedInput: any;
  @Input() selected: number;
  @Input() optionValueName: boolean;
  @Input() required = true;
  selectedClient: any;
  @Output() eventSelectedClient = new EventEmitter();
  clients: any[] = [];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
    this.getClients();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedInput) {
      this.selectedClient = this.selectedInput;
    }

  }
  openMd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Adicionar Cliente',
      description: '',
      function: 'create'
    };

    const dialogRef = this.dialog.open(MdFormClientComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getClients();
    });
  }

  getClients = () => {
    this.clientsService.getClients().subscribe(
      res => {

       const data = res.results;
       this.clients  = data.map(item => ({
         id: item.id,
         name: item.name
       }));

       setTimeout(() => {
        this.selectedClient = null;
        const lastSaveClientId = parseInt(localStorage.getItem('lastSaveClient'));
        this.clients .forEach(item => {
          if (item.id === lastSaveClientId) {
            this.selectedClient = item.id;
            this.dispachClient(item.id);
          }
        });

        this.selectedClient = this.selectedInput ? this.selectedInput : null;
      }, 2000);

      },
      err => {
        console.log('err getClients ', err);
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de clientes');
      }
    );
  }

  dispachClient = (clientId) => {
    this.eventSelectedClient.emit(clientId);
  }

}
