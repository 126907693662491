import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cta-tank-map',
  templateUrl: './tank-map.component.html',
  styleUrls: ['./tank-map.component.scss']
})
export class TankMapComponent implements OnInit {

  constructor() { }

  @Input() infosMap = {
    statusAbasteciento: null,
    placa: null,
    tanques: null,
    abastecimentos: null,
    ultimoAbastecimento: null
  };
  info: boolean;
  styleInfo = {};
  ngOnInit() {

  }

  showInfo() {
    const timeout = 1000;
    this.styleInfo = {display: 'block',  animation: 'flipInY  1s linear 0s '};

  }

  hiddenInfo() {
    const timeout = 1000;
    this.styleInfo = {display: 'none', animation: 'flipOutY 1s linear 0s '};

  }
}
