import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-select-users',
  templateUrl: './select-users.component.html',
  styleUrls: ['./select-users.component.scss']
})
export class SelectUsersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
