import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cta-md-delivery-load',
  templateUrl: './md-form-delivery.component.html',
  styleUrls: ['./md-form-delivery.component.scss']
})

export class MdFormDeliveryComponent{

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    if(data){
      this.clientId = data.clientId;
      this.delivery = data.values[0];
    }
   }
  clientId: number;
  dispachForm: boolean;
  showButton: boolean;
  delivery: any;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }
}
