import { Component, OnInit, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cta-form-modal-companies',
  templateUrl: './form-modal-companies.component.html',
  styleUrls: ['./form-modal-companies.component.scss']
})
export class FormModalCompaniesComponent implements OnInit {

  clientId: number
  constructor(@Inject(MAT_DIALOG_DATA) data,) {
    console.log('DATA IN MODAL COMPANIES CLIENTID', data)
    this.clientId = data.clientId,
    this.company = data.company;
   }
  ngOnInit(){

  }
  company: any
  dispachForm: boolean;
  showButton: boolean;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;

  }
}
