import { Component, OnInit, EventEmitter, Output, Compiler } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DriverService } from 'src/app/services/drivers/driver-service';
import { ModalConfirm } from 'src/app/models/modal-confirm';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationDate } from 'src/app/models/configurations/configuration-date.model';
import { MdFormDriverComponent } from 'src/app/components/forms-pages/md-form-driver/md-form-driver.component';
import { UsersService } from 'src/app/services/users/users.service';
import { Pagination } from '../../../../../models/pagination';

@Component({
  selector: 'cta-list-drivers-trr',
  templateUrl: './list-drivers-trr.component.html',
  styleUrls: ['./list-drivers-trr.component.scss']
})
export class ListDriversTrrComponent implements OnInit {


  constructor(
    public dialog: MatDialog,
    private driversService: DriverService,
    private userService: UsersService,
    private toastr: ToastrService,
    private _compiler: Compiler
  ) {
    this._compiler.clearCache()
    this.getList(this.dataFilters);
  }

  ngOnInit(){
    this.headersTable = [
      {
        "id": 1,
        "title": "Nome",
        "value": "name"
      },
      {
        "id": 2,
        "title": "CPF",
        "value": "cpf"
      }
    ];

    this.eventsInSelect = [
      {
        action: 'delete',
        icon:'mdi-trash-can',
        description: 'Excluir '
      }
    ];

    this.configsTable = [
      {
        name: 'edit',
        icon:'mdi-square-edit-outline',
        description: 'Editar ',
        tooltip: "Editar motorista"
      }
    ];

    this.topButtons = [
      {
        style: "btn  btn-orange",
        title: "Novo motorista",
        icon: "mdi-plus-circle",
        event: "create"
      }
    ]
  }

  @Output() selectedsEvent = new EventEmitter();
  DefaultDateVal = new Date()
  dataModal: ModalConfirm[];
  drivers: any[];
  headersTable: object[] = [];
  topButtons: object[];
  configsTable: any[];
  configsModal: any;
  showMd: boolean;
  productCategorys: any[];
  eventsInSelect: any[];
  pagination = new Pagination(10, 1);
  dataFilters = {
    date: new ConfigurationDate,
    product: 1,
    parameter: null,
    search: null,
    removed: false
  }

  filtersDinamicsSelecteds: any[]
  filtersDinamics = [
    {
      id: 1,
      label: 'CPF',
      control: 'cpf',
      required: false,
    },

  ];

  getList = (filters) => {

    let dataFilters = filters;
    if (!filters) {
      dataFilters = this.dataFilters
    }

    this.drivers = []
    this.driversService.getFilters(dataFilters, this.pagination).subscribe(
      res => {

        this.drivers = res.results.map(item => ({
          id: item.id,
          name: item.name,
          cpf: item.cpf ? item.cpf : 'Ainda não informado'
        }));

      },
      error => {
        this.toastr.warning('Os dados que você está tentando ver não foram atualizados, devido a algum erro.')
        console.log(error);

      }
    )
  }


  actionEvent = (event) => {

    const action = event.hasOwnProperty('action') ? event.action : event ;
    const date = {
      startDate: event.startDateHour,
      stopDate: event.stopDateHour
    }

    switch (action) {
      case 'edit':
        this.driversService.getDriversById(event.item.id).subscribe(
          res => {
            this.create(res.results);
          },
          err => {
            console.log(err);
          }
        );
        break;
      case 'create':
          this.create([]);
        break
      case 'select':
        this.dataFilters.product = event.value
        this.getList(this.dataFilters)
        break
      case 'delete':
        this.deleteDriver(event.data)

        break
      case 'search':
        this.dataFilters.parameter =
          event.hasOwnProperty('data') && event.data.parameter
            ? event.data.parameter
            : this.dataFilters.parameter
        this.dataFilters.search = event.data.searchWord
        console.log("EVENT DRIVER", event);
        this.getList(this.dataFilters)
        break
      case 'dateHourEvent':
        this.dataFilters.date = date
        this.getList(this.dataFilters)
        break
      case 'filters':
        console.log('filters selected and dispacth event ===>>> ', event.data)
        this.filtersDinamicsSelecteds = event.data

        this.getList(this.dataFilters)
        break
      default:
        this.getList(this.dataFilters)
        break;
    }

  }

  setSelecteds = event => {
    this.selectedsEvent.emit(event);
  }

  create(values: any) {

    let configs = {
      title: null,
      description: '',
      function: 'create',
      button: 'Salvar',
      buttonPlus: null,
      values: values.length ? values : []
    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = configs;
    const dialogRef = this.dialog.open(MdFormDriverComponent, dialogConfig);
    dialogRef.afterClosed()
      .subscribe(() => {
        this.getList(this.dataFilters);
      });
  }


  deleteDriver = (values) => {

    values.forEach(element => {
      this.userService.getUserCategoryId(element.id).subscribe(
        res => {
          const user = res.results[0];
          const dataUser = {
            id: user.id,
            deleted: true
          }
          this.userService.updateUser(dataUser).subscribe(
            res => {
              const dataDriver = {
                id: element.id,
                removed: true
              }

              this.driversService.updateDriverTrr(dataDriver).subscribe(
                () => {
                  this.toastr.success( `Motorista deletado conforme solicitado`);
                  this.getList(this.dataFilters);
                },
                () => {
                  this.toastr.warning('Não foi possível deletar o motorista. Verifique se o mesmo não possui vinculos.')
                }
              )
            },
            err => {
              this.toastr.error("Ocorreu uma falha ao tentar deletar o usuário");
              console.log("erro ao tentar deletar usuário", err);
            }
          )
        },
        err => {
          console.log("erro ao tentar fazer o get de usuario motorista", err);
        }
      )
    });
  }

  eventPagination = (pagination: Pagination) => {
    this.pagination = pagination;
    this.getList(this.dataFilters);
  }

}
