import { Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';
import { CrudGenericServicce } from 'src/app/services/CrudGeneric.service';
import { TopButtons } from 'src/app/models/top-buttons';
import { ModalConfirm } from 'src/app/models/modal-confirm';
import { HeadTable } from 'src/app/models/configurations/head.model';
import { Pagination } from '../../../../models/pagination';

@Component({
  selector: 'cta-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit {

  @Input() list: any[] = [];
  @Input() configsTable: object;
  @Input() headers: HeadTable[];
  @Input() DefaultDateVal: any;
  @Input() disabledDateHour: boolean;
  @Output() actionList = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();
  @Output() eventPagination = new EventEmitter();
  @Output() eventButton = new EventEmitter();
  @Input() stylesTopButtons: TopButtons[];
  @Input() dataModal: ModalConfirm[];
  @Input() filtersDinamics: any[];
  @Input() disableActionsEvent: boolean;
  @Input() eventsInSelect: any[];

  dataModalValue: ModalConfirm[];
  selectedsArray = 0;
  constructor(public serviceList: CrudGenericServicce) {

  }



  ngOnInit() {
    this.dataModalValue = this.dataModal || [{
      title: 'Você tem certeza que deseja executar esta ação?',
      description: '',
      functions: 'delete'
    }];
  }

  actionTable = (event) => {
    this.actionList.emit(event);
  }

  eventTopButton = event => {
    this.eventButton.emit(event);
  }

  setSelecteds = event => {
    this.selectedsArray = event;
  }

  dispachPagination = (pagination: Pagination) => {
    this.eventPagination.emit(pagination);
  }
}
