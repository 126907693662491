import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../crud.service';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';
import { Observable } from 'rxjs';
import { RestModel } from 'src/app/models/configurations/rest.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService extends CrudService {

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  createShipment(shipment): Observable<any> {
    this.apiPath = URLS_DEFAULT.SHIPMENT_DELIVERIES;
    return this.create(shipment)
  }

  updateShipment(shipment){
    this.apiPath = URLS_DEFAULT.SHIPMENT_DELIVERIES;
    return this.update(shipment);
  }

  getShipmentById(shipmentId): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.SHIPMENT_DELIVERIES}?id=${shipmentId}`;
    return this.findItem();
  }

  deleteShipment= (id) => {
    this.apiPath = URLS_DEFAULT.SHIPMENT_DELIVERIES;
    return this.delete(id)
  }
}
