import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Companie } from 'src/app/models/companie/companie';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { ToastrService } from 'ngx-toastr';
import { STATES } from '../../../data/states';
@Component({
  selector: 'cta-form-companies',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormCompanieComponent implements OnChanges, OnInit {

  cadastrar: FormGroup;
  data: any;

  @Input() dispachForm: boolean;
  @Input() clientId: number
  @Input() company: any
  @Output() validForm = new EventEmitter();
  states = STATES;
  labelsForm: any;
  dataCompany: Companie;
  regexCnpjMask: RegExp = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
  regexPhoneMask: RegExp = /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;



  constructor(private fb: FormBuilder, private companieService: CompaniesService, private toastr: ToastrService) {

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      name: this.fb.control('', [Validators.required, Validators.maxLength(50)]),
      cnpj: this.fb.control(''),
      short_name: this.fb.control(''),
      cep: this.fb.control(''),
      address: this.fb.control(''),
      city: this.fb.control('', [Validators.required]),
      neighborhood: this.fb.control(''),
      phone: this.fb.control(''),
      state: this.fb.control('sp', [Validators.required]),
      liaison: this.fb.control(''),
      branch_code: this.fb.control(null),
      client: this.clientId  ? this.fb.control(this.clientId, [Validators.required]) : this.fb.control(null, [Validators.required])
    });
 }


 ngOnInit (){
   if(!this.company || this.company === null){
    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      name: this.cadastrar.value.name,
      cnpj: this.cadastrar.value.cnpj,
      short_name: this.cadastrar.value.short_name,
      cep: this.cadastrar.value.cep,
      address: this.cadastrar.value.address,
      city: this.cadastrar.value.city,
      neighborhood: this.cadastrar.value.neighborhood,
      phone: this.cadastrar.value.phone,
      state: this.cadastrar.value.state,
      liaison: this.cadastrar.value.liaison,
      branch_code: this.cadastrar.value.branch_code,
      client: this.clientId ? this.clientId : this.cadastrar.value.client
    });
   }else{

    this.clientId = this.company.hasOwnProperty("client") ? this.company.client.id : null
    this.cadastrar.setValue({
      id: this.company.id,
      name: this.company.name,
      cnpj: this.company.cnpj,
      short_name: this.company.short_name,
      cep: this.cadastrar.value.cep,
      address: this.company.address,
      city: this.company.city,
      neighborhood: this.company.neighborhood,
      phone: this.company.phone,
      state: this.company.state,
      liaison: this.company.liaison,
      branch_code: this.company.branch_code,
      client: this.clientId
    });
   }

 }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['dispachForm']) {
      if(this.dispachForm && this.validForm){
        this.submitValues();
      }
    }
  }

  submitValues(){
    if(!this.company){
      this.companieService.createCompanie(this.cadastrar.value).subscribe(
        res => {
          localStorage.setItem('lastSaveCompanieId', res.results[0].id);
          this.toastr.success('Empresa salva com sucesso')

        }, err => {
          this.toastr.warning('Ocorreu uma falha ao tentar salvar a empresa');
        }
      );
    }else{
      this.companieService.updateCompanie(this.cadastrar.value).subscribe(
        res => {
          localStorage.setItem('lastSaveCompanieId', res.results[0].id);
          this.toastr.success('Empresa editada com sucesso', 'Formulário salvo', {timeOut: 5000})

        }, err => {
          this.toastr.warning(`Ocorreu uma falha ao tentar editar empresa ${this.company.name}`);
        }
      )
    }
  }


  eventSelect = (event) => {
    this.clientId = event;
    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      name: this.cadastrar.value.name,
      cnpj: this.cadastrar.value.cnpj,
      short_name: this.cadastrar.value.short_name,
      cep: this.cadastrar.value.cep,
      address: this.cadastrar.value.address,
      city: this.cadastrar.value.city,
      neighborhood: this.cadastrar.value.neighborhood,
      phone: this.cadastrar.value.phone,
      state: this.cadastrar.value.state,
      liaison: this.cadastrar.value.liaison,
      branch_code: this.cadastrar.value.branch_code,
      client: this.clientId
    })

    this.validForm.emit(this.cadastrar.valid);
  }

  submitEmmiterButton = (event: any) => {
    this.validForm.emit(this.cadastrar.valid);
  }

  changeCep = () => {

    this.companieService.getCep(this.cadastrar.value.cep).subscribe(res => {
      
      let dataLocal = res;

      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        name: this.cadastrar.value.name,
        cnpj: this.cadastrar.value.cnpj,
        short_name: this.cadastrar.value.short_name,
        cep: this.cadastrar.value.cep,
        address: dataLocal.logradouro,
        city: dataLocal.localidade,
        neighborhood: dataLocal.bairro,
        phone: this.cadastrar.value.phone,
        state: dataLocal.uf.toLowerCase(),
        liaison: this.cadastrar.value.liaison,
        branch_code: this.cadastrar.value.branch_code,
        client: this.cadastrar.value.client,
      });

    }, err => {
      console.log("error sdatacep is =====>>>> ", err);
    })
    
  }

  changeCnpj = () => {
    this.companieService.getCnpj(this.cadastrar.value.cnpj).subscribe(res => {
      
      let dataCompanie = res;
      console.log("dados cnpj ===> ", dataCompanie)
      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        name: dataCompanie.nome,
        cnpj: this.cadastrar.value.cnpj,
        short_name: dataCompanie.fantasia,
        cep: dataCompanie.cep,
        address: dataCompanie.logradouro,
        city: dataCompanie.municipio,
        neighborhood: dataCompanie.bairro,
        phone: dataCompanie.telefone,
        state: dataCompanie.uf.toLowerCase(),
        liaison: this.cadastrar.value.liaison,
        branch_code: this.cadastrar.value.branch_code,
        client: this.cadastrar.value.client,
      });

    }, err => {
      console.log("error sdatacep is =====>>>> ", err);
    })
  }

}
