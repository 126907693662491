import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FormModalTankComponent } from '../../forms-pages/modais-tank/form-modal/form-modal.component';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'cta-select-fuels',
  templateUrl: './select-fuels.component.html',
  styleUrls: ['./select-fuels.component.scss']
})
export class SelectFuelsComponent implements OnInit {


  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private productService: ProductsService
  ) { }
  selectedProduct: any;
  @Output() eventSelectedProduct = new EventEmitter();
  @Input() selectedInput: any;
  @Input() required = true;
  products: any[] = [];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
    this.getProducts()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.selectedInput){
      this.selectedProduct = this.selectedInput
    }
  }

  openMd(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Adicionar Frentsita',
      description: '',
      function: 'create'
    };

    const dialogRef = this.dialog.open(FormModalTankComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log('Quando fechar o modal faz...', false);
    });
  }

  getProducts = () => {
    this.productService.getProductsAll().subscribe(
      res => {
       const data = res.results
       data.map(item => ({
         id: item.id,
         name: item.name
       }))
       this.products = data

      },
      err => {
        console.log('err getProducts ', err)
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de produtos')
      }
    )
  }

  dispachProduct = (product) => {
    this.eventSelectedProduct.emit(product);
  }
}
