import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { min } from 'rxjs/operators';
import { Pagination } from 'src/app/models/pagination';


@Component({
  selector: 'cta-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {


  constructor() { }

  @Output() pageEvent = new EventEmitter();
  @Input() list: any[];
  @Input() maxPerPage = 10;
  currentPage = 1;
  pagesIn: number[];
  itensPerPage: number[] = [10, 30, 50, 100];

  ngOnInit() {
    this.setPagination();
  }

  setPagination = () => {
    if (this.list.length) {
      const lengthList = this.list.length;
      const numberPages = lengthList / this.maxPerPage;
      const pages = [];
      for (let i = 0; i <= numberPages; i++) {
        pages.push(i);
      }

      this.pagesIn = pages;
    }

  }

  setItensPerPage = (nrItens) => {
    this.maxPerPage = nrItens;
    const pagination = new Pagination(this.maxPerPage, this.currentPage);
    this.pageEvent.emit(pagination);
  }
  setPage = (page: number) => {
    this.currentPage = (page + 1);
    const pagination = new Pagination(this.maxPerPage, this.currentPage);
    this.pageEvent.emit(pagination);
  }

  nextPage = () => {
    this.currentPage = this.currentPage + 1;
    const pagination = new Pagination(this.maxPerPage, this.currentPage);
    this.pageEvent.emit(pagination);
  }

  prevPage = () => {
   this.currentPage = this.currentPage - 1;
   const pagination = new Pagination(this.maxPerPage, this.currentPage);
   this.pageEvent.emit(pagination);
  }

  changePagination = (event: any) => {

    this.currentPage = parseInt(event.target.value, 10);
    const pagination = new Pagination(this.maxPerPage, this.currentPage);
    this.pageEvent.emit(pagination);
  }

}
