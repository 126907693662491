import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'cta-filters-deliveries',
  templateUrl: './filters-deliveries.component.html',
  styleUrls: ['./filters-deliveries.component.scss']
})
export class FiltersDeliveriesComponent implements OnInit {
  startDate: any;
  stopDate: any;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FiltersDeliveriesComponent>,
  ) {

    this.filtrar = this.fb.group({
      distributor_driver_name: this.fb.control(null),
      volume: this.fb.control(null),
      unit_cost: this.fb.control(null),
      product: this.fb.control(null),
      divergence: this.fb.control(null),
      startDate: this.fb.control(null),
      stopDate: this.fb.control(null)
    });

    let getFiltersSession = sessionStorage.getItem('filtersDeliveries');
    let filtersSession = JSON.parse(getFiltersSession);

    if(getFiltersSession && this.filtrar){

      this.valueProduct = filtersSession.product;
      this.valueDriver = filtersSession.distributor_driver_name

      this.filtrar.setValue({
        distributor_driver_name: filtersSession.distributor_driver_name,
        volume: filtersSession.volume,
        unit_cost: filtersSession.unit_cost,
        product: filtersSession.product,
        divergence: filtersSession.divergence,
        startDate: filtersSession.startDate,
        stopDate: filtersSession.stopDate
      })
    }

   }

  filtrar: FormGroup;
  dinamicSelects: any[] = [];
  filtersDinamics: any[];
  valueDriver: any = null;
  valueProduct: any = null;
  ngOnInit() {

  }


  selectedEvents = (event, value) => {

    if (event === 'distributor_driver_name') {
      this.valueDriver = value;
    }

    if (event === 'product') {
      this.valueProduct = value;
    }

    this.filtrar.setValue({
      distributor_driver_name: this.valueDriver,
      volume: this.filtrar.value.volume,
      unit_cost: this.filtrar.value.unit_cost,
      product: this.valueProduct,
      divergence: this.filtrar.value.divergence,
      startDate: this.filtrar.value.startDate,
      stopDate: this.filtrar.value.stopDate
    })

  }

  getDateHour(event, name){
    console.log('getDateHour ====>>> ', event )
    if(event && name === 'startDate'){
      this.startDate = event.andUtcDate
    }
    if(event && name === 'stopDate'){
      this.stopDate = event.andUtcDate
    }

    if(this.startDate && this.stopDate){
      this.filtrar.setValue({
        distributor_driver_name: this.valueDriver,
        volume: this.filtrar.value.volume,
        unit_cost: this.filtrar.value.unit_cost,
        product: this.valueProduct,
        divergence: this.filtrar.value.divergence,
        startDate: this.startDate,
        stopDate: this.stopDate
      })
    }
  }

  onSubmit = () => {
    sessionStorage.setItem('filtersDeliveries', JSON.stringify(this.filtrar.value));
    this.dialogRef.close(this.filtrar.value);
  }

}
