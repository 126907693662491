import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})

export class CrudGenericServicce  extends CrudService{


  urlService: string;

  constructor( http: HttpClient) {
    super(http);
    this.apiPath = this.urlService;
  }

  setUrlService = (url: string) => {
    this.urlService = url;
  }

  getList = () => {
    this.getAll();
  }

  getByIdList(id: number): Observable<any>{
    return this.getById(id);
  }

  deleteItemList(companiesList: any): Observable<any>{
    return this.delete(companiesList);
  }


}
