import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'cta-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    localStorage.removeItem('itemSelected');
    localStorage.clear();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.authenticationService.logout();

    this.returnUrl = '/dashboard';

  }

  

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    let tokenRest = null;
    this.authenticationService.generatorTokenRest().subscribe(
      res => {
       tokenRest = res.hasOwnProperty('security_token') ? res.security_token : null       
       sessionStorage.setItem('tokenRest', tokenRest);
       this.login(tokenRest);
      },
      err => {
        console.log(err);
        return false;
      }
    )
  }

  login(tokenRest){
    console.log("test token rest");
    console.log(tokenRest);
    this.loading = true;
    this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password, tokenRest)
      .pipe(first())
      .subscribe(
        res => {
          let distributor = false;
          res.results.forEach(element => {
            if(element.category === "DISTRIBUIDOR"){
              distributor = true
            }
          });
          if(distributor){
            window.location.href = '/distribuidor'
          }else{
            window.location.href = '/dashboard'
          }

        },
        error => {
          this.error = error;
          console.log('error login => ', error)
          this.loading = false;
        });
  }


}
