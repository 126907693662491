export const STATES = [
  {
    sigla: "ac",
    name: "Acre"
  },
  {
    sigla: "al",
    name: "Alagoas"
  },
  {
    sigla: "ap",
    name: "Amapá"
  },
  {
    sigla: "am",
    name: "Amazonas"
  },
  {
    sigla: "ba",
    name: "Bahia"
  },
  {
    sigla: "ce",
    name: "Ceará"
  },
  {
    sigla: "es",
    name: "Espírito Santo"
  },
  {
    sigla: "go",
    name: "Goiás"
  },
  {
    sigla: "ma",
    name: "Maranhão"
  },
  {
    sigla: "mt",
    name: "Mato Grosso"
  },
  {
    sigla: "ms",
    name: "Mato Grosso do Sul"
  },
  {
    sigla: "",
    name: ""
  },
  {
    sigla: "mg",
    name: "Minas Gerais"
  },
  {
    sigla: "pa",
    name: "Pará"
  },
  {
    sigla: "pb",
    name: "Paraíba"
  },
  {
    sigla: "pr",
    name: "Paraná"
  },
  {
    sigla: "pe",
    name: "Pernambuco"
  },
  {
    sigla: "pi",
    name: "Piauí"
  },
  {
    sigla: "rj",
    name: "Rio de Janeiro"
  },
  {
    sigla: "rn",
    name: "Rio Grande do Norte"
  },
  {
    sigla: "rs",
    name: "Rio Grande do Sul"
  },
  {
    sigla: "ro",
    name: "Rondônia"
  },
  {
    sigla: "rr",
    name: "Roraima"
  },
  {
    sigla: "sc",
    name: "Santa Catarina"
  },
  {
    sigla: "sp",
    name: "São Paulo"
  },
  
  {
    sigla: "se",
    name: "Sergipe"
  },
  {
    sigla: "to",
    name: "Tocantins"
  },
  {
    sigla: "df",
    name: "Distrito Federal"
  }
];