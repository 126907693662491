import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationTopComponent } from './navigation-top/navigation-top.component';
import { ContainerComponent } from './container/container.component';
import { OptionsCustomizerRigthComponent } from './options-customizer-rigth/options-customizer-rigth.component';
import { RouterModule } from '@angular/router';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ComponentsModule } from '../components.module';
import { ServiceSettingsComponent } from './service-settings/service-settings.component';
import { ResponsiveNavigationComponent } from './responsive-navigation/responsive-navigation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MenuService } from 'src/app/services/configurations/menu.service';


@NgModule({
  declarations: [
    NavigationTopComponent,
    ContainerComponent,
    OptionsCustomizerRigthComponent,
    SidenavComponent,
    ServiceSettingsComponent,
    ResponsiveNavigationComponent,
  ],
  exports: [
    ContainerComponent
  ],
  providers: [
    MenuService
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    NgSelectModule

  ]
})
export class SharedModule { }
