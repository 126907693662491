import { Component, OnInit, Output, EventEmitter, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { GasStationService } from 'src/app/services/gas-station/gas-station.service';
import { ToastrService } from 'ngx-toastr';
import { MdFormGasStationComponent } from '../../forms-pages/md-form-gas-station/md-form-gas-station.component';



@Component({
  selector: 'cta-select-gas-stations',
  templateUrl: './select-gas-stations.component.html',
  styleUrls: ['./select-gas-stations.component.scss']
})

export class SelectGasStationsComponent implements OnInit, OnChanges {


  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private fuelStation: GasStationService,
    private toast: ToastrService
  ) {

  }
  @Output() eventSelectedGasStation = new EventEmitter();
  @Input() selectedInput: any;
  @Input() idClient: number;
  @Input() required = true;
  gasStationSelected: any;

  postos: any[] = [];
  gasStatioSave: any;
  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';

  }

  ngOnChanges() {
    this.postos = [];
    this.gasStationSelected = this.selectedInput;
    this.getGasStationsByClient();
  }

  openMd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Adicionar Posto',
      description: '',
      function: 'create'
    };

    const dialogRef = this.dialog.open(MdFormGasStationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.gasStationSelected = null;
      this.postos = [];
      setTimeout(() => {
        this.gasStatioSave = parseInt(localStorage.getItem('lastSaveGasStation'));
        this.getGasStations();
      }, 2000);

    });
  }

  getGasStationsByClient = () => {
    if (this.idClient) {
      this.fuelStation.getFuelStationByClient(this.idClient).subscribe(
        res => {
          const data = res.results;

          const postos = data.map(item => ({
            id: item.id,
            name: item.name
          }));

          postos.forEach(element => {
            if (element.id === this.gasStatioSave) {
              this.gasStationSelected = element.id;
              this.changeSelectedPosto(element.id);
            }
          });

          this.postos = postos;
        },
        err => {
          this.toast.warning('Houve um erro ao tentar carregar os dados de postos para o select');
        }
      );
    }

  }

  getGasStations = () => {

    this.fuelStation.getFuelStationAll().subscribe(
      res => {
        const data = res.results;
        const postos = data.map(item => ({
          id: item.id,
          name: item.name
        }));

        postos.forEach(element => {
          if (element.id === this.gasStatioSave) {
            this.gasStationSelected = element.id;
            this.changeSelectedPosto(element.id);
          }
        });

        this.postos = postos;
      },
      err => {
        this.toast.warning('Houve um erro ao tentar carregar os dados de postos para o select');
      }
    );
  }

  changeSelectedPosto = (gasStationSelected) => {
    this.eventSelectedGasStation.emit(gasStationSelected);
  }
}
