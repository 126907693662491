
export class TankShipmentModel{
    id: string
    image_file: any
    note: string
    occupation: string

    constructor(id: null, image_file: null, note: null, occupation: null){
      this.id = id,
      this.image_file = image_file,
      this.note = note,
      this.occupation = occupation
    }
}
