import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'cta-galery-image',
  templateUrl: './galery-image.component.html',
  styleUrls: ['./galery-image.component.scss']
})
export class GaleryImageComponent implements OnInit {

  constructor(public el: ElementRef, private _lightbox: Lightbox) { }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    dots: false,
    navSpeed: 400,
    navText: ['<i class="mdi mdi-arrow-left-drop-circle icon-arrow"></i>', '<i class="mdi mdi-arrow-right-drop-circle icon-arrow"></i>'],
    center: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  @Input() listImages: any[];
  @Input() isAudio: boolean;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const bodyDoc = document.body;
    const htmlDoc = document.documentElement;
    const heightPage = Math.max(
        bodyDoc.scrollHeight,
        bodyDoc.offsetHeight,
        htmlDoc.clientHeight,
        htmlDoc.scrollHeight,
        htmlDoc.offsetHeight
    );
  }

  ngOnInit() {
  }

  showImageZoom = (index: number) => {
    this._lightbox.open(this.listImages, index);
  }
}
