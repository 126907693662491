import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cta-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss']
})
export class ServiceSettingsComponent implements OnInit {
  @Input() show: boolean;
  loading: boolean;
  reportSelected: any;
  filtersLoaded: boolean;
  filtersSelected: any[];
  constructor() { }
  filtros: any[] = [];
  ngOnInit() {
    this.filtros = [
      {id: 1, name: 'Carregamentos'},
      {id: 1, name: 'Abastecimentos'},
      {id: 1, name: 'Média por Motorista'},
      {id: 1, name: 'Abastecimentos por turno'},
      {id: 1, name: 'Frota'},
      {id: 1, name: 'Viagem por veículo'},
      {id: 1, name: 'Economia por veículo'},
      {id: 1, name: 'Lançamentos de Lubrificantes'},
      {id: 1, name: 'Extrato por empresa'},
    ];
  }


  changeSelectedFilter = (event) => {
    if (event) {
      this.loading = true;
      this.reportSelected = event.name;
      setTimeout(() => {
        this.loading = false;
        this.filtersLoaded = false;
      }, 3000);

    } else {
      this.loading = false;
      this.filtersLoaded = true;
      this.reportSelected = null;
      this.filtersSelected = [];
    }

  }

}
