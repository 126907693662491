import { Component, OnInit, Input, EventEmitter, Output, Compiler, OnChanges, SimpleChanges} from '@angular/core';
import { HeadTable } from 'src/app/models/configurations/head.model';
import { isArray } from 'util';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'cta-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {


  constructor() {
  }

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @Input() list: any[];
  @Input() search: string;
  @Input() page: number;
  @Input() maxPerPage: number;
  @Input() headers: HeadTable[];
  @Input() configs: object;
  @Input() disableActionsEvent: boolean;

  @Output() actionEvent = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();

  thead: any[] = [];
  propClicked: any;
  data: any[] = [];
  props: any[] = [];
  isChecked: object = {id: 0, checked: false };
  checkeds: any[] = [];
  allChecked = false;
  widthIcons: string;
  styleIcons: object;
  idxHover: number;
  openDataTr: boolean;
  idExpaded: number;

  ngOnInit() {
   this.convertList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (localStorage.getItem('clearCheckeds') === 'true') {
      this.checkeds = [];
      localStorage.removeItem('clearCheckeds');
    }

  }

  convertList = () => {
    const values = [];
    if (this.list.length) {
      for (const item of this.list) {
        const propsIn = Object.keys(item);
        const myProps = [];
        values.push(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            const IdString = 'id';
            const isDivergentNegative = 'is_divergent_negative';
            const isDivergentPositive = 'is_divergent_positive';

            if (element !== IdString && element !== isDivergentNegative && element !== isDivergentPositive) {
              myProps.push(element);
            } else if (element ===  IdString && !this.disableActionsEvent) {
              myProps.push(element);
            } else {
              return null;
            }

          }
        });
        this.props = myProps;
      }
      this.data = values;
    }
  }


  verifyDivergent(item) {
    const classArray = [];
    if(item.hasOwnProperty('is_divergent_positive')){
      if(item.is_divergent_positive === true){
        classArray.push('is_divergent_positive')
      }
    }

    if (item.hasOwnProperty('is_divergent_negative')) {
      if (item.is_divergent_negative === true) {
        classArray.push('is_divergent_negative');
      }
    }

    return classArray;
  }

  getChecked = (event: any, item: any) => {
    if (event.target.checked) {
      this.checkeds.push(item);
    } else {
      const position = this.checkeds.indexOf(item);
      this.checkeds.splice(position, 1);
    }

    this.selectedsEvent.emit(this.checkeds);

  }

  setAllChecked = (event: any) => {
    if (event.target.checked) {
      this.checkeds = this.data.map(item => item.id);
    } else {
      for (const id of this.checkeds) {
        this.checkeds = [];
      }
    }
    this.selectedsEvent.emit(this.checkeds);
  }

  orderByElements = (head: any[]) => {
    // ordena o array conforme a propiedade passada
    const position = this.thead.indexOf(head);
    this.propClicked =  this.props[position + 1];
  }

  dispareAction = (action, item) => {
    const event = { action, item};
    this.actionEvent.emit(event);
  }


  hoverLineTable = (event, indiceTr) => {
    if (event.clientY && event.clientX) {
      this.idxHover = indiceTr;
      let bkgColorIcon = '#ffffff';
      if (((indiceTr + 1) % 2) !== 0) {
         bkgColorIcon = '#eeeeee';
      }
      this.styleIcons = {
        backgroundColor: bkgColorIcon
      };
    }
  }

  hideActionsTable = () => {
    this.styleIcons = null;
  }

  verifyArray(element) {
    return isArray(element);
  }


  expandTr = () => {
    this.openDataTr = !this.openDataTr;
  }

}
