import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';
import { Pagination } from '../../models/pagination';

@Injectable({
  providedIn: 'root'
})

export class GasStationService extends CrudService {

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  getFuelStationByClient(idClient): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.GAS_STATIONS}?client=${idClient}`;
    return this.findItem();
  }
  getFuelStationAll(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.GAS_STATIONS;
    return this.findItem();
  }

  getGasStationTable(filters, pagination: Pagination): Observable<RestModel> {


    if (filters && filters.hasOwnProperty('parameter') && filters.hasOwnProperty('search') && filters.search !== "" && filters.search !== null && filters.search !== undefined) {
      this.apiPath = `${URLS_DEFAULT.GAS_STATIONS}?start_date=${filters.date.startDate}&end_date=${filters.date.stopDate}&${filters.parameter}=${filters.search}&sort=+name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`
    } else {
      this.apiPath = this.apiPath = `${URLS_DEFAULT.GAS_STATIONS}?sort=+name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`
    }

    return this.findItem()
  }

  getGasStationId(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.GAS_STATIONS;
    return this.getById(id);
  }

  createGasStation(gasStation): Observable<any> {
    this.apiPath = URLS_DEFAULT.GAS_STATIONS;
    return this.create(gasStation)
  }

  editGasStation(gasStation): Observable<any> {
    this.apiPath = URLS_DEFAULT.GAS_STATIONS;
    return this.update(gasStation);
  }

  deleteGasStation(id: number) {
    this.apiPath = URLS_DEFAULT.GAS_STATIONS
    return this.delete(id)
  }

  getHeaders(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.HEADER_GAS_STATION
    return this.findItem();
  }

  getConfigs(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.CONFIGS_GAS_STATION
    return this.findItem();
  }
}
