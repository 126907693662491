import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-md-lubrificant',
  templateUrl: './md-lubrificant.component.html',
  styleUrls: ['./md-lubrificant.component.scss']
})
export class MdLubrificantComponent {

  constructor() { }
  dispachForm: boolean;
  showButton: boolean;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }
}
