import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from 'src/app/models/configurations/rest.model';
import { Pagination } from '../../models/pagination';

@Injectable({
  providedIn: 'root'
})

export class DriverService extends CrudService{

  results: any;

  constructor(http: HttpClient) {
    super(http);
  }

  getDrivers(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.DISTRIBUTOR_DRIVERS;
    return this.findItem();
  }

  getFilters(filters, pagination: Pagination): Observable<RestModel> {
    if (
        filters && filters.hasOwnProperty('parameter')
        && filters.hasOwnProperty('search')
        && filters.search !== ""
        && filters.search !== null
    ) {
      this.apiPath = `${URLS_DEFAULT.DISTRIBUTOR_DRIVERS}?${filters.parameter}=${filters.search}&sort=-name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`;
    } else {
      this.apiPath = `${URLS_DEFAULT.DISTRIBUTOR_DRIVERS}?sort=+name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`;
    }

    return this.findItem()
  }

  getDriversById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.DISTRIBUTOR_DRIVERS;
    return this.getById(id);
  }

  getConfigs(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.CONFIGS_DRIVERS
    return this.findItem();
  }

  getHeaders(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.HEADER_DRIVERS
    return this.findItem();
  }

  createDriverTrr(driver): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.DISTRIBUTOR_DRIVERS;
    return this.create(driver);
  }

  updateDriverTrr(driver): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.DISTRIBUTOR_DRIVERS;
    return this.update(driver);
  }


  deleteDriver(id: number){
    this.apiPath = URLS_DEFAULT.DISTRIBUTOR_DRIVERS;
    return this.delete(id);
  }

}
