import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-select-loadeds',
  templateUrl: './select-loadeds.component.html',
  styleUrls: ['./select-loadeds.component.scss']
})
export class SelectLoadedsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
