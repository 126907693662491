import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cta-table-report',
  templateUrl: './table-report.component.html',
  styleUrls: ['./table-report.component.scss']
})
export class TableReportComponent implements OnInit {

  constructor() { }
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @Input() list: any[];
  @Input() search: string;
  @Input() page: number;
  @Input() maxPerPage: number;
  @Input() configs: object;
  @Output() actionEvent = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();
  thead: any[] = [];
  propClicked: any;
  data: any[] = [];
  props: any[] = [];
  isChecked: object = {id: 0, checked: false}
  checkeds: any[] = [];
  allChecked = false;
  widthIcons: string;
  styleIcons: object;
  idxHover: number;
  ngOnInit() {
    this.convertList();
  }


  convertList() {
    if (this.list.length){
      for (const item of this.list) {
        const propsIn = Object.keys(item);
        const myProps = [];
        const values = this.data.push(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)){
            myProps.push(element);
          }
        });
        this.props = myProps;
      }
    }
  }

}
