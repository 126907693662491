import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-theme-color',
  templateUrl: './theme-color.component.html',
  styleUrls: ['./theme-color.component.scss']
})
export class ThemeColorComponent  {

  themeWrapper = document.querySelector('body');

  onSubmit(form) {
    this.global(form.value);
  }

  global(stylesheet) {
    console.log(stylesheet);

    // Navigation Styles
    if (stylesheet.globalNavColor) {
      this.themeWrapper.style.setProperty('primaryColor', stylesheet.globalNavColor);
    }
    if (stylesheet.globalNavBackground) {
      this.themeWrapper.style.setProperty('fontPrimaryColor', stylesheet.globalNavBackground);
    }

    // Card Styles
    if (stylesheet.globalCardColor) {
      this.themeWrapper.style.setProperty('fontColorMenu', stylesheet.globalCardColor);
    }
    if (stylesheet.globalNavBackground) {
      this.themeWrapper.style.setProperty('fontColorArrowMenu', stylesheet.globalCardBackground);
    }

    // Footer Styles
    if (stylesheet.globalFooterColor) {
      this.themeWrapper.style.setProperty('submenuColor', stylesheet.globalFooterColor);
    }
    if (stylesheet.globalFooterBackground) {
      this.themeWrapper.style.setProperty('bkgBody', stylesheet.globalFooterBackground);
    }
    if (stylesheet.globalFooterAlignment) {
      this.themeWrapper.style.setProperty('writhe', stylesheet.globalFooterAlignment);
    }

    // Button Styles
    if (stylesheet.globalButtonColor) {
      this.themeWrapper.style.setProperty('strongBlack', stylesheet.globalButtonColor);
    }
    if (stylesheet.globalButtonBackground) {
      this.themeWrapper.style.setProperty('ouverStrongColor', stylesheet.globalButtonBackground);
    }
  }

}
