import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { MdFormPumpComponent } from 'src/app/components/forms-pages/md-form-pump/md-form-pump.component';
import { TanksService } from 'src/app/services/tanks/tanks.service';
import { PumpsService } from 'src/app/services/pumps/pumps.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from 'src/app/components/modais/confirm/confirm.component';


@Component({
  selector: 'cta-form-modal-tank',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalTankComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    private tankService: TanksService,
    private pumpService: PumpsService,
    private toastService: ToastrService
  ) {

    this.fuelTankId = data && data.idTank ? data.idTank : null;
    this.optionsEdit = data && data.isEdit ? data.isEdit : false;
    if(this.fuelTankId){
      this.optionsEdit = true;
      this.getTank(this.fuelTankId)
    }


  }

  ngOnInit() {

    if(this.fuelTankId){
      this.getPumps(this.fuelTankId)
    }

  }

  dispachForm: boolean;
  dispachFormPump: boolean;
  showButton: boolean;
  optionsButton: boolean;
  openFormPump: boolean
  fuelTankId: number;
  optionsEdit: boolean;
  listPumps: any[] = []
  configsTable = [
    {
      name: 'delete',
      icon: 'mdi-delete',
      tooltip: 'Excluir Item'
    }
 ]
  valuesTank: any;
  headersPumps = [
    {
      id: 0,
      title: 'Código',
      value: 'export_code'
    },
    {
      id: 1,
      title: 'Nome',
      value: 'name'
    },
    {
      id: 2,
      title: 'Nome Curto',
      value: 'short_name'
    }
  ];

  @Output() saveAnother = new EventEmitter()
  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = (event) => {
    this.dispachFormPump = true;
    if(event === 'pump'){
      let config = {
        idTank: this.fuelTankId
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = config
       this.dialog.open(MdFormPumpComponent, dialogConfig)
       .afterClosed()
       .subscribe(
         () => {
          this.getPumps(this.fuelTankId)
         },
         err => {
          console.log('err closed modal pump', err)
         }
       )
    }
  }

  dispachFormSave = () => {
    this.dispachForm = true;
  }

  dispachFormPumpEvent = () => {
    this.dispachFormPump = true;
    this.requisitionPumpSuccess(true)
  }

  requisitionSuccess = (idTank) => {
    if(idTank){
      this.getTank(idTank);
     this.fuelTankId = idTank
     this.optionsButton = true
    }
  }

  requisitionPumpSuccess = (res) => {
    if(res){
      this.optionsButton = true
      this.openFormPump = false
      const idTank = parseInt(localStorage.getItem('lastIdSaveTank'))
      this.getPumps(idTank)
    }
  }

  getTank = (idTank) => {
    this.tankService.getTankById(idTank).subscribe(
      res => {
        this.valuesTank = res
      }
    )
  }

  getPumps = (idTank) => {
    this.listPumps = []
    this.pumpService.getPumpByTank(idTank).subscribe(
      res => {
        const pumps = res.results.map((item) => ({
          id: item.id,
          export_code: item.export_code,
          name: item.name,
          short_name: item.short_name
        }));

        this.listPumps = pumps
      },
      err => {
        console.log('err get pumps table ', err)
      }
    )
  }


  getPumpId = (idPump) => {
    this.pumpService.getPumpById(idPump)
    .subscribe(
      res => {
        let config = {
          values: res
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = config

        this.dialog.open(MdFormPumpComponent, dialogConfig)
          .afterClosed()
          .subscribe(
            () => {
              this.getPumps(this.fuelTankId)
            },
            (err) => console.log('err pump close md', err)
          )
      },
      err => {
        this.toastService.warning("Não foi possível encontrar a bomba selecionada para edição")
        console.log('não foi possível encontrar a bomba', err)
      }
    )
  }

 deleteAction = {
    action: 'delete',
    description: 'Excluir item(s)'
  }

  editAction = {
    action: 'edit',
    description: 'Editar item(s)'
  }
  countSelecteds: any = []
  selectedsPumpsTable: any = []
  selectedsEventPumps = (event) => {

    this.countSelecteds = event.length;
    this.selectedsPumpsTable = event
  }

  eventPumpTable = (event) => {

    if(event.action === 'delete'){
      this.selectedsPumpsTable.forEach(idPump => {
        this.deletePump(idPump)
      });
    }

    if(event.action === 'edit'){
      const idPump = this.selectedsPumpsTable[0]
      this.getPumpId(idPump)
    }
  }

  deletePump = (idPump) => {
    if(idPump !== 0){
      this.pumpService.deletePump(idPump).subscribe(
        () => {
          this.toastService.info("Bomba excluida conforme solicitado")
          this.getPumps(this.fuelTankId)
        },
        err => {
          this.toastService.warning("Não é possível deletar bombas que estejam vinculadas a abastecimentos.")
          console.log('err delete pump => ', err)
        }
      )
    }
  }
}
