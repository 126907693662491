import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DriverService } from 'src/app/services/drivers/driver-service';

@Component({
  selector: 'cta-select-driver',
  templateUrl: './select-driver.component.html',
  styleUrls: ['./select-driver.component.scss']
})
export class SelectDriverComponent implements OnInit {

  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private driverService: DriverService
  ) { }

  selectedDriver: any;
  @Output() eventSelected = new EventEmitter();
  @Input() selectedInput: any;
  @Input() optionValueName: boolean;
  @Input() required = true;
  @Input() idClient: any;
  drivers: any[] = [];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
    this.getDrivers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedDriver = this.selectedInput;
  }

  getDrivers = () => {
    this.driverService.getDrivers().subscribe(
      res => {
       const data = res.results
       data.map(item => ({
         id: item.id,
         name: item.name
       }))

       this.drivers = data

      },
      err => {
        console.log('err getProducts ', err)
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de produtos')
      }
    )
  }

  dispachDriver = (driver) => {
    this.eventSelected.emit(driver);
  }
}
