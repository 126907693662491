import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TopButtons } from 'src/app/models/top-buttons';
import { ModalConfirm } from 'src/app/models/modal-confirm';
import { Menu } from 'src/app/models/menu';
import { MenuService } from 'src/app/services/configurations/menu.service';
import { Log } from 'src/app/models/log';
import { LogSystemService } from 'src/app/services/helpers/log-system.service';
import { MenuRequest } from 'src/app/models/menu.request';
import { MenuPreferencesService } from 'src/app/services/configurations/menu-preferences.service';
import {DISTRIBUTORS_MENU} from '../../../data/menu-distributors';

@Component({
  selector: 'cta-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})

export class ContainerComponent implements OnInit {

  constructor(private menuService: MenuService, private MenuPreferences: MenuPreferencesService, private logs: LogSystemService) { }
  @Output() eventButton = new EventEmitter();
  @Input() selecteds: any[];
  @Input() styleButtons: TopButtons[];
  @Input() dataModal: ModalConfirm[];
  log: Log = {
    component: 'components/shared/container'
  }
  menus: any[];
  menuPreferences: Menu[];
  show: boolean;
  showTopChangeUser: boolean;
  showCustom: boolean;
  isMobile = false;
  responsiveMenuShow: boolean;
  ngOnInit() {
    this.isMobile = window.innerWidth >= 780 ? false : true;
    this.show = this.isMobile ? true : false;
    this.viewFullEvent();
    this.getDataMenu();


    this.getDataMenuPreferences()
  }

  verifyShowMenu = (result: boolean) => {
    this.show = result;
  }

  closeOptions = event => {
    this.showCustom = false;
  }
  verifyShowCustomizer = () => {
    this.showCustom = !this.showCustom;
  }

  eventTopButton = (event) => {
    this.eventButton.emit(event);
  }

  viewFullGetEvent = event => {
    this.show = event === 'true' ? true : false;
  }

  showChangeUser = event => {
    this.showTopChangeUser = event;
  }

  viewFullEvent() {
    this.show = localStorage.getItem('viewFull') && localStorage.getItem('viewFull') === 'true' ? true : false;
  }

  openCloseResponsiveMenu = () => {
    this.responsiveMenuShow = !this.responsiveMenuShow;
  }

  getDataMenu = () => {
    this.menuService.getMenus().subscribe(
      res => {

        let list_menus = [];
        const values = res.results;

        values.forEach(item => {
          const dataJson = item.json
          this.log.line = 115
          this.logs.verifyType(dataJson, 'object', this.log)
          if (!item.main_item) {
            list_menus.push({
              id: item.id,
              label: dataJson.label,
              icon: dataJson.icon,
              title: dataJson.title,
              link: dataJson.link,
              subtitle: dataJson.subtitle
            });
          } else {
            list_menus = this.createSubmenus(list_menus, item)
          }
        });
        const dataLogin = sessionStorage.getItem("currentUser");
        const transformDataLogin = JSON.parse(dataLogin);

        const userCategory = transformDataLogin[0].category;
        console.log("ENTROU EM USER CATEGORY");
        console.log(userCategory);
        if(userCategory === 'DISTRIBUIDOR'){

          this.menus = DISTRIBUTORS_MENU;
        }else{
          this.menus = list_menus;
        }

      },
      err => {
        console.log(err);
      }
    )
  }

  getDataMenuPreferences = () => {
    this.MenuPreferences.getMenu().subscribe(
      res => {
        let list_menus = [];

        const values = res.results;

        values.forEach(element => {

          const dataJson = element.json
          this.log.line = 115
          this.logs.verifyType(dataJson, 'object', this.log)
          if (!element.main_item) {
            list_menus.push({
              id: element.id,
              label: dataJson.label,
              icon: dataJson.icon,
              title: dataJson.title,
              link: dataJson.link,
              subtitle: dataJson.subtitle
            });
          } else {

            list_menus = this.createSubmenus(list_menus, element)
          }

        });

        this.menuPreferences = list_menus;
      },
      err => {
        console.log(err);
      }
    )
  }

  createSubmenus(listmenu: Menu[], submenu: MenuRequest) {

    listmenu.forEach(element => {
      let values = [];
      if (element.id === submenu.main_item) {
        if (element.submenu) {
          const dataJson = submenu.json;
          this.log.line = 115
          this.logs.verifyType(dataJson, 'object', this.log)
          if(submenu.id === 52 || submenu.id === 53){

          }
          element.submenu.push(dataJson);
        } else {
          if(submenu.id === 52 || submenu.id === 53){

          }
          values.push(submenu.json);
          element.submenu = values;
        }
      }
    })

    return listmenu;
  }
}
