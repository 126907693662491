import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriversRoutingsModule } from './drivers-rounting.module';
import { ListDriversComponent } from './views/list/list.component';
import { ViewCrudModule } from 'src/app/components/view-crud/view-crud.module';

import { FormComponent } from './views/form/form.component';
import { CreateComponent } from './views/create/create.component';
import { FormModalComponent } from './views/form-modal/form-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { MatDialogModule } from '@angular/material';
import { ListDriversTrrComponent } from './views/trr/list-drivers-trr/list-drivers-trr.component';

@NgModule({
  declarations: [ListDriversComponent, CreateComponent, FormModalComponent, FormComponent, ListDriversTrrComponent],
  imports: [
    CommonModule,
    DriversRoutingsModule,
    ViewCrudModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    MatDialogModule
  ],
  entryComponents: [FormModalComponent]
})
export class DriversModule { }
