import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DeliveriesService } from 'src/app/services/deliveries/deliveries.service';

@Component({
  selector: 'cta-md-form-shipment',
  templateUrl: './md-form-shipment.component.html',
  styleUrls: ['./md-form-shipment.component.scss']
})
export class MdFormShipmentComponent implements OnInit{

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private deliveriesService: DeliveriesService,
  )
  {
    if(data){
      this.deliveryId = data.values.id;
    }
  }


  ngOnInit(): void {
   this.getShipmentDelivery();
  }


  shipmentDeliveryId: number;
  deliveryId: number;

  getShipmentDelivery = () => {
    this.shipmentDeliveryId = null;
    if(this.deliveryId){
      this.deliveriesService.getDelivery(this.deliveryId).subscribe(
        res => {
          const delivery = res.results[0];
          console.log("teste shipment id", delivery);
          if(delivery.shipment_delivery.length){
            this.shipmentDeliveryId = delivery.shipment_delivery[0].id;
          }
        },
        err => {
          console.log(err);
        }
      )
    }
  }


  verifyShipmentTime = (event) => {
    if(event === true){
      setTimeout(() => {
        this.getShipmentDelivery()
      }, 2000);
    }
  }

}
