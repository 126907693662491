import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmComponent } from '../../modais/confirm/confirm.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HeadTable } from 'src/app/models/configurations/head.model';
import { MdSelectedsTableComponent } from '../../modais/md-selecteds-table/md-selecteds-table.component';
import { FilterTable } from 'src/app/models/configurations/filter-table.model';

@Component({
  selector: 'cta-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit, OnChanges {


  @Input() list: any[] = [];
  @Input() configs: object;
  @Input() headers: HeadTable[];
  headersFiltered: HeadTable[];
  @Input() DefaultDateVal: any;
  @Input() filtersDinamics: boolean;
  @Input() eventsInSelect: any[];
  @Input() emptyData: boolean;
  maxPerPage = 100;
  @Output() actionEvent = new EventEmitter();
  @Output() eventPagination = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();
  @Input() disableActionsEvent: boolean;
  filtersNotShow: string[] = ['delivery_date'];
  filtersSelected: any[] = [];
  @Input() disabledDateHour: boolean;
  messageAction: any;
  activeAction = {
    action: 'active',
    description: 'Modificar Status'
  };
  deleteAction = {
    action: 'delete',
    description: 'Excluir item(s)'
  };

  editAction = {
    action: 'edit',
    description: 'Editar item(s)'
  };

  startDateDefault: number;
  stopDateDefault: number;
  searchTable: FilterTable = {
    startDate: 0,
    andDate: 0,
    parameter: null,
    searchWord: null
  };

  countSelecteds: number;
  selectedsItens: any[] = [];
  currentPage = 1;
  searchValue: string = null;
  ngOnInit(): void {

    if (this.DefaultDateVal) {
      this.startDateDefault = this.DefaultDateVal.startDate;
      this.stopDateDefault = this.DefaultDateVal.stopDate;
    }

    this.headersFiltered = this.headers.filter(item => !this.filtersNotShow.includes(item.value));
    if (this.headersFiltered.length) {
      this.searchTable.parameter = this.headersFiltered[0].value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('list search table');
    console.log(this.list);

  }

  constructor( public dialog: MatDialog, private toastr: ToastrService) {}


  emitSearched = (value) => {

    this.searchTable.searchWord = value;
    this.searchValue = value;
    const emitSearch = {
      action: 'search',
      data: this.searchTable
    };

    this.actionEvent.emit(emitSearch);
  }


  getDataPage = (pagination) => {

    this.maxPerPage = pagination.maxPerPage;
    this.currentPage = pagination.currentPage;
    this.eventPagination.emit(pagination);
  }

  dispareAction = (event) => {
    this.actionEvent.emit(event);
  }

  setSelected = event => {
    this.countSelecteds = event.length;
    const values = [];
    this.list.forEach(element => {
      if (event.includes(element.id)) {
        values.push(element);
      }
    });

    this.selectedsItens = values;
  }

  getDateHour = (event) => {

    if (event && event.hasOwnProperty('initUtcDate') && event && event.hasOwnProperty('andUtcDate')) {
      this.searchTable.startDate = event.initUtcDate;
      this.searchTable.andDate = event.andUtcDate;
      const configDate = {
        action: 'dateHourEvent',
        startDateHour: event.initUtcDate,
        stopDateHour: event.andUtcDate
      };
      this.actionEvent.emit(configDate);
    }
  }

  selectedSearch = (event) => {
    console.log('SELECTED SEARCH ', event);
    this.searchTable.parameter = event.target.value;
  }

  openDialog(action: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    this.getMensagens(action);
    dialogConfig.data = {
      title: this.messageAction,
      description: '',
      function: 'delete'
    };

    const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.toastr.info('Sua alteração foi executada com sucesso!');
    });

  }

  clearFilters = (filter) => {
    const idxRemove = this.filtersSelected.indexOf(filter);
    const values = this.filtersSelected.splice(idxRemove , 1);

    const emitFilters = {
      action: 'filters',
      data: values
    };

    this.actionEvent.emit(emitFilters);
  }

  openDinamycFilters = () => {
    const emitFilters = {
      action: 'filters',
    };
    this.actionEvent.emit(emitFilters);
  }

  openMdSelected = async (action, description) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data  = {
        title: 'Itens selecionados',
        list: this.selectedsItens,
        headers: this.headers,
        action,
        description
    };

    const dialogRef =  this.dialog.open(MdSelectedsTableComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.actionEvent.emit(result);
    });
  }

  getMensagens = (action: string) => {

    const msgPlurarDelete = 'Você tem certeza que deseja deletar estes itens?';
    const msgSingDelete = 'Você tem certeza que deseja deletar este item?';
    const msgPlurarEdit = 'Você tem certeza que deseja editar estes itens?';
    const msgSingEdit = 'Você tem certeza que deseja editar este item?';
    const msgPlurarStatus = 'Você tem certeza que deseja modificar os status destes itens?';
    const msgSingStatus = 'Você tem certeza que deseja modificar o status deste item?';
    const msgDefault = 'Você tem certeza que deseja executar esta  ação';
    switch (action) {
      case 'delete':
        this.messageAction = this.countSelecteds ? msgPlurarDelete : msgSingDelete;
        break;
      case 'edit':
        this.messageAction = this.countSelecteds ? msgPlurarEdit : msgSingEdit;
        break;
      case 'status':
        this.messageAction = this.countSelecteds ? msgPlurarStatus : msgSingStatus;
        break;
      default:
        this.messageAction = msgDefault;
        break;
    }
  }
}
