import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ShipmentTankService } from 'src/app/services/shipments/shipment-tank.service';
import { TankShipmentModel } from 'src/app/models/shipments/tank-shipment.model';
import { Lightbox } from 'ngx-lightbox';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';


@Component({
  selector: 'cta-form-shipment-tank',
  templateUrl: './form-shipment-tank.component.html',
  styleUrls: ['./form-shipment-tank.component.scss']
})
export class FormShipmentTankComponent implements OnInit {

  cadastrar: FormGroup;
  @Input() shipmentId: number;
  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Input() dataShipmentRegister: any;
  @Output() validForm = new EventEmitter();
  @Output() nextEvent = new EventEmitter();
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  valuesShipment: TankShipmentModel;
  imageGet: any;

  constructor(
    private fb: FormBuilder,
    private toastService: ToastrService,
    private tankShipmentService: ShipmentTankService,
    private _lightbox: Lightbox
  ) { }

  private base64textString: String = "";

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview(){
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);

    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  handleFileSelect = (evt) => {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      image_file: this.base64textString,
      note: this.cadastrar.value.note,
      occupation: this.cadastrar.value.occupation
    });

    console.log(this.cadastrar.value);
  }

  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }


  submitValues = () => {

    if(this.valuesShipment){
      const  dataForm = {
        id: this.cadastrar.value.id,
        note: this.cadastrar.value.note,
        occupation: this.cadastrar.value.occupation
      }

      this.tankShipmentService
      .update(this.previewUrl ? this.cadastrar.value : dataForm)
      .subscribe(
        () => {
          this.toastService.toastrConfig.positionClass = 'toast-top-center'
          this.toastService.success("Sucesso ao editar os dados de entrega do tanque");
        },
        err => {
          console.log('erro ao tentar cadastrar dados de entrega do tanque', err);
          this.toastService.warning("Ocorreu uma falha ao tentar editar os dados de entrega do tanque");
        }
      )
    }else{

      const  dataForm = {
        id: this.cadastrar.value.id,
        shipment_delivery: this.shipmentId,
        note: this.cadastrar.value.note,
        image_file: this.previewUrl ? this.cadastrar.value.image_file : null,
        occupation: this.cadastrar.value.occupation,
        fuel_tank: this.dataShipmentRegister.fuel_tank.id
      }
      this.tankShipmentService.createShipmentTank(dataForm).subscribe(
        res => {
          this.toastService.success("Sucesso ao cadastrar os dados de entrega do tanque");
          this.nextEvent.emit('encerrante');
        },
        err => {
          this.toastService.warning("Ocorreu uma falha ao tentar cadastrar os dados de entrega do tanque");
        }
      )
    }


  }

  getValuesShipmentTank = () => {

    this.tankShipmentService.getShipmentByDelivery(this.shipmentId).subscribe((res) => {
      if(res.results.length){
        const value = res.results[0];
        this.valuesShipment = new TankShipmentModel(value.id, value.image_file, value.note, value.occupation);
        this.imageGet = value.image_file !== null && value.image_file !== undefined ? `${URLS_DEFAULT.URL_CURRENT}/${value.image_file}` : null

        this.setValuesShipment();
      }


    },
      err => {
        console.log("erro ao tentar fazer o get dos dados de entrega de tanque");
      }
    )
  }

  ngOnInit() {
    if (this.shipmentId) {
      this.getValuesShipmentTank();
    }

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      image_file: this.fb.control(''),
      note: this.fb.control(''),
      occupation: this.fb.control('')
    })


  }

  setValuesShipment = () => {

    this.cadastrar.setValue({
      id: this.valuesShipment.id ? this.valuesShipment.id : null,
      image_file: this.valuesShipment.image_file ? `${URLS_DEFAULT.URL_CURRENT}/${this.valuesShipment.image_file}` : null,
      note: this.valuesShipment.note ? this.valuesShipment.note : this.cadastrar.value.note ,
      occupation: this.valuesShipment.occupation ? this.valuesShipment.occupation : this.cadastrar.value.occupation
    });
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar && this.cadastrar.valid) {
        this.submitValues();
      }
    }
  }

  showImage(): void {
    const images = [
      {
        src: this.imageGet,
        caption: 'Imagem Garagem',
        thumb: this.imageGet
      }
    ];
    this._lightbox.open(images, 0);
  }

}
