import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'cta-md-form-client',
  templateUrl: './md-form-client.component.html',
  styleUrls: ['./md-form-client.component.scss']
})
export class MdFormClientComponent {

  constructor(@Inject(MAT_DIALOG_DATA) data, public dialog: MatDialog) {
    console.log("DATA MODAL CLIENT", data);
    if(data){
      this.client =  data.values ? data.values[0] : null;
      this.showButton = true;
    }

   }
  dispachForm: boolean;
  showButton: boolean;
  client: any;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;

  }

}
