import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProbeService } from 'src/app/services/probe/probe.service';
import { FormModalTankComponent } from '../../forms-pages/modais-tank/form-modal/form-modal.component';

@Component({
  selector: 'cta-select-probes',
  templateUrl: './select-probes.component.html',
  styleUrls: ['./select-probes.component.scss']
})
export class SelectProbeComponent implements OnInit, OnChanges {


  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private probeService: ProbeService
  ) { }

  selectedProbe: number;
  @Input() selectedInput: number;
  @Output() eventSelectedProbes = new EventEmitter();
  @Input() required = true;
  probes: any[] = [];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
    this.getPlumbes()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.selectedInput){
      this.selectedProbe = this.selectedInput
    }
  }

  openMd(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Adicionar Sonda',
      description: '',
      function: 'create'
    };

    const dialogRef = this.dialog.open(FormModalTankComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log('Quando fechar o modal faz...', false);
    });
  }

  getPlumbes = () => {
    this.probeService.getPlumbs().subscribe(
      res => {
       const data = res.results
       data.map(item => ({
         id: item.id,
         name: item.name
       }))

       this.probes = data
       this.probes.push({id:0, name: 'Nenhuma'})
      },
      err => {
        console.log('err getProducts ', err)
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de produtos')
      }
    )
  }

  dispachPlubs = (probe) => {
    this.eventSelectedProbes.emit(probe);
  }
}
