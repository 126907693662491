import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TanksService } from 'src/app/services/tanks/tanks.service';



@Component({
  selector: 'cta-select-contract',
  templateUrl: './select-contract.component.html',
  styleUrls: ['./select-contract.component.scss']
})
export class SelectContractComponent implements OnInit, OnChanges {

  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private tankService: TanksService

  ) { }



  selectedTypeContract: any;
  @Output() eventSelectedContract = new EventEmitter();
  @Input() selectedInput: any;
  @Input() idClient: any;
  @Input() optionValueName: boolean;
  @Input() required = true;
  typesContract: any[] = [ ];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
    this.getTypesContract();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedInput) {
      this.selectedTypeContract = this.selectedInput;
    }
    if(this.idClient) {
     this.getTypesContract();
    }
  }

  openMd() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.autoFocus = true;
    // dialogConfig.data = {
    //   title: 'Adicionar Frentsita',
    //   description: '',
    //   function: 'create'
    // };

    // const dialogRef = this.dialog.open(FormModalTankComponent, dialogConfig);

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    //   console.log('Quando fechar o modal faz...', false);
    // });
  }

  getTypesContract = () => {
    this.tankService.getTypesContract().subscribe(
      res => {
       const data = res.results;
       data.map(item => ({
         id: item.id,
         name: item.name
       }))
       this.typesContract = data;

      },
      err => {
        console.log('err getContracts ', err)
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de tipos de contrato')
      }
    )
  }

  dispachProduct = (contract) => {
    this.eventSelectedContract.emit(contract);
  }
}
