import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cta-options-customizer-rigth',
  templateUrl: './options-customizer-rigth.component.html',
  styleUrls: ['./options-customizer-rigth.component.scss']
})
export class OptionsCustomizerRigthComponent implements OnInit {

  constructor() { }
  showEvent: boolean;
  @Input() show:boolean;
  @Output() closeOptions = new EventEmitter();

  ngOnInit() {
  }

  close  = () => {
    this.closeOptions.emit(false)
  }
}
