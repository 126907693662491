import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ListCrud } from 'src/app/components/interfaces/crud-list.component.interface';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalConfirm } from 'src/app/models/modal-confirm';
import * as XLSX from 'xlsx';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { HeadTable } from '../../../../models/configurations/head.model';
import { ConfigHeaderComponent } from '../../../../components/tables/config-header/config-header.component';
import { isArray } from 'util';
import { filter } from 'rxjs/operators';
import * as autoTable from 'jspdf-autotable';

type AOA = any[][];

@Component({
  selector: 'cta-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, ListCrud {

  constructor(public dialog: MatDialog) { }

  @Output() selectedsEvent = new EventEmitter();
  dataModal: ModalConfirm[];
  openPdfView = false;

  topButtons = [
    {
      style: 'btn  btn-cta-export',
      title: 'TXT',
      icon: 'script-text-outline',
      event: 'exportTxt'
    },
    {
      style: 'btn  btn-cta-export',
      title: 'EXCEL',
      icon: 'mdi-file-excel',
      event: 'exportExcel'
    },
    {
      style: 'btn  btn-cta-export',
      title: 'PDF',
      icon: 'mdi-file-pdf-box',
      event: 'exportPDF'
    },
    {
      style: 'btn  btn-cta-export',
      title: 'Configurar',
      icon: 'mdi-cog-box',
      event: 'configHeader'
    }
  ];

  defaultList: any[] = [
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },{
      id: 1,
      client: "Exemplo",
      gas_station: "Posto Exemplo",
      fuel_tank: "Exemplo S10",
      product: "S10",
      cost: "R$ 3.500",
      deliveryman: "Motorista",
      volume: "5.000",
      delivery_date: "11/08/2020 09:00"
    },
    {
      id: 2,
      client: "Exemplo 2 ",
      gas_station: "Posto Exemplo 2",
      fuel_tank: "Exemplo S10 - B",
      product: "S10",
      cost: "R$ 7.500",
      deliveryman: "Motorista 2",
      volume: "6.000",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 3,
      client: "Exemplo 3 ",
      gas_station: "Posto Exemplo 3",
      fuel_tank: "Exemplo S10 - C",
      product: "S10",
      cost: "R$ 9.500",
      deliveryman: "Motorista 3",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    },
    {
      id: 4,
      client: "Exemplo 4 ",
      gas_station: "Posto Exemplo 4",
      fuel_tank: "Exemplo S10 - D",
      product: "S10",
      cost: "R$ 3.700",
      deliveryman: "Motorista 4",
      volume: "8.500",
      delivery_date: "19/08/2020 10:00"
    }
  ]
  header: HeadTable[] = [

    {
      id: 2,
      title: "Posto",
      value: "gas_station"
    },
    {
      id: 3,
      title: "Cliente",
      value: "client"
    },
    {
      id: 4,
      title: "Tanque",
      value: "fuel_tank"
    },
    {
      id: 5,
      title: "Produto",
      value: "product"
    },
    {
      id: 6,
      title: "Custo",
      value: "cost"
    },
    {
      id: 7,
      title: "Entregador",
      value: "deliveryman"
    },
    {
      id: 8,
      title: "Volume",
      value: "volume"
    },
    {
      id: 9,
      title: "Entrega",
      value: "delivery_date"
    }
  ];

  pdfStyle = false;
  headList: HeadTable[] = [];
  inactiveColumns:HeadTable[] = [];
  list: any[] = [];
  props: any[] = [];
  configsTable: object = { actions: ['edit', 'delete', 'active', 'create'], search: false, pdf: true, xsl: true };
  showMd: boolean;
  // wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  ngOnInit() {
    this.headList = this.header;
    this.getList();
    this.dataModal = [
      {
        title: 'Você tem certeza que deseja deletar este item',
        description: 'Ao deletar os itens você não terá mais como recupera-los',
        functions: 'delete'
      }
    ];

    this.convertList()
  }


  getList() {
    this.list = this.defaultList;
  }
  exportPdf: boolean;
  actionEvent = (event) => {
    if (event === 'exportPDF') {
      this.exportPdf = true;
      setTimeout(() => {
        this.exportPdf = false;
      }, 2000);
    }

    if (event === 'exportExcel') {
      this.generateFileExcel();
    }

    if (event === 'exportTxt') {
      console.log('export txt');
      new Angular2Txt(this.list, 'documento')
    }

    if (event === 'configHeader') {
      this.configHeaders();
    }
  }



  delete = (data: object) => {
    console.log(data);
  }

  setSelecteds = event => {
    this.selectedsEvent.emit(event);

  }

  remove(item: object) {
    throw new Error("Method not implemented.");
  }

  create() {
    console.log('teste');
  }


  valueDrawElementPdf: any[] = [];
  generateFilePDF(){

    if(this.list.length){
      // this.headList = this.list.filter(item => item.head)[0]
      const dataTable = this.list.filter(item => !item.head)

      let myProps = [];

      for (const item of dataTable) {
        let propsIn = Object.keys(item);
        propsIn.forEach(element => {
          if(!myProps.includes(element)){
            myProps.push(element);
          }
        });
      }

      const bodyFile: any[] = [];
      for (const item of this.list) {
        let line = [];
        for (const prop of myProps) {

          line.push(item[prop]);

          if(line.length === myProps.length){
            if(!bodyFile.includes(line)){
              line.shift();
              bodyFile.push(line);
            }
            line = [];
          }
        }
      }
      let newHead = [];
      this.headList.forEach(element => {
        newHead.push(element.title);
      })

      var element = document.getElementById("content")

      html2canvas(element).then((canvas) => {
  
        var imgData = canvas.toDataURL('image/png');        
        var imgWidth = 210;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var doc = new jsPDF('p', 'mm');
        var position = 0;
        // var imgHeight = canvas.height * 210 / canvas.width;
        
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        let page =+ doc.internal.getNumberOfPages() ;
        doc.autoTable({
          headStyles: { fillColor: [0, 173, 154] },
          footStyles: {fillColor: [0, 173, 154]},
          rowStyles: {fillColor: [245, 245, 245]},
          margin: {top: 80}, // Seting top margin for First Page.
          didDrawPage:  (data) => {
            data.settings.margin.top = 10;            
            this.valueDrawElementPdf.push(data);            
          },
          head:  [newHead],
          body: bodyFile,          
        });
        console.log("testando data value => ", this.valueDrawElementPdf);
        this.addFooters(doc);        
        doc.save("relatorio.pdf");
      });

      // let doc = new jsPDF();
     

      // doc.save(`documento.pdf`);
    }

  }
  
  addFooters  = async (doc) => {
    const pageCount = doc.internal.getNumberOfPages();
    
    doc.setFontSize(12)
    for (var i = 1; i <= pageCount; i++) {      
      doc.setPage(i)
      var img = new Image();
      img.src = "../../../../../assets/images/cta_simbolo_verde.png";
      doc.addImage(img, 'png', 186 , 288)     
      doc.text('página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width / 2, 292, {
        align: 'center'
      })
    }
  }


  convertList = () => {

    const values = []
    if (this.list.length) {
      for (const item of this.list) {
        const propsIn = Object.keys(item);
        const myProps = [];
        values.push(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element) && element !== "id") {
            myProps.push(element);
          }
        });
        this.props = myProps;
      }

    }
  }

  generateFileExcel() {
    this.convertListMatrisArray();
    if (this.list.length) {
      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.list);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, `documento.xlsx`);
    }
  }


  convertListMatrisArray = () => {

    if (this.list.length) {
      this.headList = this.list.filter(item => item.head)[0]
      const dataTable = this.list.filter(item => !item.head)

      let myProps = [];

      for (const item of dataTable) {
        let propsIn = Object.keys(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            myProps.push(element);
          }
        });
      }

      const bodyFile: any[] = [];
      bodyFile.push(this.headList["head"])
      for (const item of this.list) {
        let line = [];
        for (const prop of myProps) {

          line.push(item[prop]);

          if (line.length === myProps.length) {
            if (!bodyFile.includes(line)) {
              line.shift();
              bodyFile.push(line);
            }
            line = [];
          }
        }
      }

      this.list = bodyFile;
    }
  }

  verifyArray(element) {
    return isArray(element);
  }

  configHeaders() {

    let values = {
      activeColumns: this.headList,
      inactiveColumns: this.inactiveColumns
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = values
    const dialogRef = this.dialog.open(ConfigHeaderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
     
      if (res.activeColumns !== undefined) {
        this.list = [];
        this.headList = [];
        this.headList = res.activeColumns;
        this.inactiveColumns = res.inactiveColumns !== undefined ? res.inactiveColumns : this.inactiveColumns;
        this.updateFormatList(res.activeColumns);        
      }
      
    });

  }

  updateFormatList = (columns) => {
    this.list = this.defaultList;

    let values = [];
    let columnsValid = [];
    columns.forEach(element => {
      columnsValid.push(element.value);
    });

    this.list.forEach((item) => {
      let newObj = {id: item.id};
      columnsValid.forEach(propName => {
        let obj = {[propName]: item[propName]}
        Object.assign(newObj, obj)
      });

      values.push(newObj);
    });
    
    console.log(this.headList);
    this.list = values;
  }

  //  ConfigHeaderComponent
}
