import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';
import { Pagination } from '../../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class DeliveriesService extends CrudService{

  results: any;

  constructor(http: HttpClient) {
    super(http);
  }


  getDeliveriesList(filters: any, deliveryDate: any): Observable<RestModel> {
    if (
        filters && filters.hasOwnProperty('parameter')
        && filters.hasOwnProperty('search')
        && filters.search !== ""
        && filters.search !== null
        && deliveryDate
    ) {
      this.apiPath = `${URLS_DEFAULT.DELIVERIES}?${filters.parameter}=${filters.search}&issue_date=bt:${deliveryDate}&sort=-issue_date`;
    } else if(deliveryDate) {
      this.apiPath = `${URLS_DEFAULT.DELIVERIES}?issue_date=bt:${deliveryDate}&sort=-issue_date`;
    }else{
      this.apiPath = `${URLS_DEFAULT.DELIVERIES}?sort=-issue_date`;
    }

    return this.findItem()
  }

  getDeliveries(filters: any[], pagination: Pagination, sortItem: string): Observable<RestModel> {
    let searchs  = "";
    let operator = '=';
    let filtersNames = [];
    if(filters.length){
      filters.forEach((element, index) => {
        if(!filtersNames.includes(element.name)){
          filtersNames.push(element.name);
          if(element.name === 'delivery_date' || element.name === 'delivery_shipment_date'){

            operator = '=bt:';
          }else if(element.name ==='unit_cost' || element.name ==='divergence'){
            operator = '=ge:';
          }else{
            operator = "="
          }

          if (index === 0) {
            searchs += `${element.name}${operator}${element.value}`;
          } else {
            searchs += `&${element.name}${operator}${element.value}`;
          }
        }
      })

    }
    const url = `${URLS_DEFAULT.DELIVERIES_TABLE + '?'}${searchs}&sort=-delivery_date&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`;
    const urlClean = url.normalize('NFD').replace('%', '');
    this.apiPath = urlClean;
    return this.findItem();
  }

  getDeliveryTableId(idDelivery: number){
    const url = `${URLS_DEFAULT.DELIVERIES_TABLE}?id=${idDelivery}`;
    return this.findItem();
  }

  getDeliveriesParams(dateInterval: any): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.DELIVERIES_TABLE}?delivery_date=bt:${dateInterval.startDate},${dateInterval.stopDate}&delivery_shipment_date=bt:${dateInterval.startDate},${dateInterval.stopDate}&sort=-delivery_date`;
    return this.findItem();
  }

  getTestDelivery(): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.DELIVERIES}`;
    return this.findItem();
  }

  getDeliveriesByTankId(tankId: number, dateInterval: any): Observable<RestModel>  {
    this.apiPath = `${URLS_DEFAULT.DELIVERIES_TABLE}?fuel_tank_id=${tankId}&delivery_date=bt:${dateInterval.startDate},${dateInterval.stopDate}&sort=-delivery_date`
    console.log("REQUISIÇÃO AQUIIIIIIII");
    console.log(this.apiPath);
    return this.findItem();
  }

  getDelivery(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.DELIVERIES;
    return this.getById(id);
  }

  createDelivery(delivery): Observable<any> {
    this.apiPath = URLS_DEFAULT.DELIVERIES
    return this.create(delivery)
  }

  updateDelivery(delivery): Observable<any> {
    this.apiPath = URLS_DEFAULT.DELIVERIES
    return this.update(delivery)
  }

  deleteDelivery(id: number){
    this.apiPath = URLS_DEFAULT.DELIVERIES
    return this.delete(id);
  }

  getConfigs(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.CONFIGS_DRIVERS
    return this.findItem();
  }

  getHeaders(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.HEADER_DRIVERS
    return this.findItem();
  }
}
