import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ShipmentPumpService } from 'src/app/services/shipments/shipment-pump.service';
import { PumpShipmentModel } from 'src/app/models/shipments/pump-shipment.model';
import { Lightbox } from 'ngx-lightbox';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';

@Component({
  selector: 'cta-form-shipment-pump',
  templateUrl: './form-shipment-pump.component.html',
  styleUrls: ['./form-shipment-pump.component.scss']
})
export class FormShipmentPumpComponent implements OnInit {

  cadastrar: FormGroup;
  @Input() shipmentPump: any;
  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Input() shipmentId: number;
  @Output() validForm = new EventEmitter();
  @Output() nextEvent = new EventEmitter();
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  valuesShipment: PumpShipmentModel;
  imageGet: any;
  pumpName: any;
  constructor(
    private fb: FormBuilder,
    private toastService: ToastrService,
    private pumpShipmentService: ShipmentPumpService,
    private _lightbox: Lightbox
  ) {

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      image_file: this.fb.control(''),
      note: this.fb.control(''),
      accumulator: this.fb.control('')
    })
  }

  private base64textString: String = "";

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview(){
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);

    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  handleFileSelect = (evt) => {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      image_file: this.base64textString,
      note: this.cadastrar.value.note,
      accumulator: this.cadastrar.value.accumulator
    });

    console.log(this.cadastrar.value);
  }

  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }


  submitValues = () => {
    const  dataForm = {
      id: this.cadastrar.value.id,
      note: this.cadastrar.value.note,
      accumulator: this.cadastrar.value.accumulator
    }
    if(this.shipmentPump.id){
      this.pumpShipmentService
      .update(this.previewUrl ? this.cadastrar.value : dataForm)
      .subscribe(
        () => {
          this.toastService.toastrConfig.positionClass = 'toast-top-center'
          this.toastService.success(`Sucesso ao editar os dados de entrega da bomba ${this.pumpName}`);
        },
        err => {
          this.toastService.warning(`Ocorreu uma falha ao tentar editar os dados de entrega da bomba ${this.pumpName}`);
        }
      )
    }else{
      const  dataForm = {
        id: this.cadastrar.value.id,
        note: this.cadastrar.value.note,
        pump: this.shipmentPump.pump.id,
        shipment_delivery: this.shipmentId,
        image_file: this.previewUrl ? this.cadastrar.value.image_file : null,
        accumulator: this.cadastrar.value.accumulator
      }
      this.pumpShipmentService.createShipmentPumps(dataForm).subscribe(
        res => {
          this.toastService.success("Sucesso ao cadastrar os dados do encerrante");
          this.nextEvent.emit('panoramica');
        },
        err => {
          this.toastService.warning("Ops, parece que houve uma falha ao tentarmos cadastrar os dados do encerrante");
        }
      )
    }


  }


  ngOnInit() {

    if (this.shipmentPump) {
      this.imageGet = this.shipmentPump.image_file !== null && this.shipmentPump.image_file !== undefined ? `${URLS_DEFAULT.URL_CURRENT}/${this.shipmentPump.image_file}` : null
      if(this.shipmentPump){
        this.pumpName = this.shipmentPump.pump.name
        this.cadastrar.setValue({
          id: this.shipmentPump.id,
          image_file: this.shipmentPump.image_file ? `${URLS_DEFAULT.URL_CURRENT}/${this.shipmentPump.image_file}` : null,
          note: this.shipmentPump ? this.shipmentPump.note : null,
          accumulator: this.shipmentPump.accumulator
        })
      }
    }

  }


  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar && this.cadastrar.valid) {
        this.submitValues();
      }
    }
  }

  showImage(): void {
    const images = [
      {
        src: this.imageGet,
        caption: 'Imagem Garagem',
        thumb: this.imageGet
      }
    ];
    this._lightbox.open(images, 0);
  }

}
