import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputValidateComponent } from './input-validate/input-validate.component';
import { CustomizerTopLayoutComponent } from './customizer-top-layout/customizer-top-layout.component';
import { ModaisModule } from './modais/modais.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import {
  MatNativeDateModule,
  MatSidenavModule,
  MatListModule,
  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
  MatDialogRef,
  MatBottomSheetRef,
  MatBottomSheet,
  MatBottomSheetModule,
  MatSelectModule,
  MatDialogModule
} from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './elements/button/button.component';
import { FiltersValidateComponent } from './filters-validate/filters-validate.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { DateInputComponent } from './elements/date-input/date-input.component';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { ThemeColorComponent } from './theme-color/theme-color.component';
import { ThemePickerComponent } from './theme-picker/theme-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FileUploadModule } from 'ng2-file-upload';
import { NotificationsComponent } from './notifications/notifications.component';
import { RouterModule } from '@angular/router';
import { TankMapComponent } from './tank-map/tank-map.component';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { SelectGasStationsComponent } from './selects/select-gas-stations/select-gas-stations.component';
import { SelectDriverComponent } from './selects/select-driver/select-driver.component';
import { SelectAttendentComponent } from './selects/select-attendent/select-attendent.component';
import { SelectCompaniesComponent } from './selects/select-companies/select-companies.component';
import { SelectPumpsComponent } from './selects/select-pumps/select-pumps.component';
import { SelectLoadedsComponent } from './selects/select-loadeds/select-loadeds.component';
import { SelectUsersComponent } from './selects/select-users/select-users.component';
import { SelectVehiclesComponent } from './selects/select-vehicles/select-vehicles.component';
import { SelectTanksComponent } from './selects/select-tanks/select-tanks.component';
import { SelectProductsComponent } from './selects/select-products/select-products.component';
import { GaleryImageComponent } from './galery-image/galery-image.component';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ClustersIconComponent } from './elements/clusters-icon/clusters-icon.component';
import { SelectProbeComponent } from './selects/select-probe/select-probes.component';
import { MdFormGasStationComponent } from './forms-pages/md-form-gas-station/md-form-gas-station.component';
import { FormGasStationComponent } from './forms-pages/form-gas-station/form-gas-station.component';
import { SelectClientComponent } from './selects/select-client/select-client.component';
import { FormProductComponent } from './forms-pages/form-product/form-product.component';
import { MdFormProductComponent } from './forms-pages/md-form-product/md-form-product.component';
import { FormClientComponent } from './forms-pages/form-client/form-client.component';
import { MdFormClientComponent } from './forms-pages/md-form-client/md-form-client.component';
import {NgxMaskModule} from 'ngx-mask';
import { FormPumpComponent } from './forms-pages/form-pump/form-pump.component';
import { MdFormPumpComponent } from './forms-pages/md-form-pump/md-form-pump.component';
import { FormTankComponent } from './forms-pages/forms-tank/form-tank/form-tank.component';
import { FormLubrificantComponent } from './forms-pages/forms-tank/form-lubrificant/form-lubrificant.component';
import { FormAdjustStockComponent } from './forms-pages/forms-tank/form-adjust-stock/form-adjust-stock.component';
import { FormModalTankComponent } from './forms-pages/modais-tank/form-modal/form-modal.component';
import { MdAdjustStockComponent } from './forms-pages/modais-tank/md-adjust-stock/md-adjust-stock.component';
import { MdLubrificantComponent } from './forms-pages/modais-tank/md-lubrificant/md-lubrificant.component';
import { SwitchComponent } from './elements/switch/switch.component';
import { TableDefaultComponent } from './table-default/table-default.component';
import { PipesModule } from '../services/helpers/pipes/pipes.module';
import { FormDeliveryComponent } from './forms-pages/form-delivery/form-delivery.component';
import { MdFormDeliveryComponent } from './forms-pages/md-form-delivery/md-form-delivery.component';
import { SelectFuelsComponent } from './selects/select-fuels/select-fuels.component';
import { TankIconMapComponent } from './elements/tank-icon-map/tank-icon-map.component';
import { SelectContractComponent } from './selects/select-contract/select-contract.component';
import { DateInputValueComponent } from './elements/date-input-value/date-input-value.component';
import { FiltersDistributorsComponent } from './filters/filters-distributors/filters-distributors.component';
import { FiltersDeliveriesComponent } from './filters/filters-deliveries/filters-deliveries.component';
import { FormModalCompaniesComponent } from './forms-pages/md-form-companies/form-modal-companies.component';
import { FormCompanieComponent } from './forms-pages/form-companie/form.component';
import { ShowFiltersDeliveriesComponent } from './filters/showfilters/show-filters-deliveries/show-filters-deliveries.component';
import { FormDriverComponent } from './forms-pages/form-driver/form-driver.component';
import { MdFormDriverComponent } from './forms-pages/md-form-driver/md-form-driver.component';
import { MdFormShipmentComponent } from './forms-pages/md-form-shipment/md-form-shipment.component';
import { FormShipmentComponent } from './forms-pages/form-shipment/form-shipment.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FormShipmentPumpComponent } from './forms-pages/form-shipment-pump/form-shipment-pump.component';
import { FormShipmentTankComponent } from './forms-pages/form-shipment-tank/form-shipment-tank.component';
import { FormShipmentGasstationComponent } from './forms-pages/form-shipment-gasstation/form-shipment-gasstation.component';
import { TooltipDataIconComponent } from './elements/tooltip-data-icon/tooltip-data-icon.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

@NgModule({
  declarations: [
    InputValidateComponent,
    CustomizerTopLayoutComponent,
    ButtonComponent,
    FiltersValidateComponent,
    DateInputComponent,
    ThemeColorComponent,
    ThemePickerComponent,
    NotificationsComponent,
    TankMapComponent,
    SelectGasStationsComponent,
    SelectDriverComponent,
    SelectAttendentComponent,
    SelectCompaniesComponent,
    SelectPumpsComponent,
    SelectLoadedsComponent,
    SelectUsersComponent,
    SelectVehiclesComponent,
    SelectTanksComponent,
    SelectProductsComponent,
    GaleryImageComponent,
    ClustersIconComponent,
    SelectProbeComponent,
    FormGasStationComponent,
    MdFormGasStationComponent,
    SelectClientComponent,
    FormProductComponent,
    MdFormProductComponent,
    FormClientComponent,
    MdFormClientComponent,
    FormPumpComponent,
    MdFormPumpComponent,
    FormTankComponent,
    FormLubrificantComponent,
    FormAdjustStockComponent,
    FormModalTankComponent,
    MdAdjustStockComponent,
    MdLubrificantComponent,
    SwitchComponent,
    TableDefaultComponent,
    FormDeliveryComponent,
    MdFormDeliveryComponent,
    SelectFuelsComponent,
    TankIconMapComponent,
    SelectContractComponent,
    DateInputValueComponent,
    FiltersDistributorsComponent,
    FiltersDeliveriesComponent,
    FormModalCompaniesComponent,
    FormCompanieComponent,
    ShowFiltersDeliveriesComponent,
    FormDriverComponent,
    MdFormDriverComponent,
    MdFormShipmentComponent,
    FormShipmentComponent,
    FormShipmentPumpComponent,
    FormShipmentTankComponent,
    FormShipmentGasstationComponent,
    TooltipDataIconComponent

  ],
  imports: [
    CommonModule,
    ModaisModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatListModule,
    MatBottomSheetModule,
    MatSelectModule,
    TooltipModule,
    NgSelectModule,
    NgOptionHighlightModule,
    AngularDateTimePickerModule,
    Ng2FlatpickrModule,
    ColorPickerModule,
    FileUploadModule,
    RouterModule,
    TimezonePickerModule,
    LightboxModule,
    MatDialogModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      confirmText: 'Sim',
      cancelText: 'Não' // set defaults here
    }),
    PipesModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule

  ],
  exports: [
      InputValidateComponent,
      CustomizerTopLayoutComponent,
      ButtonComponent,
      FiltersValidateComponent,
      DateInputComponent,
      ThemeColorComponent,
      ThemePickerComponent,
      TankMapComponent,
      SelectGasStationsComponent,
      SelectDriverComponent,
      SelectAttendentComponent,
      SelectCompaniesComponent,
      SelectPumpsComponent,
      SelectLoadedsComponent,
      SelectUsersComponent,
      SelectVehiclesComponent,
      SelectTanksComponent,
      SelectProductsComponent,
      SelectProbeComponent,
      GaleryImageComponent,
      ClustersIconComponent,
      FormGasStationComponent,
      MdFormGasStationComponent,
      SelectClientComponent,
      FormClientComponent,
      MdFormClientComponent,
      FormPumpComponent,
      FormTankComponent,
      FormLubrificantComponent,
      FormAdjustStockComponent,
      FormModalTankComponent,
      MdAdjustStockComponent,
      MdLubrificantComponent,
      SwitchComponent,
      TableDefaultComponent,
      FormDeliveryComponent,
      TankIconMapComponent,
      SelectContractComponent,
      DateInputValueComponent,
      FiltersDistributorsComponent,
      FiltersDeliveriesComponent,
      FormModalCompaniesComponent,
      FormCompanieComponent,
      ShowFiltersDeliveriesComponent,
      FormDriverComponent,
      MdFormDriverComponent,
      MdFormShipmentComponent,
      FormShipmentComponent,
      FormShipmentPumpComponent,
      FormShipmentTankComponent,
      FormShipmentGasstationComponent,
      TooltipDataIconComponent

  ],
  entryComponents: [
    ThemeColorComponent,
    MdFormGasStationComponent,
    MdFormGasStationComponent,
    MdFormClientComponent,
    MdFormPumpComponent,
    FormModalTankComponent,
    FormTankComponent,
    MdAdjustStockComponent,
    MdLubrificantComponent,
    MdFormDeliveryComponent,
    FiltersDistributorsComponent,
    FiltersDeliveriesComponent,
    FormModalCompaniesComponent,
    FormCompanieComponent,
    FormDriverComponent,
    MdFormDriverComponent,
    MdFormShipmentComponent,
    FormShipmentComponent,
    FormShipmentPumpComponent,
    FormShipmentTankComponent,
    FormShipmentGasstationComponent,
    TooltipDataIconComponent
  ],
  providers: [
    {provide: MatBottomSheetRef, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    {provide: MatBottomSheet, useValue:{}},
    {provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
  ],

})
export class ComponentsModule { }
