import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalConfirm } from 'src/app/models/modal-confirm';
import { TopButtons } from 'src/app/models/top-buttons';
import { HeadTable } from '../../../../models/configurations/head.model';

@Component({
  selector: 'cta-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor() { }

  @Input() headers: HeadTable[];
  @Input() list: any[];
  @Input() configsTable: object;
  @Output() actionList = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();
  @Output() eventButton = new EventEmitter();
  @Input() stylesTopButtons: TopButtons[];
  @Input() dataModal: ModalConfirm[];
  @Input() exportPdf: boolean;
  dataModalValue: ModalConfirm[];

  ngOnInit() {
  }


  eventTopButton = event => {
    this.eventButton.emit(event);
   }

  actionTable = (event) => {
    this.actionList.emit(event);
  }


}
