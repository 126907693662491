import { Component, OnChanges, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TanksService } from 'src/app/services/tanks/tanks.service';
import { ConvertersService } from 'src/app/services/helpers/converters.helpers';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PumpsService } from 'src/app/services/pumps/pumps.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'cta-form-tank',
  templateUrl: './form-tank.component.html',
  styleUrls: ['./form-tank.component.scss']
})

export class FormTankComponent implements OnChanges, OnInit {

  cadastrar: FormGroup;
  data: any;
  @Input() title: any[];
  @Input() dispachForm: boolean;
  @Input() values: any;
  @Output() validForm = new EventEmitter();
  @Output() requisitionSuccess = new EventEmitter();
  listPumps: any[] = [];
  tankId: number;
  valueGasStation: number;
  valueProduct: number;
  valuePlumb: number;
  valueClient: number;
  valueContractType: number;
  headersPumps = [
    {
      id: 0,
      title: 'Código',
      value: 'export_code'
    },
    {
      id: 1,
      title: 'Nome',
      value: 'name'
    },
    {
      id: 2,
      title: 'Nome Curto',
      value: 'short_name'
    }
  ];



  selectedAccount = null;
  constructor(
    private fb: FormBuilder,
    private tankService: TanksService,
    private convertService: ConvertersService,
    private toastService: ToastrService,
    private pumpService: PumpsService,
    private productService: ProductsService
  ) {
    const format_date = moment().format('d/m/Y H:M:S');
    const probeLastUpdate = this.convertService.convertUtc(format_date);

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      gas_station: this.fb.control('', [Validators.required]),
      product: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required, Validators.minLength(3)]),
      capacity: this.fb.control('', [Validators.required]),
      initial_volume: this.fb.control(0),
      asset_number: this.fb.control(null),
      probe_type: this.fb.control(null),
      contract_type: this.fb.control(null, [Validators.required]),
      probe_last_update: this.fb.control(probeLastUpdate)
    });
  }

  ngOnInit() {
    this.setValuesForm(false);
  }

  ngOnChanges(changes: SimpleChanges): void {

    console.log("TESTE DISPACH FORM");
    console.log(changes);
    this.setValuesForm(changes["values"] && changes["values"].currentValue)
    if (changes['dispachForm'] && changes['dispachForm'].currentValue === true) {
      this.submitValues();
    }
  }

  setValuesForm = (currentValue) => {
    if (this.values && currentValue) {
      const dataTank = this.values.results[0];
      this.valueClient = dataTank.gas_station.client.id;
      this.valueGasStation = dataTank.gas_station.id;
      this.valueProduct = dataTank.product.id;
      this.valueContractType = dataTank.contract_type.id;

      this.cadastrar.setValue({
        id: dataTank.id,
        gas_station:  dataTank.gas_station.id,
        product: dataTank.product.id,
        name: dataTank.name,
        capacity: dataTank.capacity,
        initial_volume: dataTank.initial_volume,
        probe_type: dataTank.probe_type && dataTank.probe_type.id ? dataTank.probe_type.id : null,
        probe_last_update: dataTank.probe_last_update,
        contract_type: dataTank.contract_type.id,
        asset_number: dataTank.asset_number
      })
    }
  }

  submitValues = () => {
    if (this.cadastrar.valid && !this.values) {
      this.tankService.createTank(this.cadastrar.value)
        .subscribe(
          (res) => {
            this.toastService.success("Novo tanque cadastrado com sucesso!");
            const tankId = res.results[0].id;
            this.tankId = tankId;
            localStorage.setItem('lastIdSaveTank', tankId);
            this.requisitionSuccess.emit(tankId);
          },
          err => {
            this.toastService.error("Ops. Ocorreu um erro ao tentar salvar o tanque");
            console.log('err', err);
          }
        );
    }else{
      this.tankService.updateTank(this.cadastrar.value)
      .subscribe(
        (res) => {
          this.toastService.success(" Tanque editado com sucesso!");
          const tankId = res.results[0].id;
          this.tankId = tankId;
          localStorage.setItem('lastIdSaveTank', tankId);
          this.requisitionSuccess.emit(tankId);
        },
        err => {
          this.toastService.error("Ops. Ocorreu um erro ao tentar salvar o tanque");
          console.log('err', err);
        }
      );
    }
  }

  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }

  getNameProduct = (idProduct :number) => {
    this.productService.getProductId(idProduct).subscribe(
      res => {
        return res.results[0].name;
      },
      err => {
        console.log(err);
        console.log("erro ao tentar buscar o nome do produto");
      }
    )
  }

  selectedEvents = (event, value) => {

    if (event === 'gas_station') {
      this.valueGasStation = value;
    } else if (event === 'product') {
      this.valueProduct = value;
      this.cadastrar.value.name = this.getNameProduct(value);
    } else if (event === 'plumb') {
      this.valuePlumb = value;
    } else if (event === 'client') {
      this.valueClient = value;
    } else if (event === 'contract_type') {
      this.valueContractType = value;
    }

    if (this.valueGasStation && this.valueProduct  && this.valueContractType) {

      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        product: this.valueProduct,
        gas_station: this.valueGasStation,
        name: this.cadastrar.value.name,
        capacity: this.cadastrar.value.capacity,
        initial_volume: this.cadastrar.value.initial_volume,
        asset_number: this.cadastrar.value.asset_number,
        probe_type: this.valuePlumb ? this.valuePlumb : this.cadastrar.value.probe_type,
        probe_last_update: this.cadastrar.value.probe_last_update,
        contract_type: this.valueContractType
      })

      if (this.cadastrar.valid) {
        this.submitEmmiterButton(this.cadastrar.valid);
      }
    }
  }
}
