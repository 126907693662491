import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from '../../modais/confirm/confirm.component';
import { NgSelectConfig } from '@ng-select/ng-select';
import { filter } from 'rxjs/operators';
import { MdSelectedsTableComponent } from '../../modais/md-selecteds-table/md-selecteds-table.component';
import { HeadTable } from '../../../models/configurations/head.model';
import { FiltersDeliveriesComponent } from '../../filters/filters-deliveries/filters-deliveries.component';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'cta-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.scss']
})
export class ReportsFilterComponent implements OnInit, OnChanges {


  filtros: any[] = [];
  constructor( public dialog: MatDialog, private toastr: ToastrService,  private configSelect: NgSelectConfig) {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
  }
  


  messageAction: any;
  @Input() headers: HeadTable[];
  @Input() list: any[];
  @Input() configsTable: object;
  @Input() exportPdf: boolean;
  @Output() actionList = new EventEmitter();
  @Output() eventButton = new EventEmitter();
  @Input() configs: object;
  @Output() actionEvent = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();
  countSelecteds: number;
  checkedsTable: any[];
  currentPage = 1;
  maxPerPage = 10;
  searchValue: string = null;
  loading: boolean;
  loadingView: boolean;
  filtersLoaded = true;
  reportSelected: string;
  filtersSelected: any[];
  reportEdit = false;
  ngOnInit() {
    this.filtros = [
      {id: 1, name: 'Carregamentos'},
      {id: 1, name: 'Abastecimentos'},
      {id: 1, name: 'Média por Motorista'},
      {id: 1, name: 'Abastecimentos por turno'},
      {id: 1, name: 'Frota'},
      {id: 1, name: 'Viagem por veículo'},
      {id: 1, name: 'Economia por veículo'},
      {id: 1, name: 'Lançamentos de Lubrificantes'},
      {id: 1, name: 'Extrato por empresa'},
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reportEdit = !this.reportEdit;
    console.log("changes === > ", changes);
    if (changes.exportPdf.currentValue === true) {
      this.reportEdit = false;
      setTimeout(() => {
        if (!this.reportEdit) {
          console.log("chegou aqui ... ")
          this.generateFilePDF();        
        }
      }, 1000);     
    }
  }
  getSearched = (value) => {
    this.searchValue = value;
  }

  getDataPage = (value) => {
    this.maxPerPage = value.maxPerPage;
    this.currentPage = value.currentPage;
  }

  dispareAction = (event) => {
    console.log('dados do evento:  ',  event);
    this.actionEvent.emit(event);
  }

  setSelected = checkeds => {
    this.countSelecteds = checkeds.length;
    this.checkedsTable = checkeds;
  }

  getDateHour = (event, period) => {
    console.log('event ', event);
    console.log('period', period);
  }


  // openDialog(action: string) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.autoFocus = true;
  //   this.actionList.emit(action);
  //   // this.getMensagens(action);
  //   // dialogConfig.data = {
  //   //   title: this.messageAction,
  //   //   description: '',
  //   //   function: 'delete'
  //   // };

  //   // const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);

  //   // dialogRef.afterClosed().subscribe(result => {
  //   //   console.log(`Dialog result: ${result}`);
  //   //   this.toastr.info('Sua alteração foi executada com sucesso!');
  //   // });

  // }



  openFilter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(FiltersDeliveriesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      const arrkeys = Object.keys(result);
      const filters = [];
      arrkeys.forEach(item => {
        console.log(result[item]);
        filters.push({name: item, value: result[item]});
      });
      this.filtersSelected = filters;
    });

  }

  getMensagens = (action: string) => {

    const msgPlurarDelete = 'Você tem certeza que deseja deletar estes itens?';
    const msgSingDelete = 'Você tem certeza que deseja deletar este item?';
    const msgPlurarEdit = 'Você tem certeza que deseja editar estes itens?';
    const msgSingEdit = 'Você tem certeza que deseja editar este item?';
    const msgPlurarStatus = 'Você tem certeza que deseja modificar os status destes itens?';
    const msgSingStatus = 'Você tem certeza que deseja modificar o status deste item?';
    const msgDefault = 'Você tem certeza que deseja executar esta  ação';
    switch (action) {
      case 'delete':
        this.messageAction = this.countSelecteds ? msgPlurarDelete : msgSingDelete;
        break;
      case 'edit':
        this.messageAction = this.countSelecteds ? msgPlurarEdit : msgSingEdit;
        break;
      case 'status':
        this.messageAction = this.countSelecteds ? msgPlurarStatus : msgSingStatus;
        break;
      default:
        this.messageAction = msgDefault;
        break;
    }
  }


  changeSelectedFilter = (event) => {
    if (event) {
      this.loading = true;
      this.reportSelected = event.name;
      setTimeout(() => {
        this.loading = false;
        this.filtersLoaded = false;
        this.openFilter();
      }, 3000);

    } else {
      this.loading = false;
      this.filtersLoaded = true;
      this.reportSelected = null;
      this.filtersSelected = [];
    }

  }

  alterViewTable = () => {
    this.loadingView = true;
    setTimeout(() => {
      this.reportEdit = !this.reportEdit;
      this.loadingView = false;
    }, 2000);

  }

  valueDrawElementPdf: any[] = [];
  generateFilePDF(){
    
    if(this.list.length){
      // this.headList = this.list.filter(item => item.head)[0]
      const dataTable = this.list.filter(item => !item.head)

      let myProps = [];

      for (const item of dataTable) {
        let propsIn = Object.keys(item);
        propsIn.forEach(element => {
          if(!myProps.includes(element)){
            myProps.push(element);
          }
        });
      }

      const bodyFile: any[] = [];
      for (const item of this.list) {
        let line = [];
        for (const prop of myProps) {

          line.push(item[prop]);

          if(line.length === myProps.length){
            if(!bodyFile.includes(line)){
              line.shift();
              bodyFile.push(line);
            }
            line = [];
          }
        }
      }
      let newHead = [];
      this.headers.forEach(element => {
        newHead.push(element.title);
      })

      var element = document.getElementById("content")

      html2canvas(element).then((canvas) => {
  
        var imgData = canvas.toDataURL('image/png');        
        var imgWidth = 210;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var doc = new jsPDF('p', 'mm');
        var position = 0;
        var imgHeight = canvas.height * 210 / canvas.width;
        console.log("testando bodyFile =======>>>>> ", bodyFile);
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        let page =+ doc.internal.getNumberOfPages() ;
        doc.autoTable({
          headStyles: { fillColor: [0, 173, 154] },
          footStyles: {fillColor: [0, 173, 154]},
          rowStyles: {fillColor: [245, 245, 245]},
          margin: {top: 120}, // Seting top margin for First Page.
          didDrawPage:  (data) => {
            data.settings.margin.top = 10;            
            this.valueDrawElementPdf.push(data);            
          },
          head:  [newHead],
          body: bodyFile,          
        }); 
        
        this.addFooters(doc);        
        doc.save("relatorio.pdf");
        // this.exportPdf = false;
      });


    }

  }
  

  footerPage = async (doc) => {
    var element = document.getElementById("footer")

    html2canvas(element).then((canvas) => {

      var imgData = canvas.toDataURL('image/png');        
      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;     
      var position = 0;
      // var imgHeight = canvas.height * 210 / canvas.width;
      
      doc.addImage(imgData, 'PNG', 210, position, imgWidth, imgHeight);     

    });
  }


  addFooters  = async (doc) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(12)
    for (var i = 1; i <= pageCount; i++) {      
      doc.setPage(i)
      
      var img = new Image();
      img.src = "../../../../../assets/images/footer_pdf.png";
      doc.addImage(img, 'png', 0, 287);
      
      doc.text('Página ' + String(i) + ' de ' + String(pageCount), 26, 293, {
        align: 'center'
      })
    }
  }


}
