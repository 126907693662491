import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'cta-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent implements OnInit {

  private themeWrapper = document.querySelector('body');
  uploader: FileUploader = new FileUploader({ url: URL });
  hasBaseDropZoneOver: boolean = false;
  hasAnotherDropZoneOver: boolean = false;
  selectedFont: string;
  nameSelectedUpload: string;
  ngOnInit(): void {
    console.log('themeWrapper ', this.themeWrapper);
  }

  onSubmit(form) {
    this.global(form.value);
  }

  global(stylesheet) {
    console.log('stylesheet ', stylesheet);
    // Navigation Styles
    if (stylesheet.primaryColor) {

      this.themeWrapper.style.setProperty('--primaryColor', stylesheet.primaryColor);
    }
    if (stylesheet.orangeColor) {
      this.themeWrapper.style.setProperty('--orangeColor', stylesheet.orangeColor);
    }
    if (stylesheet.fontPrimaryColor) {
      this.themeWrapper.style.setProperty('--fontPrimaryColor', stylesheet.fontPrimaryColor);
    }
    if (stylesheet.chartBlack) {
      this.themeWrapper.style.setProperty('--chartBlack', stylesheet.chartBlack);
    }
    if (stylesheet.primaryColor) {
      this.themeWrapper.style.setProperty('--primaryColor', stylesheet.primaryColor);
    }

  }

  setFont = event => {
    this.selectedFont = event.target.value;
  }

  selectedFile = event => {
    this.nameSelectedUpload = event.target.files[0].name;
  }
}
