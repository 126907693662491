import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';
import { Observable } from 'rxjs';
import { RestModel } from 'src/app/models/configurations/rest.model';
import { Pagination } from '../../models/pagination';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends CrudService {

  getFilters(filters, pagination: Pagination): Observable<RestModel> {
    if (
        filters && filters.hasOwnProperty('parameter')
        && filters.hasOwnProperty('search')
        && filters.search !== ""
        && filters.search !== null
    ) {
      this.apiPath = `${URLS_DEFAULT.COMPANIES}?${filters.parameter}=${filters.search}&sort=+name&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`;
    } else {
      this.apiPath = `${URLS_DEFAULT.COMPANIES}?sort=+name&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`;
    }

    return this.findItem()
  }

  results: any;

  get(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.COMPANIES;
    return this.findItem();
  }

  getCompaniesTable(filters, pagination: Pagination): Observable<RestModel> {
    if (filters && filters.hasOwnProperty('parameter') && filters.hasOwnProperty('search') && filters.search !== "" && filters.search !== null) {
      this.apiPath = `${URLS_DEFAULT.TABLE_COMPANIES}?start_date=${filters.date.startDate}&end_date=${filters.date.stopDate}&product_category=${filters.product}&${filters.parameter}=${filters.search}&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`
    } else {
      this.apiPath = `${URLS_DEFAULT.TABLE_COMPANIES}?start_date=${filters.date.startDate}&end_date=${filters.date.stopDate}&product_category=${filters.product}&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`
    }

    return this.findItem()
  }


  getCompany(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.COMPANIES;
    return this.getById(id);
  }

  getCompaniesClientId(idClient: number): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.COMPANIES}?client=${idClient}`;
    return this.findItem();
  }

  getConfigs(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.CONFIGS_COMPANIES
    return this.findItem();
  }

  getHeaders(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.HEADER_COMPANIES
    return this.findItem();
  }

  getLabelsForm(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.FORM_COMPANIES
    return this.findItem();
  }

  updateCompanie = (dataForm) => {
    this.apiPath = URLS_DEFAULT.COMPANIES
    return this.update(dataForm);
  }

  createCompanie = (dataForm) => {
    this.apiPath = URLS_DEFAULT.COMPANIES
    return this.create(dataForm);
  }

  deleteCompany = (id: number) => {
    this.apiPath = URLS_DEFAULT.COMPANIES
    return this.delete(id)
  }

  getProductCategory = () => {
    this.apiPath = URLS_DEFAULT.PRODUCT_CATEGORIES
    return this.findItem();
  }

}
