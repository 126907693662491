import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';
import { Pagination } from '../../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends CrudService{

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  getClients(): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.CLIENTS}?sort=+name`;
    return this.findItem();
  }


  getFilters(filters, pagination: Pagination): Observable<RestModel> {
    if (
        filters && filters.hasOwnProperty('parameter')
        && filters.hasOwnProperty('search')
        && filters.search !== ""
        && filters.search !== null
    ) {
      this.apiPath = `${URLS_DEFAULT.CLIENTS}?${filters.parameter}=${filters.search}&sort=-name&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`;
    } else {
      this.apiPath = `${URLS_DEFAULT.CLIENTS}?sort=+name&page_size=${pagination.maxPerpage}&page=${pagination.currentPage}`;
    }

    return this.findItem()
  }

  getgetClientById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.CLIENTS;
    return this.getById(id);
  }

  deleteClientById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.CLIENTS;
    return this.delete(id);
  }
}
