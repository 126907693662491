

export const DISTRIBUTORS_MENU = [
  {
    id: 1,
    icon: "mdi-view-dashboard",
    label: "Dashboard",
    link: "dashboard",
    subtitle: "Resumo do sistema",
    title: "Dashboard",
  },
  {
    icon: "mdi-domain",
    id: 2,
    label: "Empresas",
    link: "empresas",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Tabela com lista de suas empresas",
        title: "Listar empresas",
      },

    ],
    subtitle: "Veja suas empresas aqui",
    title: "Empresas"
  },
  {
    icon: "mdi-account-group-outline",
    id: 3,
    label: "Clientes",
    link: "clientes",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Tabela com lista de suas empresas",
        title: "Listar empresas",
      },

    ],
    subtitle: "Veja suas empresas aqui",
    title: "Empresas"
  },

  {
    id: 4,
    icon: "mdi-account-card-details",
    label: "Motoristas",
    link: "motoristas",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Tabela com lista de seus motoristas",
        title: "Listar motoristas"
      },
      {
        label: "Criar",
        link: "criar",
        subtitle: "frontend.motoristas.submenu.2.subtitle",
        title: "Cadastrar motorista"
      }
    ],
    subtitle: "Tabela com lista de seus motoristas",
    title: "Motoristas Cadastrados",
  },
  {
    icon: "mdi-tanker-truck",
    id: 5,
    label: "Carregamentos",
    link: "carregamentos",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Edite os carregamentos cadastrados e faça ações",
        title: "Listar"
      },

    ],
    subtitle: "Tabela com lista de suas bombas",
    title: "Carregamentos Cadastrados",
  },
  {
    icon: "mdi-garage-open",
    id: 6,
    label: "Garagens",
    link: "garagens",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Veja, crie, 3dite e exclua garagens",
        title: "Listar"
      },

    ],
    subtitle: "Tabela com lista de suas bombas",
    title: "Carregamentos Cadastrados",
  },
  {
    icon: "mdi-car-coolant-level",
    id: 6,
    label: "Tanques",
    link: "tanques",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Tabela com lista de seus tanques",
        title: "Listar frentistas"
      },
    ],
    subtitle: "Tabela com lista de seus tanques",
    title: "Tanques Cadastrados"
  },

  {
    icon: "mdi-gas-station",
    id: 7,
    label: "Bombas",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Visualize, edite e exclua bombas",
        title: "Listar"
      }
    ],
    link: "bombas",
    subtitle: "Tabela com lista de suas bombas",
    title: "Bombas Cadastradas"
  },
  {
    icon: "mdi-finance",
    id: 8,
    label: "Relatórios",
    link: "relatorios",
    submenu: [
      {
        label: "Listar",
        link: "listar",
        subtitle: "Visualize, edite e exporte relatórios no formato desejado",
        title: "Listar"
      }
    ],
    subtitle: "Visualize e exporte relatórios no formato desejado",
    title: "Relatórios"
  },

];
