const parameters = 'system?category='
const url_prod = 'https://www.ctasmart.com.br:8443';
const url_local = 'https://dev.ctaplus.com.br:8443';
const url_prod_rest = `${url_prod}/rest/`;
const url_dev_rest = `${url_local}/rest/`;

// Para colocar o build em produção antes de faze-lo modificar as propiedades
// BUILD_PROD para true 
// URL_CURRENT se for testar no servidor dev manter BASE_URL: url_dev_rest SE for para produção BASE_URL: url_prod_rest,

export const URLS_DEFAULT = {
    BUILD_PROD: false,
    BASE_URL: url_dev_rest,
    URL_CURRENT: url_local,
    AUTENTICATION: 'SvWebGetToken?formato=json',
    MENU: `${parameters}LIST_MENU`,
    MENU_PREFERENCES: `${parameters}MENU_PREFERENCIAS`,
    LOG: 'log_request',
    VEHICLES: 'vehicles',
    COMPANIES: 'companies',
    CONTRACT_TYPES: 'fuel_tank_contract_types',
    TABLE_COMPANIES: 'companies_table',
    DISTRIBUTORS_MAP: `distributors_map`,
    TABLE_BALANCE_COMPANY: 'balance_by_companies',
    VEHICLE_CATEGORIES: 'vehicle_categories',
    VEHICLE_MODELS: 'vehicle_models',
    CLIENTS: 'clients',
    FUELINGS: 'fuelings',
    GAS_STATIONS: 'gas_stations',
    FUEL_TANKS: 'fuel_tanks',
    FUEL_TANKS_TABLE: `fuel_tanks_table`,
    PUMPS: 'pumps',
    SHIPMENT_DELIVERIES: 'shipment_deliveries',
    IMAGE_SHIPMENT_PUMP: 'image_shipments_pump',
    IMAGE_SHIPMENT_TANK: 'image_shipments_fuel_tank',
    IMAGE_SHIPMENT_GASSTATION: 'image_shipments_gas_station',
    PRODUCTS: 'products',
    PRODUCT_CATEGORIES: 'product_categories',
    USERS: 'users',
    DISTRIBUTOR_DRIVERS: 'distributor_drivers',
    SESSION: 'session',
    SYSTEM: 'system',
    CONFIGS_COMPANIES: `${parameters}CONFIGS_COMPANIES`,
    FORM_COMPANIES: `${parameters}FORM_COMPANIES`,
    HEADER_COMPANIES: `${parameters}HEADER_COMPANIES`,
    HEADER_GAS_STATION: `${parameters}HEADER_GAS_STATION`,
    HEADER_BALANCES: `${parameters}HEADER_BALANCES`,
    HEADER_DRIVERS: `${parameters}HEADER_DRIVERS`,
    CONFIGS_DRIVERS: `${parameters}CONFIGS_DRIVERS`,
    HEADER_DISTRIBUTOR: `${parameters}HEADER_DISTRIBUTOR`,
    HEADER_TANKS: `${parameters}HEADER_TANKS`,
    CONFIGS_GAS_STATION: `${parameters}CONFIGS_GAS_STATION`,
    PROBE_TYPES: `probe_types`,
    DELIVERIES: `deliveries`,
    DELIVERIES_TABLE: 'deliveries_table'
}
