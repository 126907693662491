import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-md-form-product',
  templateUrl: './md-form-product.component.html',
  styleUrls: ['./md-form-product.component.scss']
})
export class MdFormProductComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
