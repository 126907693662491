import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cta-form-client',
  templateUrl: './form-client.component.html',
  styleUrls: ['./form-client.component.scss']
})
export class FormClientComponent implements OnInit {

  cadastrar: FormGroup;

  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Output() validForm = new EventEmitter();
  @Input() client: any;
  distributor: any;
  constructor(
    private fb: FormBuilder,
    private clientService: ClientsService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    const user = sessionStorage.getItem('currentUser');
    const dataUser =  JSON.parse(user);
    const distributor_id = dataUser[0]['id'];
    this.distributor = distributor_id

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      name: this.fb.control('', [Validators.required, Validators.minLength(3)]),
      distributor: distributor_id
    })

    if(this.client){
      console.log("TESTE CLIENT FORM", this.client);
      this.cadastrar.setValue({
        id: this.client.id,
        name: this.client.name,
        distributor: distributor_id
      })
    }
  }

  submitEmmiterButton = event => {
    const caracteres = event.target.value;
    this.validForm.emit(this.cadastrar.valid);
  }

  submitValues = () => {
    if(this.client){
      this.clientService.update(this.cadastrar.value).subscribe(
        res => {
          localStorage.setItem('lastSaveClient', res.results[0].id)
          this.toastService.success("Cliente editado com sucesso")
        },
        err => {
          console.log('err');
          this.toastService.error("Ops! Ocorreu uma falha ao tentar editar o cliente")
        }
      );
    }else{
      this.clientService.create(this.cadastrar.value).subscribe(
        res => {
          localStorage.setItem('lastSaveClient', res.results[0].id)
          this.toastService.success("Cliente cadastrado com sucesso")
        },
        err => {
          console.log('err');
          this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o cliente")
        }
      );
    }

  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar && this.cadastrar.valid) {
        this.submitValues();
      }
    }
  }

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

  selectedEvents = (event, value) => {

  }

}
