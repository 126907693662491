import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'cta-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss']
})
export class ListFiltersComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() filtersSelected: any[];
  @Output() clearFilters = new EventEmitter();
  filtersShow: any[] = [];
  ngOnInit() {
    this.filtersShow =  this.filtersSelected.filter((item) => item.title);

  }

  ngOnChanges(changes: SimpleChanges){

     this.filtersShow =  this.filtersSelected.filter((item) => item.title);
  }

  eventClearFilters(filter){
    this.clearFilters.emit(filter);

  }

  formatDate (UNIX_timestamp){

    return  moment.unix(UNIX_timestamp/1000).format('DD/MM/YYYY - HH:mm:ss ');
  }


}
