export const MENU_CONFIGS = [
  {
    id: 1,
    label: 'Preferências',
    icon: 'mdi-account-settings',
    title: 'Preferências',
    link: 'preferencias',
    subtitle: 'Modifique os estilos e configurações do sistema conforme sua empresa.',
    submenu: [
        {id: 1, label: 'Cores'},
        {id: 1, label: 'Menus'},
    ]
  },
  {
    id: 2,
    label: 'Trocar de usuário',
    icon: 'mdi-account-switch',
    link: 'trocar-usuario',
    title: 'Trocar de usuário',
    subtitle: 'Modifique os estilos e configurações do sistema conforme sua empresa.',

  },
  {
      id: 3,
      label: 'Sair',
      icon: 'mdi-exit-to-app',
      submenu: [
          {id: 1, label: 'Listar'},
          {id: 1, label: 'Criar'},
          {id: 1, label: 'Dashboard'},
      ]
  }
];
