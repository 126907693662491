import { Component, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'cta-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  constructor() { }
  @Output() searched = new EventEmitter()

  getValueSearch = (event) => {
    console.log("ocorre aqui", event.target.value);
    this.searched.emit(event.target.value);
  }

}
