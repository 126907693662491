import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cta-md-form-pump',
  templateUrl: './md-form-pump.component.html',
  styleUrls: ['./md-form-pump.component.scss']
})
export class MdFormPumpComponent  {
  fuelTankId: number
  constructor(@Inject(MAT_DIALOG_DATA) data,) {
    this.fuelTankId = data.idTank

    if(data && data.values){
      this.valuesPumpForm = data.values[0]
      if(data.values.results){
        this.valuesPumpForm = data.values.results[0]
      }

    }
   }
  valuesPumpForm: any
  dispachForm: boolean;
  showButton: boolean;

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }
}
