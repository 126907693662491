import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DndDropEvent } from 'ngx-drag-drop';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cta-config-header',
  templateUrl: './config-header.component.html',
  styleUrls: ['./config-header.component.scss']
})
export class ConfigHeaderComponent implements OnInit {

  activeColumns: any[] = [];
  inactiveColumns: any[] = [];
  public constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private dialogRef: MatDialogRef<ConfigHeaderComponent>
  ) {
    this.activeColumns = data.activeColumns;
    this.inactiveColumns = data.inactiveColumns

  }

  ngOnInit() {
    console.log("entrou")
  }

  draggable = {
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  draggableListLeft = [
    {
      content: "Left",
      effectAllowed: "move",
      disable: false,
      handle: false,
    },
    {
      content: "Lefter",
      effectAllowed: "move",
      disable: false,
      handle: false,
    },
    {
      content: "Leftest",
      effectAllowed: "copyMove",
      disable: false,
      handle: false
    },
    {
      content: "Lefty",
      effectAllowed: "move",
      disable: false,
      handle: true,
    }
  ];

  onDraggableMovedActives = (event: DndDropEvent, element) => {
    console.log("draggable moved", event);
    console.log(element);
  }
  onDragEnd = (event: DndDropEvent) => {
    console.log("on drag end => ", event);
  }

  onDropActives = (event: any) => {

    console.log("teste draggableListLeft === >>> ", event);

    if (event.dropEffect !== 'move') {
      let titlesActives = this.activeColumns.length ? this.activeColumns.map(item => item.title) : [''];
      if (!titlesActives.includes(event.data.title)) {
        this.activeColumns.push(event.data);
        var removeIndex = this.inactiveColumns.map((item) => item.title).indexOf(event.data.title);
        this.inactiveColumns.splice(removeIndex, 1);
      }
    }

    if (this.activeColumns && (event.dropEffect === "move")) {

      let index = event.index;
      if (typeof index === "undefined") {
        index = this.activeColumns.length;
      }
      if (index > 1) {
        let idx = this.activeColumns.map((item) => item.title).indexOf(event.data.title);
        console.log("teste idx ===>> ", idx);
        console.log(index)

        if (idx > 0) {
          this.activeColumns.splice(idx, 1);
        }

        this.activeColumns.splice(index, 0, event.data);
      } else {
        
        this.toast.warning(
          `Para reordenar a primeira coluna, ${this.activeColumns[1].title}, arraste a coluna para a ordem desejada de cima para baixo`,
          'Observação:',          
          {
            timeOut: 10000,
            positionClass: 'toast-top-center'
        });
      }


    }
  }

  onDropInactive = (event: any) => {
    let titlesActives = this.inactiveColumns.map(item => item.title);
    if (!titlesActives.includes(event.data.title)) {
      this.inactiveColumns.push(event.data);
      var removeIndex = this.activeColumns.map((item) => item.title).indexOf(event.data.title);
      this.activeColumns.splice(removeIndex, 1);
    }
  }



  aplyAcitveActions = false;
  applyConfigs = () => {
    this.aplyAcitveActions = true
    const values = {
      inactiveColumns: this.inactiveColumns,
      activeColumns: this.activeColumns
    };
    if (this.aplyAcitveActions) {
      console.log("está chegando aqui ... ", this.aplyAcitveActions);
      console.log(values);
      this.dialogRef.close(values);
    }

  }
}
