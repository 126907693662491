import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { DeliveriesService } from 'src/app/services/deliveries/deliveries.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FiltersDeliveriesComponent } from 'src/app/components/filters/filters-deliveries/filters-deliveries.component';
import { formatNumber } from '@angular/common';
import { ProductsService } from 'src/app/services/products/products.service';
import { PumpsService } from 'src/app/services/pumps/pumps.service';
import { MdFormDeliveryComponent } from 'src/app/components/forms-pages/md-form-delivery/md-form-delivery.component';
import { MdFormShipmentComponent } from 'src/app/components/forms-pages/md-form-shipment/md-form-shipment.component';
import { Pagination } from '../../../../models/pagination';

@Component({
  selector: 'cta-table-deliveries',
  templateUrl: './table-deliveries.component.html',
  styleUrls: ['./table-deliveries.component.scss']
})
export class TableDeliveriesComponent implements OnChanges, OnInit {


  @Input() filtersDinamics: any
  @Input() tankSelected: number;
  @Input() updateTableDeliveries: boolean;
  @Output() actionEvent = new EventEmitter();
  listDeliveries: any[] = [];
  dataDistributor: any[];
  countSelecteds: number;
  selectedsItens: any[] = [];
  currentPage = 1;
  pagination = new Pagination(10, 1);
  searchValue: string = null;
  disableActionsEvent = true;
  defaultDateVal: Object;

  filtersSelected: any[] = [];
  valuesFormFilterAdvanced: any = null;
  maxPerPage = 100;
  sortItem: string = 'id';
  startDate: any =   moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).subtract(30, 'days').unix()  * 1000;
  stopDate: any = moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}).unix() * 1000;
  constructor(
    public dialog: MatDialog,
    private deliveriesService: DeliveriesService,
    private productService: ProductsService,
    private pumpService: PumpsService
  ) { }

  headers: any[];
  configsTable = [
    {
      name: "edit",
      icon: "mdi-pencil-box",
      tooltip: "Editar Entrega"
    }
  ]

  ngOnInit(){
   // this.getHeadersTable();
    this.clearFilters(this.filtersSelected);
    this.defaultDateVal = {
      startDate: this.startDate,
      stopDate: this.stopDate,
    }
    let value = `${this.startDate},${this.stopDate}`;
    const valueFilterDate = this.normalizeStr(value);
    this.filtersSelected.push(
      {name: 'fuel_tank_id', value: this.tankSelected},
      {
        name: 'delivery_date',
        value: valueFilterDate,
        start: this.startDate,
        stop: this.stopDate,
        title: 'De:'
      },

    );
   this.headers = [
      {
        id: 1,
        title: 'Data e Hora',
        value: 'delivery_date'
      },
      {
        id: 2,
        title: 'Motorista',
        value: 'distributor_driver_name'
      },
      {
        id: 3,
        title: 'Volume',
        value: 'volume'
      },
      {
        id: 4,
        title: 'Estoque informado',
        value: 'occupation'
      },
      {
        id: 5,
        title: 'Produto',
        value: 'product'
      },
      {
        id: 6,
        title: 'Custo Unitário',
        value: 'unit_cost'
      },
      {
        id: 7,
        title: 'Encerrante',
        value: 'pump_closers'
      },
      {
        id: 8,
        title: 'Consumo Calculado (Estoque)',
        value: 'period_comsume'
      },
      {
        id: 9,
        title: 'Consumo Calculado (Encerrantes)',
        value: 'calculate_comsume'
      },
      {
        id: 10,
        title: 'Divergência',
        value: 'divergence'
      },
    ];


    this.getDeliveriesFilters();

    console.log("DATA CRIADA");
    console.log(moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).subtract(30, 'days').unix()  * 1000);
  }

  ngOnChanges(changes: SimpleChanges){

    let valueDate  =  `${this.startDate},${this.stopDate}`;
    const valueFilterDate = this.normalizeStr(valueDate);
    this.filtersSelected.push(
      {
        name: 'delivery_date',
        value: valueFilterDate,
      },
      {
        name: 'delivery_shipment_date',
        value: valueFilterDate,
      },
      {
        name: 'fuel_tank_id',
        value: this.tankSelected,
      }
    )

    this.getDeliveriesFilters()
  }

  convertDateUtc(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  setSelected = (event) => {

  }

  dispareAction = (event) => {
    const valueDate = `${this.startDate}, ${this.stopDate}`;

    switch (event.action) {

      case 'dateHourEvent':
          this.startDate = event.startDateHour
          this.stopDate = event.stopDateHour
          const valueFilterDAte = this.normalizeStr(valueDate);
          this.defaultDateVal = {
            startDate: this.startDate,
            stopDate: this.stopDate,
          }

          this.filtersSelected.forEach((element) => {
            if(element.name === 'delivery_date' || element.name === 'delivery_shipment_date'){
              const idxRemove = this.filtersSelected.indexOf(element);
              this.filtersSelected.splice(idxRemove, 1);
              this.filtersSelected.splice(idxRemove, 1);
            }
          });

          this.filtersSelected.push({name: 'delivery_date', title: 'De: ', value: valueFilterDAte, start: this.startDate, stop: this.stopDate})
          this.getFilters();
        break;

      case 'filters':
          this.openFilter();
        break;

      case 'search':
        this.eventDefaultFilterSelected(event.data);
        break;
      case 'edit':
     ;
          this.openFormShipment(event.item)
          break;

      default:
          this.getFilters();
          return false;
        break;
    }


  }

  eventDefaultFilterSelected = (filterSelected) => {
    let nameIs = this.legentKeys(filterSelected.parameter);
    const valueInsert = {name: filterSelected.parameter, title: nameIs , value: filterSelected.searchWord};
    let listNames = [];
    const nameVal = valueInsert.name;
    this.filtersSelected.forEach(item => {

      if(item.name === valueInsert.name){
        const idxRemove = this.filtersSelected.indexOf(item);
        this.filtersSelected.splice(idxRemove, 1);
        if(!listNames.includes(nameVal) && valueInsert.value && valueInsert.value !== ""){

          this.filtersSelected.push(valueInsert);
          listNames.push(valueInsert.name)
        }

      }else{
        if(!listNames.includes(nameVal)){
          listNames.push(valueInsert.name)
          if(valueInsert.value && valueInsert.value !== ""){
            this.filtersSelected.push(valueInsert);
          }

        }
      }
    })

    this.getDeliveriesFilters();
  }

  openFilter = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.setFilters();

    const dialogRef = this.dialog.open(FiltersDeliveriesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getFilters();
      this.getDeliveriesFilters();

    });
  }

  setFilters = () => {
    let objectDataValuesForm = {
      distributor_driver_name: null,
      product: null,
      unit_cost: null,
      volume: null,
      divergence: null,
      startDate: null,
      stopDate: null,
    } ;

    this.filtersSelected.forEach(element => {
      if(element.value !== null){
        objectDataValuesForm[`${element.name}`] = element.value
      }
    });

    return objectDataValuesForm;
  }

  getFilters = () => {

    let getFiltersSession = sessionStorage.getItem('filtersDeliveries');
    let filtersSession = JSON.parse(getFiltersSession);
    const valueDate = `${this.startDate},${this.stopDate}`;
    const valueFilterDate = this.normalizeStr(valueDate);
    this.filtersSelected = [
      {
        name: 'fuel_tank_id',
        value: this.tankSelected
      },
      {
        name: 'delivery_date',
        value: valueFilterDate,
        start: this.startDate,
        stop: this.stopDate,
        title: 'De:'
      }
    ];

    for (const key in filtersSession) {
      if (filtersSession.hasOwnProperty(key)) {
        const element = filtersSession[key];
        if(element !== "" && element !== null){
          let nameIs = this.legentKeys(key);

          let valueDefine = element
          if(key === 'product'){
              this.productService.getProductId(element)
                .subscribe(
                  res => {
                    valueDefine = res.results[0].name;
                    this.filtersSelected.push({name: key, title: nameIs , value: valueDefine, valueDescriptive: valueDefine});
                  },
                  err => {
                    console.log("err get product", err);
                  }
                );
          }else if(key === 'unit_cost'){
            this.filtersSelected.push({name: key, title: nameIs , value: valueDefine, valueDescriptive: `a partir de R$ ${formatNumber(parseFloat(element), 'pt-Br')}`});
          }else{
            this.filtersSelected.push({name: key, title: nameIs , value: valueDefine, valueDescriptive: valueDefine});
          }

        }

      }
    }
    this.getDeliveriesFilters();
  }

  clearFilters = (filter) => {

    const idxRemove = this.filtersSelected.indexOf(filter);
    this.filtersSelected.splice(idxRemove, 1);
    const objectDataValuesForm = this.setFilters();
    sessionStorage.setItem('filtersDeliveries', JSON.stringify(objectDataValuesForm));
    this.getFilters();
  }


  legentKeys = (key) => {

    if(key === 'distributor_driver_name') {
     return 'Motorista'
    }
    if(key === 'driver_name') {
      return 'Motorista'
     }
    else if(key === 'product'){
      return 'Produto'
    }  else if(key === 'volume'){
      return 'Volume Carregado'
    }
    else if(key === 'unit_cost'){
      return 'Custo unitário'
    }
    else if(key === 'divergence'){
      return 'Divergência'
    }else if(key === 'startDate'){
      return 'Data Inicial'
    }else if(key === 'stopDate'){
      return 'Data Final'
    }else{
      return null;
    }
  }

  getDataPage = (value) => {
    this.maxPerPage = value.maxPerPage;
    this.currentPage = value.currentPage;
  }

  normalizeStr(str){
    // const str = 'ÁÉÍÓÚáéíóúâêîôûàèìòùÇç/.,~!@#$%&_-12345';
    const parsed = str.normalize('NFD').replace('%', '');
    return parsed;
  }

  formatFilters = () => {
    const filters = []
    this.filtersSelected.forEach(element => {
      let value = element.value;

      if(element.name === 'startDate' || element.name === 'stopDate'){
        if(element.name === 'startDate'){
          this.startDate = `${element.value}000`;
        }
        if(element.name === 'stopDate'){
          this.stopDate = `${element.value}000`;
        }
        if(this.startDate && this.stopDate){
          value = `${this.startDate},${this.stopDate}`;
          const valueFiltersDate = this.normalizeStr(value);
          filters.push({name: 'delivery_date', value: valueFiltersDate})
        }

      }else{
        filters.push({name: element.name, value: value})
      }
    })

    return filters;
  }
  emptyData: boolean;
  getDeliveriesFilters = () => {

    const filters = this.formatFilters();
    this.listDeliveries = [];
    if(this.filtersSelected.length){

      this.deliveriesService.getDeliveries(filters, this.pagination, this.sortItem)
      .subscribe(
        res => {
          if(res.results.length){
            localStorage.setItem("lastDelivery", res.results[0].shipment_delivery_id);
          } else {
            this.emptyData = true;
          }

          this.listDeliveries = [];
          this.listDeliveries  = res.results.map( element => ({
            id: element.id,
            delivery_date: moment(element.delivery_date).format('DD/MM/YYYY - HH:mm:ss'),
            driver: element.distributor_driver ? element.distributor_driver.name : null,
            volume: `${formatNumber(parseFloat(element.volume), 'pt-Br')} (L)`,
            occupation: `${formatNumber(parseFloat(element.occupation), 'pt-Br')} (L)`,
            product: element.product.name,
            unit_cost: element.unit_cost ? `R$ ${formatNumber(parseFloat(element.unit_cost), 'pt-Br')}` : 'Não informado',
            pump_closers: this.formatValuePumpsAcumulator(element.pumps_accumulator),
            period_comsume: !isNaN(parseFloat(element.consumption_tank)) ? formatNumber(parseFloat(element.consumption_tank), 'pt-Br', '1.1-1') + ' (L)' : null,
            calculate_comsume: !isNaN(parseFloat(element.consumption_pump)) ? formatNumber(parseFloat(element.consumption_pump), 'pt-Br', '1.1-1')  + ' (L)' : null,
            divergence: element.divergence || element.divergence === 0 ? `${formatNumber(parseFloat(element.divergence) ,'pt-Br', '1.1-1')}(L)`  : null,
            is_divergent_negative: element.is_divergent_negative,
            is_divergent_positive: element.is_divergent_positive
          }))
        },
        err => {
          console.log('err deliveries => ', err)
        }
      )
    }
  }


  formatValuePumpsAcumulator = (pumps) => {
    const pumpsObjtcts = new Array();
    pumps.forEach(element => {
      pumpsObjtcts.push({
        name: element.hasOwnProperty("pump") ? element.pump.short_name : '',
        value: formatNumber(element.accumulator, 'pt-Br', '3.1-5')
      })
    });

    return pumpsObjtcts;
  }


  openFormShipment = (data) => {
    let configs = {
      title: null,
      description: '',
      function: 'create',
      button: 'Salvar',
      buttonPlus: null,
      values: data
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = configs;
    const dialogRef = this.dialog.open(MdFormShipmentComponent, dialogConfig);
    dialogRef.afterClosed()
      .subscribe(() => {
        this.getDeliveriesFilters();
      });
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
    this.getFilters();
  }


}
