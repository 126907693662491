import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-select-attendent',
  templateUrl: './select-attendent.component.html',
  styleUrls: ['./select-attendent.component.scss']
})
export class SelectAttendentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
