import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { NgSelectConfig, NgSelectComponent } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ProductsService } from 'src/app/services/products/products.service';
import { ToastrService } from 'ngx-toastr';
import { FormModalTankComponent } from '../../forms-pages/modais-tank/form-modal/form-modal.component';


@Component({
  selector: 'cta-select-products',
  templateUrl: './select-products.component.html',
  styleUrls: ['./select-products.component.scss']
})
export class SelectProductsComponent implements OnInit, OnChanges {

  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private productService: ProductsService
  ) { }

  selectedProduct: any;
  @ViewChild(NgSelectComponent,  {static: false}) ngSelectComponent: NgSelectComponent;
  @Output() eventSelectedProduct = new EventEmitter();
  @Input() selectedInput: any;
  @Input() optionValueName:boolean;
  @Input() idClient: number;
  @Input() required = true;
  products: any[] = [];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';

    this.getProducts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedInput) {
      this.selectedProduct = this.selectedInput;
    }
    if(this.selectedInput === null){
      this.ngSelectComponent.handleClearClick();
    }

    if(this.idClient) {
     this.getProductsByClient();
    }
  }

  openMd(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Adicionar Frentsita',
      description: '',
      function: 'create'
    };

    const dialogRef = this.dialog.open(FormModalTankComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log('Quando fechar o modal faz...', false);
    });
  }

  getProductsByClient = () =>{

    this.productService.getProductsByClient(this.idClient).subscribe(
      res => {
       const data = res.results;
       data.map(item => ({
         id: item.id,
         name: item.name
       }))
       this.products = data;

      },
      err => {
        console.log('err getProducts ', err)
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de produtos')
      }
    )
  }

  getProducts = () => {
    this.productService.getProductsAll().subscribe(
      res => {
       const data = res.results
       data.map(item => ({
         id: item.id,
         name: item.name
       }))

       this.products = data

      },
      err => {
        console.log('err getProducts ', err)
        this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de produtos')
      }
    )
  }

  dispachProduct = (product) => {
    this.eventSelectedProduct.emit(product);
  }
}
