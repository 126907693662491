import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'cta-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})

export class DateInputComponent implements OnInit {


  @Output() dateHourEvent = new EventEmitter();
  @Input() title: string;
  @Input() DefaultDateVal: number
  dateInputed: Date
  dateValues: FormGroup;
  openCalendar = false;
  date: Date = new Date();
  dateInitialPicker: any;
  settings: FlatpickrOptions
  utcValueDate: any

  ngOnInit(): void {

    if(this.DefaultDateVal && this.DefaultDateVal !== undefined){
      this.dateInputed =  new Date(this.DefaultDateVal)
    }

    const settingsPiker = {
      defaultDate:  this.dateInputed  ?  this.dateInputed  : new Date(),
      locale: Portuguese,
      altInputClass: 'input-date-values',
      time_24hr: true,
      enableTime: true,
      dateFormat: 'd/m/Y H:i:S',
      defaultHour: Number,
      clickOpens: true,
      plugins:  [
         confirmDatePlugin({
          confirmIcon: `<i class='fa fa-check confirm-calendar'></i>`,
          confirmText: `CONFIRMAR`,
          showAlways: true,
          theme: 'dark'
        }
      ),
    ],
      enableSeconds: true,
      allowInput: true,
      key: 'calendar-filter',
      showMonths: 1,
      shorthandCurrentMonth: true,

      onClose: (dateEvent) => this.getDates(dateEvent),
      onOpen: () => this.eventOpenCalendar(),
    };

    this.settings = settingsPiker;
    this.dateHourEvent.emit();
  }

  constructor() {}

  getDates = (dateEvent) => {
    const initDate =  (document.getElementsByClassName('ng2-flatpickr-input')[0] as HTMLInputElement).value;
    const andDate  =  (document.getElementsByClassName('ng2-flatpickr-input')[1] as HTMLInputElement).value;
    const startUtcDate = this.convertUtc(initDate);
    const andDateUtc = this.convertUtc(andDate);
    console.log("INIT DATE => ", initDate);
    console.log("AND DATE => ", andDate)
    const objDate = {
      initUtcDate: startUtcDate,
      andUtcDate: andDateUtc,
      dateEvent: `${moment(new Date(dateEvent)).unix()}000`
    }
    console.log("this.getDates teste ");
    console.log(objDate);

    this.dateHourEvent.emit(objDate);
  }

  convertUtc = (date) => {

    console.log("DATE CALENDAR COMPONENT");
    console.log(new Date(date));

    const valuesDate = date.split('/');
    const yearDate = valuesDate[2].split(" ")
    const hourDate = yearDate[1].split(":")


    const year = parseInt(yearDate[0])
    const month = parseInt(valuesDate[1]) - 1
    const day = parseInt(valuesDate[0])
    const hour = parseInt(hourDate[0]) + 3
    const minutes = parseInt(hourDate[1])
    const secounds = parseInt(hourDate[2])

    const utcValueDate = Date.UTC(year, month, day, hour, minutes, secounds);
    console.log("test utcValue new Date");
    console.log(new Date(utcValueDate));
    // const new value = moment().unix()
    return utcValueDate;
  }

  eventOpenCalendar = () => {
    this.openCalendar = true;
  }
}
