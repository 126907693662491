import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(list: any[], page: number, max: number): any[] {

    if (list.length && page && max) {
      const newList = [];
      list.forEach((element, index) => {
        if (page === 1) {
          if (newList.length <= (max - 1)) {
            newList.push(element);
          }
        } else if (index >= (max * page ) - max ) {
          if (newList.length <= (max - 1)) {
            newList.push(element);
          }
        } else {
          return list;
        }
      });

      return newList;
    }


  }

}
