import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cta-tank-icon-map',
  templateUrl: './tank-icon-map.component.html',
  styleUrls: ['./tank-icon-map.component.scss']
})
export class TankIconMapComponent implements OnInit {

  constructor() { }
  @Input() level: number;
  @Input() currentId: number;
  @Input() currentSelected: number;
  ngOnInit() {
  }

}
