import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {URLS_DEFAULT} from '../../configs/urls_default';
import { Log } from 'src/app/models/log';

@Injectable({
  providedIn: 'root'
})
export class LogSystemService  {
   
  tokenUser = sessionStorage.getItem('tokenUser');
  tokenRest = sessionStorage.getItem('tokenRest');
  currentUser: any = sessionStorage.getItem('currentUser');
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.tokenRest} `,
      'user_token': this.tokenUser
    })
  };

  constructor(protected http: HttpClient) { }

  log(data: Log): Observable<any> {
    return this.http.post(`${URLS_DEFAULT.BASE_URL}${URLS_DEFAULT.LOG}`, data, this.headerOptions).pipe(
      catchError(this.handleError),
      map(this.handleError)
    );
  }

  verifyType(value: any,aguardType: string, log: Log){
    if(typeof value === aguardType){
      return
    }else{
      this.log(log).subscribe(
        res => {
          return 
        },
        err => {
          console.log('erro ao tentar salvar log', err);
        }
      )
    }

  }

  private handleError(error: any[]): Observable<any> {
    console.log('erro na requisição => ', error);
    return throwError(error);
  }
}
