import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './views/list/list.component';
import { CrudGenericServicce } from 'src/app/services/CrudGeneric.service';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { TablesModule } from '../tables/tables.module';
import { GenerateFilesModule } from '../generate-files/generate-files.module';
import { ComponentsModule } from '../components.module';
import { ReportComponent } from './views/report/report.component';


@NgModule({
  declarations: [ListComponent, ReportComponent],
  imports: [
    CommonModule,
    SharedModule,
    TablesModule,
    GenerateFilesModule

  ],
  exports: [ListComponent, ReportComponent, TablesModule],
  providers: [CrudGenericServicce]
})
export class ViewCrudModule { }
