import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';

@Injectable({
  providedIn: 'root'
})
export class ShipmentPumpService extends CrudService {

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  createShipmentPumps(shipment): Observable<any> {
    this.apiPath = URLS_DEFAULT.IMAGE_SHIPMENT_PUMP;
    return this.create(shipment)
  }

  getShipmentByDelivery(shipmentId): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.IMAGE_SHIPMENT_PUMP}?shipment_delivery=${shipmentId}`;
    return this.findItem();
  }

  deleteShipmentPump = (id) => {
    this.apiPath = URLS_DEFAULT.IMAGE_SHIPMENT_PUMP;
    return this.delete(id)
  }
}
