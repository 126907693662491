import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isArray } from 'util';

@Component({
  selector: 'cta-td-case',
  templateUrl: './td-case.component.html',
  styleUrls: ['./td-case.component.scss']
})
export class TdCaseComponent implements OnInit {

  constructor() { }
  @Input() props: any[]

  ngOnInit() {
  }


  openDataTr: boolean;
  expandTr = () => {
    this.openDataTr = !this.openDataTr;
  }
  verifyArray(element) {
    return isArray(element)
  }
}
