import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'cta-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor() { }
  notificationsList = [
    {id: 1, title: 'Bomba offline'},
    {id: 2, title: 'Limite de abastecimento excedido'},
    {id: 3, title: 'Tanque vazio'}
  ]
  ngOnInit() {
  }

}
