import { Component, OnInit, Inject, Input, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogContentComponent } from '../dialog-content/dialog-content.component';
import { EventEmitter } from 'events';

@Component({
  selector: 'cta-md-selecteds-table',
  templateUrl: './md-selecteds-table.component.html',
  styleUrls: ['./md-selecteds-table.component.scss']
})
export class MdSelectedsTableComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DialogContentComponent>, @Inject(MAT_DIALOG_DATA) data) {

    this.data = data;
    this.list = data.list
  }
  data: any;
  list: any[] = []
  props: any[] = [];
  @Output() actionButton = new EventEmitter();

  ngOnInit() {
    this.convertList()
  }

  setSelected = (event) => {
    console.log(event);
  }

  convertList() {
    if (this.data.list.length) {
      for (const item of this.data.list) {
        const propsIn = Object.keys(item);
        const myProps = [];
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            myProps.push(element);
          }
        });
        this.props = myProps;
      }
    }
  }


  removeItem = (item) => {
    const position = this.list.indexOf(item);
    this.list.splice(position, 1);
  }

  actionList = (action) => {
    this.actionButton.emit(action)
  }


}
