import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';

@Injectable({
  providedIn: 'root'
})

export class ProductsService extends CrudService{

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  getProductsByClient(idClient): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.PRODUCTS}?client=${idClient}`;
    return this.findItem();
  }

  getProductsAll(): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.PRODUCTS}?client=null`;
    return this.findItem();
  }

  getProductId(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.PRODUCTS;
    return this.getById(id);
  }

  createProduct = (product) => {
    this.apiPath = URLS_DEFAULT.PRODUCTS;
    return this.create(product);
  }

  getCategoryProducts = () => {

  }


}
