import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cta-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {

  constructor() { }
  dispachForm: boolean;
  showButton: boolean;
  ngOnInit() {
  }

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

}
