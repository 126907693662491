import { Component, OnInit, Input } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];


@Component({
  selector: 'cta-excel-file',
  templateUrl: './excel-file.component.html',
  styleUrls: ['./excel-file.component.scss']
})

export class ExcelFileComponent implements OnInit {
  headList: any;
  data: AOA = []
  constructor() { }
  @Input() list: any[]
  @Input() fileName: string = 'documento';
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  ngOnInit() {

  }

  convertListMatrisArray = () => {

    if (this.list.length) {
      this.headList = this.list.filter(item => item.head)[0]
      const dataTable = this.list.filter(item => !item.head)

      let myProps = [];

      for (const item of dataTable) {
        let propsIn = Object.keys(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            myProps.push(element);
          }
        });
      }

      const bodyFile: any[] = [];
      bodyFile.push(this.headList["head"])
      for (const item of this.list) {
        let line = [];
        for (const prop of myProps) {

          line.push(item[prop]);

          if (line.length === myProps.length) {
            if (!bodyFile.includes(line)) {
              line.shift();
              bodyFile.push(line);
            }
            line = [];
          }
        }
      }

      this.data = bodyFile;
    }
  }


  generateXlsx = () => {

    this.convertListMatrisArray();
    if (this.data.length) {
      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, `${this.fileName}.xlsx`);
    }
  }

}
