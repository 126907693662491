import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { JwtInterceptor, ErrorInterceptor } from './services/helpers';
import { ChartsModule } from 'ng2-charts';
import { CrudService } from './services/crud.service';
import { GasStationService } from './services/gas-station/gas-station.service';
import { ProductsService } from './services/products/products.service';
import { DriversModule } from './pages/drivers/drivers.module';
import { SharedModule } from './components/shared/shared.module';
import { CpfCnpjValidator } from './services/helpers/directives/validator-cnpj.directive';
import { ToastrModule } from 'ngx-toastr';
import { ReportsModule } from './pages/reports/reports.module';
import { AuthenticationService } from './services/authentication.service';
import { ComponentsModule } from './components/components.module';
import { PhonePipe } from './services/helpers/phone.pipe';


declare global {
  interface Window { google: any; }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CpfCnpjValidator,
    PhonePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ToastrModule,
    HttpClientModule,
    HttpClientJsonpModule,    
    ChartsModule,
    DriversModule,
    ReportsModule,
    SharedModule,
    ComponentsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CrudService,
    AuthenticationService,
    GasStationService,
    ProductsService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
