import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { MatDialogModule, MatDialogRef } from '@angular/material';
import { MdSelectedsTableComponent } from './md-selecteds-table/md-selecteds-table.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';


@NgModule({
  declarations: [
    ConfirmComponent,
    DialogContentComponent,
    MdSelectedsTableComponent,
    ConfirmActionComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  providers: [
   {provide: MatDialogRef, useValue: {}},
 ],
 entryComponents: [DialogContentComponent, ConfirmComponent, MdSelectedsTableComponent, ConfirmActionComponent]
})
export class ModaisModule { }
