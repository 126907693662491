import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'cta-form-lubrificant',
  templateUrl: './form-lubrificant.component.html',
  styleUrls: ['./form-lubrificant.component.scss']
})
export class FormLubrificantComponent implements OnChanges, OnInit {

  cadastrar: FormGroup;
  data: any;
  @Input() title: any[];
  @Input() dispachForm: boolean;
  @Output() validForm = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.cadastrar = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
    });
 }

 ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      this.submitValues();
    }
  }

  ngOnInit() {
    this.cadastrar.setValue({
      posto: 1,
      produto: 1,
      capacidade: 25000
    });
   }

  submitValues = () => {
    console.log('event submit ', this.cadastrar.value);
  }

  submitEmmiterButton = event => {
    this.validForm.emit(event);
  }
}
