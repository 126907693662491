import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';
import { Pagination } from '../../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class TanksService extends CrudService{

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  getTanks(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.FUEL_TANKS;
    return this.findItem();
  }


  getFilters(filters, pagination: Pagination): Observable<RestModel> {
    if (
        filters && filters.hasOwnProperty('parameter')
        && filters.hasOwnProperty('search')
        && filters.search !== ""
        && filters.search !== null
    ) {
      this.apiPath = `${URLS_DEFAULT.FUEL_TANKS}?${filters.parameter}=${filters.search}&sort=-name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`;
    } else {
      this.apiPath = `${URLS_DEFAULT.FUEL_TANKS}?sort=+name&page=${pagination.currentPage}&page_size=${pagination.maxPerpage}`;
    }

    const urlClean = this.apiPath.normalize('NFD').replace('%', '');
    this.apiPath = urlClean;
    return this.findItem()
  }

  getTanksTable(start_date: number, end_date : number, product_category : number): Observable<RestModel> {
    this.apiPath = `${URLS_DEFAULT.FUEL_TANKS_TABLE}?start_date=${start_date}&end_date=${end_date}&product_category=${product_category}`
    return this.findItem();
  }

  getTankById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.FUEL_TANKS;
    return this.getById(id);
  }

  createTank(tank: any): Observable<any> {
    this.apiPath = URLS_DEFAULT.FUEL_TANKS
    return this.create(tank);
  }

  updateTank(tank: any): Observable<any> {
    this.apiPath = URLS_DEFAULT.FUEL_TANKS
    return this.update(tank);
  }

  getHeaders(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.HEADER_TANKS
    return this.findItem();
  }

  getTypesContract(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.CONTRACT_TYPES
    return this.findItem();
  }

  deleteTankById(id: number){
    this.apiPath = URLS_DEFAULT.FUEL_TANKS;
    return this.delete(id);
  }

}
