import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from 'src/app/models/configurations/rest.model';

@Injectable({
  providedIn: 'root'
})

export class UsersService extends CrudService{

  results: any;

  constructor(http: HttpClient) {
    super(http);
  }

  getDrivers(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.USERS;
    return this.findItem();
  }

  getFilters(filters): Observable<RestModel> {
    if (
        filters && filters.hasOwnProperty('parameter')
        && filters.hasOwnProperty('search')
        && filters.search !== ""
        && filters.search !== null
    ) {
      this.apiPath = `${URLS_DEFAULT.USERS}?${filters.parameter}=${filters.search}&sort=-name`;
    } else {
      this.apiPath = `${URLS_DEFAULT.USERS}?sort=+name`;
    }

    return this.findItem()
  }

  getUsersById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.USERS;
    return this.getById(id);
  }

  getUserCategoryId(id: number){
    this.apiPath = `${URLS_DEFAULT.USERS}?categoria_id=${id}&category=MOTORISTA`;
    return this.findItem();
  }

  createUser(user): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.USERS;
    return this.create(user);
  }

  updateUser(user): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.USERS;
    return this.update(user);
  }


  deleteUser(id: number){
    this.apiPath = URLS_DEFAULT.USERS;
    return this.delete(id);
  }

}
