import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestModel } from '../../models/configurations/rest.model';
import { URLS_DEFAULT } from '../../configs/urls_default';

@Injectable({
  providedIn: 'root'
})
export class ProbeService extends CrudService{

  results: any;
  constructor(http: HttpClient) {
    super(http);
  }

  getPlumbs(): Observable<RestModel> {
    this.apiPath = URLS_DEFAULT.PROBE_TYPES;
    return this.findItem();
  }

  getPlumbById(id: number): Observable<any> {
    this.apiPath = URLS_DEFAULT.PROBE_TYPES;
    return this.getById(id);
  }

  createPlumb(tank: any): Observable<any> {
    this.apiPath = URLS_DEFAULT.PROBE_TYPES
    return this.create(tank);
  }
}
