import { Component, OnInit } from '@angular/core';

import less from 'less';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'ctasmart-system';
  logged: boolean;

  constructor(

    private router: Router
  ) { }

  ngOnInit() {
    less.modifyVars({ 'primaryColor': 'rgba(0,0,0,0.5)' });
  }

}
