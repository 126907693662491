export const LIST_MENU = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        link: 'dashboard',
        subtitle: 'Resumo do sistema'
    },
    {
        id: 2,
        label: 'Empresas',
        icon: 'mdi-domain',
        title: 'Empresas',
        link: 'empresas',
        subtitle: 'Veja suas empresas aqui',
        submenu: [
            {id: 1, label: 'Listar', link: 'listar', title: 'Listar empresas', subtitle: 'Tabela com lista de suas empresas'},
            {id: 2, label: 'Criar', link: 'criar', title: 'Cadastrar empresas', subtitle: 'Cadastre suas empresas'},
            {id: 2, label: 'Saldos', link: 'saldos', title: 'Saldos de empresas', subtitle: 'Listagem de saldo por empresa'},
            {id: 3, label: 'Dashboard', link: 'dashboard', title: 'Dashboard de empresa', subtitle: 'Veja os dados de sua empresa'},
        ]
    },
    {
        id: 3,
        label: 'Motoristas',
        icon: 'mdi-account-card-details',
        title: 'Motoristas',
        subtitle: 'Motoristas Cadastrados',
        link: 'motoristas',
        submenu: [
          {id: 1, label: 'Listar', link: 'listar', title: 'Listar motoristas', subtitle: 'Tabela com lista de suas motoristas'},
          {id: 2, label: 'Criar', link: 'criar', title: 'Cadastrar motoristas', subtitle: 'Cadastre suas motoristas'},
          {id: 3, label: 'Dashboard', link: 'dashboard' , title: 'Dashboard de motoristas', subtitle: 'Veja os dados de sua motoristas'}
        ]
    },
    {
        id: 4,
        label: 'Frentistas',
        icon: 'mdi-worker',
        title: 'Frentistas',
        subtitle: 'Frentistas Cadastrados',
        link: 'frentistas',
        submenu: [
          {id: 1, label: 'Listar', link: 'listar', title: 'Listar frentista', subtitle: 'Tabela com lista de suas frentistas'},
          {id: 2, label: 'Criar',  link: 'criar', title: 'Cadastrar frentista', subtitle: 'Cadastre suas frentistas'},
          {id: 3, label: 'Dashboard',  link: 'dashboard', title: 'Dashboard de frentista', subtitle: 'Veja os dados de sua frentistas'},
        ]
    },
    {
        id: 5,
        label: 'Tanques',
        icon: 'mdi-gas-station',
        title: 'Tanques',
        subtitle: 'Tanques cadastrados',
        link: 'tanques',
        submenu: [
          {id: 1, label: 'Listar', link: 'listar', title: 'Listar tanques', subtitle: 'Tabela com lista de suas tanques'},
          {id: 2, label: 'Criar', link: 'criar',  title: 'Cadastrar tanques', subtitle: 'Cadastre suas tanques'},
          {
            id: 3,
            label: 'Lubrificantes',
            link: 'lubrificantes',
            title: 'Lista de lubrificantes',
            subtitle: 'Veja os dados de seus lubrificantes'
          },
          {id: 4, label: 'Dashboard', link: 'dashboard',  title: 'Dashboard de tanques', subtitle: 'Veja os dados de sua tanques'},
        ]
    },
    {
        id: 6,
        label: 'Bombas',
        icon: 'mdi-water-pump',
        title: 'Bombas',
        subtitle: 'Bombas cadastradas',
        link: 'bombas',
        submenu: [
            {id: 1, label: 'Listar', link: 'listar'},
            {id: 2, label: 'Criar', link: 'criar'},
            {id: 3, label: 'Dashboard', link: 'dashboard'},
        ]
    },
    {
        id: 7,
        label: 'Veículos',
        icon: 'mdi-truck',
        title: 'veiculos',
        subtitle: 'Edite os veículos cadastrados e faça ações',
        link: 'veiculos',
        submenu: [
            {
              id: 1,
              label: 'Listar',
              link: 'listar',
              title: 'Veículos',
              subtitle: 'Edite os veículos cadastrados e faça ações'
            },
            {
              id: 2,
              label: 'Criar',
              link: 'criar',
              title: 'Cadastrar veículos',
              subtitle: 'Cadastre aqui seus veículos'
            },
            {
              id: 3,
              label: 'lançamentos',
              link: 'lancamentos',
              title: 'Cadastrar lançamentos',
              subtitle: 'Cadastre aqui seus lançamentos'
            },
            {
              id: 4,
              label: 'Modelos',
              link: 'modelos',
              title: 'Modelos de veículos',
              subtitle: 'Veja, edite e crie modelos de veículos'
            },
            {
              id: 4,
              label: 'Categorias',
              link: 'categorias',
              title: 'Categorias de veículos',
              subtitle: 'Veja, edite e crie categorias de veículos'
            },
            {
              id: 4,
              label: 'Componentes',
              link: 'componentes',
              title: 'Componentes de veículos',
              subtitle: 'Veja, edite e crie componentes de veículos'
            },
            {
              id: 4,
              label: 'Mapa',
              link: 'mapa',
              title: 'Mapa de abastecimentos dos veículos',
              subtitle: 'informações sobre o abastecimento dos veículos.'
            },
            {
              id: 5,
              label: 'Dashboard',
              link: 'dashboard',
              title: 'Dashboard de Veículos',
              subtitle: 'Veja aqui gráficos e tabelas com informações dos veículos'
            },
        ]
    },
    {
        id: 8,
        label: 'Carregamentos',
        icon: 'mdi-battery-charging-50',
        title: 'Carregamentos',
        subtitle: 'Carregamentos Cadastrados',
        link: 'carregamentos',
        submenu: [
            {
              id: 1,
              label: 'Listar',
              icon: 'mdi-battery-charging-50',
              link: 'listar',
              title: 'Carregamentos',
              subtitle: 'Edite os carregamentos cadastrados e faça ações'
            },
            {
              id: 2,
              label: 'Criar',
              icon: 'mdi-battery-charging-50',
              link: 'criar',
              title: 'Novo Carregamento',
              subtitle: 'Insira novos carregamentos através do formulário abaixo'
            },
            {id: 3, label: 'Dashboard', link: 'dashboard'},
        ]
    },
    {
        id: 9,
        label: 'Relatórios',
        icon: 'mdi-finance',
        title: 'Relatórios',
        link: 'relatorios',
        subtitle: 'Visualize e exporte relatórios no formato desejado',
        submenu: [
          {
            id: 1,
            label: 'Listar',
            icon: 'mdi-battery-charging-50',
            link: 'listar',
            title: 'Relatórios',
            subtitle: 'Visualize, edite e exporte relatórios no formato desejado'
          },
            {id: 1, label: 'Criar', link: 'criar'},
            {id: 1, label: 'Dashboard', link: 'dashboard'},
        ]
    },
    {
        id: 10,
        label: 'Usuários',
        title: 'Usu´arios',
        icon: 'mdi-account-tie',
        link: 'usuarios',
        submenu: [
          {
            id: 1,
            label: 'Listar',
            icon: 'mdi-account-tie',
            link: 'listar',
            title: 'Usuários',
            subtitle: 'Veja os usuários cadastrados e faça ações'
          },
          {
            id: 2,
            label: 'Criar',
            icon: 'mdi-account-tie',
            link: 'criar',
            title: 'Novo Usuário',
            subtitle: 'Insira novos usuários através do formulário abaixo'
          }
        ]
    }
];
