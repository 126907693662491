import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './views/main/main.component';
import { ReportsRoutingModule } from './report-routing.modules';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { TablesModule } from 'src/app/components/tables/tables.module';
import { GenerateFilesModule } from 'src/app/components/generate-files/generate-files.module';
import { ViewCrudModule } from 'src/app/components/view-crud/view-crud.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { MatDialogModule } from '@angular/material';
import { ValidatorsEspeciais } from 'src/app/services/helpers/validators-especiais';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    TablesModule,
    GenerateFilesModule,
    ViewCrudModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    MatDialogModule,
    NgxMaskModule.forRoot(maskConfigFunction),
  ],
  providers: [
    ValidatorsEspeciais
  ]
})
export class ReportsModule { }
