import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import {LIST_MENU} from '../../../data/menu';

@Component({
  selector: 'cta-navigation-top',
  templateUrl: './navigation-top.component.html',
  styleUrls: ['./navigation-top.component.scss']
})
export class NavigationTopComponent implements OnInit {

  constructor(private router: Router) { }
  @Input() fullTop: boolean;
  showCustom: boolean;
  verifyWidth: boolean;
  isMobile: boolean;
  routerActive: string;
  subtitleBread: string;
  icon: string;

  @Output() showCustomizer = new EventEmitter();
  ngOnInit() {
    this.isMobile = window.innerWidth >= 780 ? false : true;
    this.defineTitleSubtitle();
    this.viewFullEvent();
  }

  menuToogle = () => {
    this.fullTop = !this.fullTop ? true : false;
  }

  customizerToogle = () => {
    this.showCustom = !this.showCustom ? true : false;
    this.showCustomizer.emit(this.showCustom);
  }

  defineTitleSubtitle() {
    const urlActive = this.router.url.replace('/', '');

    LIST_MENU.forEach(item => {
      if (item.label.toLocaleLowerCase() === urlActive.toLocaleLowerCase()) {
        if (item.title && item.subtitle) {
          this.subtitleBread = item.subtitle;
          this.routerActive = item.title;
          this.icon = item.icon;
        }

      } else if (item.submenu && item.submenu.length) {
        const submenus = item.submenu;
        if (submenus.length) {
          submenus.forEach(sub => {
            const urlSubmenu = `${item.link.toLocaleLowerCase()}/${sub.link.toLocaleLowerCase()}`;
            if (urlActive.toLocaleLowerCase() === urlSubmenu) {
              if (sub.hasOwnProperty('title')) {
                this.routerActive =  sub.title;
              }
              if (sub.hasOwnProperty('subtitle')) {
                this.subtitleBread = sub.subtitle;
              }
            }
          });
        }

      } else {
        this.routerActive = null;
        this.subtitleBread = null;
      }
    });
  }


  viewFullEvent() {
    this.fullTop = localStorage.getItem('viewFull') && localStorage.getItem('viewFull') === 'true' ? true : false;
  }
}
