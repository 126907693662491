import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from 'src/app/services/products/products.service';
import { ConvertersService } from 'src/app/services/helpers/converters.helpers';
import * as moment from 'moment';
@Component({
  selector: 'cta-form-product',
  templateUrl: './form-product.component.html',
  styleUrls: ['./form-product.component.scss']
})
export class FormProductComponent implements OnInit {

  cadastrar: FormGroup;
  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Output() validForm = new EventEmitter()
  constructor(
    private fb: FormBuilder,
    private productService: ProductsService,
    private toastService: ToastrService,
    private convertService: ConvertersService
  ) {

    const format_date = moment().format('d/m/Y H:M:S')
    const last_update = this.convertService.convertUtc(format_date);

    this.cadastrar =  this.fb.group({
      category: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      short_name: this.fb.control('', [Validators.required]),
      code: this.fb.control('', [Validators.required]),
      export_code: this.fb.control('', [Validators.required]),
      last_update: last_update,
      client: this.fb.control('', [Validators.required]),
    })
  }

  ngOnInit() {

  }


  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }

  submitValues = () => {

    this.productService.createProduct(this.cadastrar.value).subscribe(
      res => {

        this.toastService.success("Posto cadastrado com sucesso")
      },
      err => {
        console.log('err');
        this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o posto")
      }
    )
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar.valid) {
        console.log('entrou aqui ... produto')
        this.submitValues();
      }
    }
  }

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

  selectedEvents = (event, value) => {
    if (event == 'client') {
      this.cadastrar.setValue({
        short_name: this.cadastrar.value.short_name,
        code: this.cadastrar.value.code,
        export_code: this.cadastrar.value.export_code,
        last_update: this.cadastrar.value.last_update,
        client: value,
      })

    }
  }
}
