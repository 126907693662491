

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConvertersService {

  convertUtc = (date) => {
    const valuesDate = date.split('/');
    const yearDate = valuesDate[2].split(" ")
    const hourDate = yearDate[1].split(":")

    const year = parseInt(yearDate[0])
    const month = parseInt(valuesDate[1])
    const day = parseInt(valuesDate[0])
    const hour = parseInt(hourDate[0])
    const minutes = parseInt(hourDate[1])
    const secounds = parseInt(hourDate[2])

    const utcValueDate = Date.UTC(year, month, day, hour, minutes, secounds);
    return utcValueDate;
  }
}

