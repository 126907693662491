import { Component } from '@angular/core';

@Component({
  selector: 'cta-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {

  disabledButton: boolean;
  constructor() { }
  dispachForm: boolean;

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

  eventFire = event => {
    if (event) {
      this.disabledButton = true;
    }
  }
}
