import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'cta-clusters-icon',
  templateUrl: './clusters-icon.component.html',
  styleUrls: ['./clusters-icon.component.scss']
})
export class ClustersIconComponent implements OnInit {

  constructor() { }
  @Input() numberItens: number;
  @Input() marker: any;
  @Input() tankSelected: any;
  @Output() dataTankEvent = new EventEmitter();
  levels: number[] = [];
  show: boolean;

  ngOnInit() {
    this.getLevels()
  }

  getLevels = () => {
    let levels = []
    this.marker.join.forEach(element => {
      if(!levels.includes(element.level)){
        levels.push(element.level)
      }
    });

    if(levels.length){
      this.levels = levels
    }

  }

  dispachTank = (level) => {

    console.log("marker in element this multiples tanks ", this.marker);

    this.dataTankEvent.emit(level);
  }

  showIcon = () => {
    this.show = !this.show;
  }

}
