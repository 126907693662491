import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], props: any[], value: string): any[] {
    if (!items) {
      return [];
    }

    if (!props || !value) {
      return items;
    }

    return this.filterProps(props, items, value);
  }

  filterProps = (props, itens, value) => {
    const dataSearched = [];
    for (const item of itens) {
      for (const prop of props) {
        if (
          item[prop] !== null
          && item[prop].toString().toLowerCase().includes(value.toLowerCase())
          && dataSearched.length < itens.length) {
          if (!dataSearched.includes(item)) {
            dataSearched.push(item);
          }
        }
      }
    }

    return dataSearched;
  }

}
