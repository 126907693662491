import { Pipe, PipeTransform } from '@angular/core';
import numeral from 'numeral'
import locale from 'numeral/locales'

@Pipe({
  name: 'formatnumber'
})
export class FormatnumberPipe implements PipeTransform {

  transform(value: any): any {
    if(value){

      if(!isNaN(value) ){
        
        return  numeral(value).format('#,0')
      }else{
        return value
      }
    }
  }

}
