import { Component, OnInit, Input } from '@angular/core';
import { HeadTable } from 'src/app/models/configurations/head.model';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'cta-report-pdf',
  templateUrl: './report-pdf.component.html',
  styleUrls: ['./report-pdf.component.scss']
})
export class ReportPdfComponent implements OnInit {

  constructor() { }
  @Input() headList: HeadTable[] = [];
  @Input() list: any[] = [];
  props: any[] = [];
  ngOnInit() {
    this.convertList();
    if (this.list.length && this.headList.length) {
      this.openPdf();      
    }

  }


  openPdf = () => {
    var element = document.getElementById("content")
    html2canvas(element).then((canvas) => {
      console.log(canvas);
      var imgData = canvas.toDataURL('image/png');
      var doc = new jsPDF();
      var imgHeight = canvas.height * 208 / canvas.width; 
      doc.addImage(imgData, 0, 0, 210, imgHeight)
      doc.save("image.pdf")
  });
  }

  convertList = () => {

    const values = []
    if (this.list.length) {
      for (const item of this.list) {
        const propsIn = Object.keys(item);
        const myProps = [];
        values.push(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            myProps.push(element);
          }
        });
        this.props = myProps;
      }

    }
  }
}
