import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cta-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  constructor() { }
  @Input() action: object;
  @Input() btnIcon: string;
  @Input() btnClass: string;
  @Input() description: string
  @Output() dispachEvent = new EventEmitter();
  ngOnInit() {
  }

  openMdSelected = (event) => {
    this.dispachEvent.emit(event);
  }

}
