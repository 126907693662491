import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgSelectConfig, NgSelectComponent } from '@ng-select/ng-select';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { FormModalCompaniesComponent } from '../../forms-pages/md-form-companies/form-modal-companies.component';

@Component({
  selector: 'cta-select-companies',
  templateUrl: './select-companies.component.html',
  styleUrls: ['./select-companies.component.scss']
})
export class SelectCompaniesComponent implements OnInit, OnChanges {

  constructor(
    private configSelect: NgSelectConfig,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private companieService: CompaniesService
  ) { }


  @ViewChild(NgSelectComponent,  {static: false}) ngSelectComponent: NgSelectComponent;
  @Output() eventSelectedCompany = new EventEmitter();
  @Input() selected: number;
  @Input() clientId: number;
  @Input() required = true;
  selectedCompanie: any;
  lastSaveCompanieId: any;
  companies: any[] = [];

  ngOnInit() {
    this.configSelect.notFoundText = 'Nenhum dado encontrado';
  }

  ngOnChanges(changes: SimpleChanges){
     this.selectedCompanie = null;
     if(this.clientId === null){
      this.ngSelectComponent.handleClearClick();
     }

     this.companies = [];
     this.getCompanies()
  }

  openMd(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Adicionar Empresa',
      description: '',
      function: 'create',
      clientId: this.clientId
    };

    const dialogRef = this.dialog.open(FormModalCompaniesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {

      this.companies = [];
      setTimeout(() => {
        this.lastSaveCompanieId = parseInt(localStorage.getItem('lastSaveCompanieId'));
        this.getCompanies();
      }, 2000);
    });
  }

  getCompanies = () => {
    this.companies = [];
    if(this.clientId){
      this.companieService.getCompaniesClientId(this.clientId).subscribe(
        res => {

         const data = res.results
         const companies  = data.map(item => ({
           id: item.id,
           name: item.name
         }));

         this.companies = companies;

          companies.forEach(item => {
            if(item.id === this.lastSaveCompanieId){
              this.selectedCompanie = item.id
              this.dispachCompanie(item.id)
            }
          });
        },
        err => {
          console.log('err getCompanies ', err)
          this.toastr.warning('Houve alguma falha ao tentar carregar os dados para o select de clientes')
        }
      )
    }

  }

  dispachCompanie = (companyId) => {
    this.eventSelectedCompany.emit(companyId);
  }
}
