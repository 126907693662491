import { Component, EventEmitter, Input, Output, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeliveriesService } from 'src/app/services/deliveries/deliveries.service';

import { ToastrService } from 'ngx-toastr';
import { ShipmentService } from 'src/app/services/shipments/shipment.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ConfirmActionComponent } from '../../modais/confirm-action/confirm-action.component';
import * as moment from 'moment';
import { ShipmentPumpService } from 'src/app/services/shipments/shipment-pump.service';
import { ShipmentTankService } from 'src/app/services/shipments/shipment-tank.service';
import { ShipmentGasStationService } from 'src/app/services/shipments/shipment-gasstation.service';
import { PumpsService } from 'src/app/services/pumps/pumps.service';

@Component({
  selector: 'cta-form-delivery',
  templateUrl: './form-delivery.component.html',
  styleUrls: ['./form-delivery.component.scss']
})
export class FormDeliveryComponent implements OnChanges, OnInit {


  cadastrar: FormGroup;
  data: any;
  @Input() title: any[];
  @Input() dispachForm: boolean;
  @Output() validForm = new EventEmitter();
  @Input()  valuesDelivery: any;
  @Input() showBtn: boolean;
  @Input() shipmentId: number;
  @Output() shipmentEvent = new EventEmitter();
  @Output() registerShipment = new EventEmitter();
  valueClient: number;
  valueGasStation: number;
  valueFuelTank: number;
  valueProduct: number;
  valueCompany: number;
  popoverTitle = 'Você tem certeza que deseja executar esta ação';
  popoverMessage = 'Ao confirmar seus dados de entrega serão deletados.';
  confirmClicked = false;
  cancelClicked = false;
  selected = null;
  optionsShipment = ['Sim', 'Não'];
  showAlert: boolean;
  constructor(
    private fb: FormBuilder,
    private deliveryService: DeliveriesService,
    private shipmentService: ShipmentService,
    private shipmentPumpService: ShipmentPumpService,
    private shipmentTankService: ShipmentTankService,
    private shipmentGasStationService: ShipmentGasStationService,
    private toastService: ToastrService,
    public dialog: MatDialog,
  ) {

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      gas_station: this.fb.control('', [Validators.required]),
      fuel_tank: this.fb.control('', [Validators.required]),
      product: this.fb.control(null, [Validators.required]),
      cost: this.fb.control(null),
      delivery_date: this.fb.control('', [Validators.required]),
      volume: this.fb.control('', [Validators.required]),
      company: this.fb.control(null, [Validators.required]),
      status: this.fb.control('previsto')
    });
 }
  ngOnInit() {

    if (this.valuesDelivery) {
      this.valueClient = this.valuesDelivery.fuel_tank.gas_station.client.id;
      this.valueCompany = this.valuesDelivery.company.id;
      this.valueGasStation = this.valuesDelivery.fuel_tank.gas_station.id;
      this.valueFuelTank = this.valuesDelivery.fuel_tank.id;
      this.valueProduct = this.valuesDelivery.product.id;

      this.cadastrar.setValue({
        id: this.valuesDelivery.id,
        gas_station: this.valuesDelivery.fuel_tank.gas_station.id,
        fuel_tank: this.valuesDelivery.fuel_tank.id,
        product: this.valuesDelivery.product.id,
        delivery_date: this.valuesDelivery.delivery_date,
        volume: this.valuesDelivery.volume,
        cost: this.valuesDelivery.cost,
        company: this.valuesDelivery.company.id,
        status: this.valuesDelivery.status
      });
    }
  }


  ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
    if (changes.dispachForm) {
      this.submitValues();
    }
  }

  submitValues = () => {

    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      gas_station: this.valueGasStation,
      fuel_tank: this.valueFuelTank,
      product: this.valueProduct,
      company: this.valueCompany,
      delivery_date:  this.cadastrar.value.delivery_date,
      cost: this.cadastrar.value.cost,
      volume: this.cadastrar.value.volume,
      status: this.cadastrar.value.status
    });

    if (this.cadastrar.valid && this.valuesDelivery === undefined) {
      this.createDelivery();
    } else {
      if (this.shipmentId && (this.valueFuelTank !== this.valuesDelivery.fuel_tank.id)) {
        this.deleteShipment();
        this.editDelivery();
      } else {
        this.editDelivery();
      }

    }
  }

  createDelivery = () => {
    this.deliveryService.createDelivery(this.cadastrar.value)
    .subscribe(
      res => {
        this.valuesDelivery = res.results[0];
        this.toastService.success('Carregamento salvo com sucesso!');
      },
      err => {
        this.toastService.warning('Ops, houve uma falha ao tentar salvar o carregamento.');
      });

  }

  editDelivery = () => {
    this.deliveryService.updateDelivery(this.cadastrar.value)
    .subscribe(
      res => {
        this.toastService.success('Carregamento editado com sucesso!');
      },
      err => {
        this.toastService.warning('Ops, houve uma falha ao tentar editar o carregamento.');
        console.log(err);
      });

  }

  deleteShipment() {
    if (this.deleteShipmentsPumps()) {
      if (this.deleteShipmentFuelTank()) {
        if (this.deleteShipmentGasStation()) {
          setTimeout(() => {
            this.shipmentService.deleteShipment(this.shipmentId).subscribe(
              () => {
                this.toastService.success('Todas as informações de entrega foram excluidas conforme o solciitado');
                this.shipmentEvent.emit(false);
                return true;
              },
              err => {
                this.toastService.warning('Ops, houve alguma falha ao tentar deletar a entrega');
                console.log('erro ao deletar entrega', err);
                return false;
              }
            );
          }, 2000);
        }
      }
    }

  }

  deleteShipmentsPumps() {
    this.shipmentPumpService.getShipmentByDelivery(this.shipmentId).subscribe(
      res => {
        if (res.results.length) {
          const limit = res.results.length;
          let count = 1;
          res.results.forEach(element => {
            this.shipmentPumpService.deleteShipmentPump(element.id).subscribe(
              () => {
                count++;
                const msg = 'Dados da entrega da bomba foram deletados conforme o solicitado';
                this.toastService.info(msg);
                if (limit === count) {
                  return true;
                }
              },
              err => {
                // tslint:disable-next-line: max-line-length
                const msg = 'Ops, houve uma falha no processo de exclusão da entrega das bombas. Por favor consulte o administrador do sitema';
                this.toastService.warning(msg);
                console.log(err);
                return false;
              }
            );
          });
        }

        return true;
      },
      err => {
        const msg = 'Ops, parece que houve uma falha ao tentar buscar os dados de entrega de bomba para excluir';
        this.toastService.warning(msg);
        console.log(err);
        return false;
      });

    return true;
  }


  deleteShipmentFuelTank() {
    this.shipmentTankService.getShipmentByDelivery(this.shipmentId).subscribe(
      res => {
        res.results.forEach(element => {
          this.shipmentTankService.deleteShipmentTank(element.id).subscribe(
            () => {
              this.toastService.info('Dados da entrega do tanque foram deletados conforme o solicitado');
              return true;
            },
            err => {
              const msg = 'Ops, houve uma falha no processo de exclusão da entrega do tanque. Por favor consulte o administrador do sitema';
              this.toastService.warning(msg);
              console.log(err);
              return false;
            }
          );
        });

        return true;
      },
      err => {
        const msg = 'Ops, houve alguma falha ao tentar buscar os dados de entrega de tanque para deletar';
        this.toastService.warning(msg);
        console.log(err);
        return false;
      });

    return true;
  }

  deleteShipmentGasStation() {
    this.shipmentGasStationService.getShipmentByDelivery(this.shipmentId).subscribe(
      res => {
        res.results.forEach(element => {
          this.shipmentGasStationService.deleteShipmentGasStation(element.id).subscribe(
            () => {
              this.toastService.info('Dados da entrega da garagem foram deletados conforme o solicitado');
              return true;
            },
            err => {
              // tslint:disable-next-line: max-line-length
              const msg = 'Ops, houve uma falha no processo de exclusão da entrega da garagem. Por favor consulte o administrador do sitema';
              this.toastService.warning(msg);
              console.log(err);
              return false;
            }
          );
        });

        return true;
      },
      err => {
        const msg = 'Ops, houve alguma falha ao tentar buscar os dados da garagem para exclusão.';
        this.toastService.warning(msg);
        console.log(err);
      });

    return true;
  }



  editShipmentGasStationValues = (formDataGasStation) => {

    this.shipmentGasStationService.updateShipmentGasStation(formDataGasStation).subscribe(
      res => {
        const msg = 'O posto da entrega foi modificado com sucesso';
        this.toastService.success(msg);
      },
      err => {
        this.toastService.warning('Ops, ocorreu alguma falha ao tentar editar valor do posto');
      }
    );
  }

  editShipmentFuelTankValue = (formFuelTank) => {

    this.shipmentTankService.updateShipmentTank(formFuelTank).subscribe(
      res => {
        const msg = 'O tanque da entrega foi editado com sucesso';
        this.toastService.success(msg);
      },
      err => {
        const msg = 'Ops, ocorreu alguma falha ao tentar editar valor do tanque';
        this.toastService.warning(msg, err);
      }
    );
  }

  submitEmmiterButton = event => {
    this.validForm.emit(event);
  }

  eventSelected = (value, event) => {

    if (event === 'gas_station') {
      this.valueGasStation = value;
      this.valueFuelTank = null;
    } else if (event === 'product') {
      this.valueProduct = value;
    } else if (event === 'client') {
      this.valueClient = value;
      this.valueGasStation = null;
      this.valueProduct = null;
    } else if (event === 'fuelTank') {
      this.valueProduct = null;
      if (this.shipmentId && (this.valuesDelivery.fuel_tank.id !== value)) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
      }
      this.valueFuelTank = value.id;
      this.valueProduct = value.product;
    } else if (event === 'company') {
      this.valueCompany = value;
      this.valueGasStation = null;
    }

    if (this.valueClient && this.valueGasStation && this.valueFuelTank && this.valueProduct &&  this.valueCompany) {
      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        gas_station: this.valueGasStation,
        fuel_tank: this.valueFuelTank,
        product: this.valueProduct,
        company: this.valueCompany,
        delivery_date: this.cadastrar.value.delivery_date,
        volume: this.cadastrar.value.volume,
        cost: this.cadastrar.value.cost,
        status: this.cadastrar.value.status
      });
    }

    if (this.cadastrar.valid) {
      this.submitEmmiterButton(this.cadastrar.valid);
    }

  }


  getDateHour = (event) => {
    const dateForm = event ? event.dateEvent : null;

    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      gas_station: this.valueGasStation,
      fuel_tank: this.valueFuelTank,
      product: this.valueProduct,
      company: this.valueCompany,
      delivery_date: dateForm,
      volume: this.cadastrar.value.volume,
      cost: this.cadastrar.value.cost,
      status: this.cadastrar.value.status
    });

    this.submitEmmiterButton(this.cadastrar.valid);
  }


  eventShipment = (event) => {
    if (event ===  'delete') {
      this.deleteShipment();
    } else {
      this.registerShipment.emit(this.valuesDelivery);
    }
  }


  confirmAction = (action, description) => {

    const configs = {
      title: 'CONFIRME A AÇÃO',
      description,
      positive: 'SIM',
      negative: 'NÃO'
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = configs;
    const dialogRef = this.dialog.open(ConfirmActionComponent, dialogConfig);
    dialogRef.afterClosed()
      .subscribe((res) => {
        if (res.data && action === 'create') {
          this.eventShipment(action);
        } else if (res.data && action === 'delete') {
          this.deleteShipment();
        } else {
          console.log(res);
        }
      });
  }

}
