import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HeadTable } from 'src/app/models/configurations/head.model';

@Component({
  selector: 'cta-table-default',
  templateUrl: './table-default.component.html',
  styleUrls: ['./table-default.component.scss']
})
export class TableDefaultComponent implements OnInit {

  constructor() {
  }

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @Input() list: any[];
  @Input() search: string;
  @Input() page: number;
  @Input() maxPerPage: number = 100;
  @Input() headers: HeadTable[]
  @Input() configs: object;
  @Input() disableActionsEvent: boolean

  @Output() actionEvent = new EventEmitter();
  @Output() selectedsEvent = new EventEmitter();

  thead: any[] = [];
  propClicked: any;
  data: any[] = [];
  props: any[] = [];
  isChecked: object = {id: 0, checked: false}
  checkeds: any[] = [];
  allChecked = false;
  widthIcons: string;
  styleIcons: object;
  idxHover: number;

  ngOnInit() {

   this.convertList();
  }

  convertList = () => {

    const values = []
    if (this.list.length) {
      for (const item of this.list) {
        const propsIn = Object.keys(item);
        const myProps = [];
        values.push(item);
        propsIn.forEach(element => {
          if (!myProps.includes(element)) {
            myProps.push(element);
          }
        });
        this.props = myProps;
      }

      this.data = values;

    }
  }

  getChecked = (event: any, item: any) => {

    if (event.target.checked) {
      this.checkeds.push(item);
    } else {
      const position = this.checkeds.indexOf(item);
      this.checkeds.splice(position, 1);
    }

    this.selectedsEvent.emit(this.checkeds);

  }

  setAllChecked = (event: any) => {

    if(event.target.checked){
      this.checkeds = this.data.map(item => item.id)
    } else {
      for (const id of this.checkeds) {
        this.checkeds = [];
      }
    }

    this.selectedsEvent.emit(this.checkeds);
  }

  orderByElements = (head: any[]) => {
    // ordena o array conforme a propiedade passada
    const position = this.thead.indexOf(head);
    this.propClicked =  this.props[position + 1];
  }

  dispareAction = (action, item) => {
    const event = { action, item};
    this.actionEvent.emit(event);
  }


  hoverLineTable = (event, indiceTr) => {
    if (event.clientY && event.clientX) {
      this.idxHover = indiceTr;
      let bkgColorIcon = '#ffffff';
      if (((indiceTr + 1) % 2) !== 0) {
         bkgColorIcon = '#eeeeee';
      }
      this.styleIcons = {
        backgroundColor: bkgColorIcon
      };
    }
  }

  hideActionsTable = () => {
    this.styleIcons = null;
  }
}
