import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'cta-md-form-driver',
  templateUrl: './md-form-driver.component.html',
  styleUrls: ['./md-form-driver.component.scss']
})
export class MdFormDriverComponent {

  constructor(@Inject(MAT_DIALOG_DATA) data, public dialog: MatDialog) {
    console.log("DATA MODAL motorista", data);
    if(data){
      this.driver =  data.values ? data.values[0] : null;
      this.showButton = true;
    }
   }

  dispachForm: boolean;
  showButton: boolean;
  driver: any;


  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;

  }

}
