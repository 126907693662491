import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShipmentPumpService } from 'src/app/services/shipments/shipment-pump.service';
import { DeliveriesService } from 'src/app/services/deliveries/deliveries.service';
import { ShipmentService } from 'src/app/services/shipments/shipment.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { TanksService } from 'src/app/services/tanks/tanks.service';
import { PumpsService } from 'src/app/services/pumps/pumps.service';


@Component({
  selector: 'cta-form-shipment',
  templateUrl: './form-shipment.component.html',
  styleUrls: ['./form-shipment.component.scss']
})
export class FormShipmentComponent implements OnInit, OnChanges {

  @Input() shipmentId: number;
  @Input() deliveryId: number;
  @Output() eventShipment = new EventEmitter()
  cadastrar: FormGroup;
  valuesDelivery: any;
  tabSelected: any;
  shipmentsPumps: any[] = [];
  shipmentValue: any;
  dataShipmentRegister: any;
  idDriver: number;
  constructor(
    private fb: FormBuilder,
    private pumpShipmentService: ShipmentPumpService,
    private deliveryService: DeliveriesService,
    private shipmentService: ShipmentService,
    private pumpsService: PumpsService,
    private toastrService: ToastrService

  ) {

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      date_hour: this.fb.control(null, [ Validators.required]),
      delivery: this.fb.control(this.deliveryId, [Validators.required]),
      distributor_driver: this.fb.control(null, [Validators.required]),
      status: this.fb.control(2)
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDeliveryId();
  }

  ngOnInit() {
    this.tabSelected = 'carregamento'
    this.getDeliveryId();
    this.getValuesShipmentPump();

  }

  selectedTypeImages = (select: String) => {
    this.tabSelected = select;
    switch (select) {
      case 'tanque':

        break;
      case 'encerrante':
        this.getValuesShipmentPump()
        break;
      case 'panoramica':

        break;
      case 'carregamento':
        this.getDeliveryId();
        break;

      default:
        break;
    }
  }


  getValuesShipmentPump = () => {
    if(this.shipmentId){
      this.pumpShipmentService.getShipmentByDelivery(this.shipmentId).subscribe((res) => {
        console.log("RESPOSTA GET SHIPMENT PUMP", res.results);

        if(res.results.length){
          this.shipmentsPumps = res.results;
        }else{
          this.getPumpsFueltank();
        }
      },
        err => {
          console.log("erro ao tentar fazer o get dos dados de entrega de tanque");
        }
      )
    }

  }

  getPumpsFueltank = () => {
    this.pumpsService.getPumpByTank(this.valuesDelivery.fuel_tank.id).subscribe(
      res => {
        this.shipmentsPumps = res.results.map(item => ({
          id: null,
          pump: {id: item.id, name: item.name,}
        }));

      },
      err => {
        console.log("erro get tank by id in form shipment", err);
      }
    )
  }


  getDeliveryId = () => {
    if (this.deliveryId) {
      this.deliveryService.getDelivery(this.deliveryId).subscribe(
        res => {
          this.valuesDelivery = res.results[0];
          if(res.results[0].shipment_delivery.length){
            this.cadastrar.setValue({
              id: res.results[0].shipment_delivery[0].id,
              date_hour: res.results[0].shipment_delivery[0].date_hour,
              delivery: res.results[0].id,
              distributor_driver: res.results[0].shipment_delivery[0].distributor_driver.id,
              status: 2
            });
          }

        },
        err => {

        }
      )
    }
  }


  submitValues = () => {

    if(this.shipmentId){
      this.shipmentService.updateShipment(this.cadastrar.value).subscribe(
        res => {
          this.toastrService.success("Entrega editada conforme o solicitado");
        },
        err => {
          this.toastrService.error("Ops, houve alguma falha ao tentar editar a entrega");
        }
      )
    }else{
      this.shipmentService.createShipment(this.cadastrar.value).subscribe(
        res => {
          this.shipmentId = res.results[0].id;
          this.toastrService.success("Entrega do carregamento registrada com sucesso!");
          this.tabSelected = 'tanque';
        },
        err => {
          this.toastrService.error("Ops, houve uma falha ao tentar registrar uma nova entrega");
        }
      )
    }


  }
  eventSelected = (idDriver) => {
    this.idDriver = idDriver;
    if(this.shipmentId){
      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        date_hour: this.cadastrar.value.date_hour,
        delivery: this.cadastrar.value.delivery,
        distributor_driver: idDriver,
        status: this.cadastrar.value.status
      });
    }else{
      this.cadastrar.setValue({
        id: null,
        date_hour: moment().unix(),
        delivery: this.deliveryId,
        distributor_driver: idDriver,
        status: 2
      });
    }

  }

  getDateHour = (event) => {
    const dateForm = event ? event.dateEvent : null;
    if(this.shipmentId){
      this.cadastrar.setValue({
        id: this.cadastrar.value.id,
        date_hour: dateForm,
        delivery: this.cadastrar.value.delivery,
        distributor_driver: this.cadastrar.value.distributor_driver,
        status: this.cadastrar.value.status
      });
    }else{
      this.cadastrar.setValue({
        id: null,
        date_hour: dateForm,
        delivery: this.deliveryId,
        distributor_driver: this.idDriver,
        status: 2
      });
    }

  }

  dispachEventShipment = (event) => {
    this.shipmentId = null;
  }

  registerShipment = (delivery) => {
    this.tabSelected = 'entrega';
    this.deliveryId = delivery.id;
    this.valuesDelivery = delivery;
    this.dataShipmentRegister = delivery;
  }


}
