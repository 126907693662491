import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MENU_CONFIGS } from '../../../data/menu-configs';
import { Menu } from 'src/app/models/menu';

@Component({
  selector: 'cta-responsive-navigation',
  templateUrl: './responsive-navigation.component.html',
  styleUrls: ['./responsive-navigation.component.scss']
})
export class ResponsiveNavigationComponent implements OnInit {

  activeSub: string;
  constructor() { }

  @Input() menus: Menu[];
  configs: any[] = MENU_CONFIGS;
  showTitles = true;
  isMobile: boolean;
  titleSelected: string;

  @Output() showMenuEvent  = new EventEmitter();
  @Output() showChangeUser  = new EventEmitter();
  ngOnInit() {
    this.showTitles = localStorage.getItem('viewFull') && localStorage.getItem('viewFull') === 'true' ? false : true;
    this.titleSelected = localStorage.getItem('itemSelected');
  }

  verifyIsMobile = () => {
    this.isMobile = window.innerWidth <= 780 ? true : false;
  }

  closeMenu = () => {
    this.showTitles = !this.showTitles;
    const showMenu = this.showTitles ? 'false' : 'true';
    localStorage.setItem('viewFull',  showMenu);
    this.showMenuEvent.emit(showMenu);
  }

  showSubmenu = (title) => {
    if (title === this.titleSelected) {
      this.titleSelected = null;
    } else {
      localStorage.setItem('itemSelected', title);
      this.titleSelected = title;
    }
  }

  setActiveLi = (link, sublink) => {
    this.activeSub = link.toLowerCase() + sublink.toLowerCase();;
  }

  changeUser = () => {
    this.showChangeUser.emit(true);
  }
}
