import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { now } from 'moment';

@Component({
  selector: 'cta-form-adjust-stock',
  templateUrl: './form-adjust-stock.component.html',
  styleUrls: ['./form-adjust-stock.component.scss']
})
export class FormAdjustStockComponent implements OnChanges, OnInit {

  cadastrar: FormGroup;
  data: any;
  @Input() title: any[];
  @Input() dispachForm: boolean;
  @Output() validForm = new EventEmitter();
  valueGasStation: number;
  valueTank: number;

  constructor(private fb: FormBuilder) {
    this.cadastrar = this.fb.group({
      gas_station: this.fb.control('', [Validators.required]),
      tank: this.fb.control('', [Validators.required]),
      volume: this.fb.control('', [Validators.required]),
      date: this.fb.control('', [Validators.required]),
      observation: this.fb.control('')
    });
 }

 ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      this.submitValues();
    }
  }

  ngOnInit() {
    this.cadastrar.setValue({
      gas_station: 1222,
      tank: 1222,
      volume: '',
      date: moment(now()).format('YYYY-MM-DDTHH:mm'),
      observation: ''
    });
   }

  submitValues = () => {
    console.log('event submit ', this.cadastrar.value);
  }

  submitEmmiterButton = event => {
    this.validForm.emit(event);
  }

  selectedEvents = (event, value) => {

    if (event === 'gas_station') {
      this.valueGasStation = value;
    } else if (event === 'tank') {
      this.valueTank = value;
    }

    if (this.valueGasStation && this.valueTank) {
      this.cadastrar.setValue({
        gas_station: this.valueGasStation,
        tank: this.valueTank,
        volume: this.cadastrar.value.volume,
        date: this.cadastrar.value.date,
        observation: this.cadastrar.value.observation
      });

      if (this.cadastrar.valid) {
        this.submitEmmiterButton(this.cadastrar.valid);
      }
    }
  }
}

