import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cta-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnChanges {

  cadastrar: FormGroup;
  data: any;
  @Input() title: any[];
  @Input() dispachForm: boolean;
  @Output() validForm = new EventEmitter();

  constructor(private fb: FormBuilder) {

    this.cadastrar = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      sobrenome: this.fb.control('', [Validators.required]),
      nomeCurto: this.fb.control('', [Validators.required]),
      codMotorista: this.fb.control('', [Validators.required]),
      rfid: this.fb.control(''),
      senha: this.fb.control('', [Validators.required]),
      empresa: this.fb.control('', [Validators.required]),
    });
 }


  ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      this.submitValues();
    }
  }

  submitValues = () => {
    console.log('event submit ', this.cadastrar.value);
  }

  submitEmmiterButton = event => {
    this.validForm.emit(event);
  }

}
