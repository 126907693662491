import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ShipmentGasStationService } from 'src/app/services/shipments/shipment-gasstation.service';
import { Lightbox } from 'ngx-lightbox';
import { URLS_DEFAULT } from 'src/app/configs/urls_default';

@Component({
  selector: 'cta-form-shipment-gasstation',
  templateUrl: './form-shipment-gasstation.component.html',
  styleUrls: ['./form-shipment-gasstation.component.scss']
})
export class FormShipmentGasstationComponent implements OnInit {


  cadastrar: FormGroup;
  @Input() shipmentId: number;
  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Input() valuesDelivery: any;
  @Output() validForm = new EventEmitter();

  valuesShipment: any;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imageGet: any;
  audioGet: any;
  constructor(
    private fb: FormBuilder,
    private toastService: ToastrService,
    private gasStationShipmentService: ShipmentGasStationService,
    private _lightbox: Lightbox
  ) {

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      image_file: this.fb.control(''),
      note: this.fb.control(''),
    });
  }

  private base64textString: String = "";

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview(){
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);

    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  handleFileSelect = (evt) => {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.cadastrar.setValue({
      id: this.cadastrar.value.id,
      image_file: this.base64textString,
      note: this.cadastrar.value.note,
    });

    console.log(this.cadastrar.value);
  }

  submitEmmiterButton = event => {
    if (event) {
      this.validForm.emit(event);
    }
  }


  submitValues = () => {
    const  dataForm = {
      id: this.cadastrar.value.id,
      note: this.cadastrar.value.note,
      occupation: this.cadastrar.value.occupation
    }
    if(this.valuesShipment){
      this.gasStationShipmentService
      .update(this.previewUrl ? this.cadastrar.value : dataForm)
      .subscribe(
        () => {
          this.toastService.toastrConfig.positionClass = 'toast-top-center'
          this.toastService.success("Sucesso ao editar os dados de entrega da garagem")
        },
        err => {
          this.toastService.warning("Ocorreu uma falha ao tentar editar os dados de entrega da garagem");
        }
      )
    }else{
      const form_data = {
        note: this.cadastrar.value.note,
        occupation: this.cadastrar.value.occupation,
        image_file: this.previewUrl ? this.cadastrar.value.image_file : null,
        shipment_delivery: this.shipmentId,
        audio_file: null,
        gas_station: this.valuesDelivery.fuel_tank.gas_station.id

      }
      this.gasStationShipmentService.createShipmentGasStation(form_data).subscribe(
        res => {
          this.toastService.success("Sucesso ao cadastrar os dados de entrega da garagem");
        },
        err => {
          this.toastService.warning("Ops, parece que falhamos ao tentar cadastrar os dados de entrega da garagem");
        }
      )
    }

  }

  getValuesShipmentTank = () => {

    this.gasStationShipmentService.getShipmentByDelivery(this.shipmentId).subscribe((res) => {
      if(res.results.length){
        const value = res.results[0];
        this.valuesShipment = {
          id: value.id,
          note: value.note,
          audio_file: value.audio_file,
          image_file: value.image_file
        }
        this.imageGet = value.image_file !== null && value.image_file !== undefined ? `${URLS_DEFAULT.URL_CURRENT}/${value.image_file}` : null
        this.audioGet = value.audio_file !== null && value.audio_file !== undefined ? `${URLS_DEFAULT.URL_CURRENT}/${value.audio_file}` : null
        this.setValuesShipment();
      }

    },
      err => {
        console.log("erro ao tentar fazer o get dos dados de entrega da garagem");
      }
    )
  }

  ngOnInit() {
    if (this.shipmentId) {
      this.getValuesShipmentTank();
    }
  }

  setValuesShipment = () => {

    this.cadastrar.setValue({
      id: this.valuesShipment.id,
      image_file: this.valuesShipment.image_file ? `${URLS_DEFAULT.URL_CURRENT}/${this.valuesShipment.image_file}` : null,
      note: this.valuesShipment.note,
    })
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar && this.cadastrar.valid) {
        this.submitValues();
      }
    }
  }

  showImage(): void {
    const images = [
      {
        src: this.imageGet,
        caption: 'Imagem Garagem',
        thumb: this.imageGet
      }
    ];
    this._lightbox.open(images, 0);
  }

}
