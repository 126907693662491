import { Component, Input, Output, Inject } from '@angular/core';
import { EventEmitter } from 'events';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cta-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent {

  constructor(private dialogRef: MatDialogRef<DialogContentComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
   }

  data: any;
  @Input() title: any[];
  @Output() actionButton = new EventEmitter();

  submitActionButton = event => {
    this.actionButton.emit(event);
  }

}
