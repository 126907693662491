import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListDriversComponent } from './views/list/list.component';
import { CreateComponent } from './views/create/create.component';
import { ListDriversTrrComponent } from './views/trr/list-drivers-trr/list-drivers-trr.component';

const routes: Routes = [
  {path: '', component: ListDriversTrrComponent},
  {path: 'listar', component: ListDriversTrrComponent},
  {path: 'criar', component: CreateComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriversRoutingsModule { }
