import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ModalConfirm } from 'src/app/models/modal-confirm';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FormModalComponent } from '../form-modal/form-modal.component';
import { DriverService } from 'src/app/services/drivers/driver-service';
import * as moment from 'moment';
@Component({
  selector: 'cta-list-drivers',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListDriversComponent implements OnInit {

  constructor(public dialog: MatDialog, private driverService: DriverService) { }
  @Output() selectedsEvent = new EventEmitter();
  dataModal: ModalConfirm[];
  drivers: any[];
  headersTable: object[];
  topButtons: object[];
  configsTable: any[];
  configsModal: any;
  showMd: boolean;

  ngOnInit() {
    this.getConfigsDrivers();
    this.getHeaders();
    this.getList();
  }

  getList() {
    this.driverService.getDrivers().subscribe(
      res => {

        console.log('res.results drivers:::  ;', res.results)
        const drivers = res.results.map(item => ({
          id: item.id,
          motorista: item.license,
          senha: item.description,
          ultimoVeiculo: item.fleet,
          ultimoAbastecimento: moment(item.last_update).format("DD/MM/YYYY"),
          horasTrabalhadas: moment(item.last_update).format("hh:mm:ss") ,
          volumeTotalAbastecido: item.category.id,
          nrCartao: item.model.name,
          mediaKm: item.id,
          mediaLh: item.company.cnpj,
          empresa: item.company.name
        }))

        this.drivers = drivers;

      },
      error => {
        console.log(error);
      }
    )

  }

  getConfigsDrivers() {
    this.driverService.getConfigs().subscribe(
      res => {
        const { configsTable, dataModal, topButtons, configsModal } = res.results[0].json;
        this.dataModal = dataModal;
        this.topButtons = topButtons;
        this.configsTable = configsTable;
        this.configsModal = configsModal;
      },
      err => {
        console.log('drivers configs error', err)
      }
    )
  }

  getHeaders = () => {
    this.driverService.getHeaders().subscribe(
      res => {
        const headers = res.results[0].json.headers.map(item => ({
          id: item.id,
          title: item.title
        }))
        this.headersTable = headers;
      },
      err => {
        console.log('err configs: ', err);
      }
    )
  }

  actionEvent = (event) => {

    const action =  event.hasOwnProperty('action') ? event.action : event;
    switch (action) {
      case 'edit':
        this.driverService.getDriversById(event.item.id).subscribe(
          res => {
            this.create(res.results);
          },
          err => {
            console.log(err);
          }
        );
        break;
      case 'create':
        this.create([]);
        break
      default:
        console.log('no action');
        break;
    }

  }

  setSelecteds = event => {
    this.selectedsEvent.emit(event);
  }

  create(values: any) {

    let configs = {
      title: null,
      description: '',
      function: 'create',
      button: 'Salvar',
      buttonPlus: null,
      values: values.length ? values : []
    }

    this.configsModal.forEach(element => {

      if(element.function === 'create' && !values.length){
        configs.title = element.title
        configs.description = element.description
        configs.function = element.function
        configs.buttonPlus = element.buttonPlus
        configs.button = element.button
      }
      if(element.function === 'edit' && values.length){
        configs.title = element.title
        configs.description = element.description
        configs.function = element.function
        configs.function = element.button
      }
    });


    console.log('news configs ', configs);


    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = configs;

    const dialogRef = this.dialog.open(FormModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log('Quando fechar o modal faz...', false);
    });
  }

}
