import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';


@Component({
  selector: 'cta-show-filters-deliveries',
  templateUrl: './show-filters-deliveries.component.html',
  styleUrls: ['./show-filters-deliveries.component.scss']
})
export class ShowFiltersDeliveriesComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() filtersSelected;
  @Output() clear = new EventEmitter();
  filters: any[] = [];
  ngOnInit() {
    this.filters = this.uniqui(this.filtersSelected)
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.filters = this.uniqui(this.filtersSelected)
  }

  uniqui(filters) {
    const dataFilters = [];
    const data= [];
    console.log("FILTERS IN SHOW FILTERS");
    console.log(filters);
    filters.forEach(element => {
      if(!dataFilters.includes(element.name)){
        dataFilters.push(element.name);
        data.push(element);
      }
    });

    return data;
  }

  formatDate(UNIX_timestamp) {

    let a = new Date(UNIX_timestamp / 1000 * 1000);
    let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ag', 'Set', 'Out', 'Nov', 'Dez'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  clearFilters(filter){
    this.clear.emit(filter);
  }
}
