import { Component, Input, Output, Inject } from '@angular/core';
import { EventEmitter } from 'events';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cta-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent  {

  constructor(private dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
   }

   data: any;
   @Input() title: any[];
   @Output() actionButton = new EventEmitter();


   submitActionButton = event => {
     this.actionButton.emit(event);
   }

}
