import { Injectable } from '@angular/core';
import { Crud } from '../models/interfaces/crud.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {URLS_DEFAULT} from '../configs/urls_default';
import { RestModel } from '../models/configurations/rest.model';


@Injectable({
  providedIn: 'root'
})

export class CrudService implements Crud {

  tokenUser = null
  tokenRest = null
  currentUser = null
  headerOptions = null

  protected apiPath: string;
  private urlPathern = `${URLS_DEFAULT.BASE_URL}`;
  constructor(protected http: HttpClient) {

    this.tokenUser = sessionStorage.getItem('tokenUser');
    this.tokenRest = sessionStorage.getItem('tokenRest');
    this.currentUser = sessionStorage.getItem('currentUser');
    this.headerOptions = { headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':`bearer ${this.tokenRest}`,
      'user_token': this.tokenUser
    })};

  }

  getAll(): Observable<RestModel> {
    return this.http.get(`${this.urlPathern}${this.apiPath}`, this.headerOptions)
    .pipe(
      catchError(this.handleError),
      map(this.jsonDataToEntity)
    );
  }

 findItem(): Observable<RestModel> {
    const url = this.urlPathern + this.apiPath

    return this.http.get(url, this.headerOptions)
      .pipe(
        catchError(this.handleError),
        map(this.jsonDataToEntity)
      );

 }

  getTable(): Observable<any> {
    const url = `${this.urlPathern}/table`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      map(this.jsonDataToEntity)
    );
  }

  getById(id: number): Observable<any> {
    const url = `${this.urlPathern}${this.apiPath}/${id}`;
    return this.http.get(url, this.headerOptions)
    .pipe(
      catchError(this.handleError),
      map(this.jsonDataToEntity)
    );
  }

  getCep(cep: string): Observable<any> {
   
    let url = `https://viacep.com.br/ws/${cep}/json/`;
    return this.http.get(url)
      .pipe(      
        catchError(this.handleError),
        map(data => data)
    );
  }

  getCnpj(cnpj: string): Observable<any> {
    const headerApiCnpj = {
      headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Content-Type': 'application/json',
      'Authorization':`bearer 7c3668c0167e73fe2e33134aaf5db5082eae7143502ca64c03d1cfaa8bdcee98`,
    })};
   // 7c3668c0167e73fe2e33134aaf5db5082eae7143502ca64c03d1cfaa8bdcee98
    let url = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;
    return this.http.jsonp(url, "callback")
      .pipe(      
        catchError(this.handleError),
        map(data => data)
    );
  }

  create(data: any): Observable<any> {
    const url = `${this.urlPathern}${this.apiPath}`;
    return this.http.post(url, data, this.headerOptions).pipe(
      tap(() => data),
      catchError(this.handleError),
    );
  }

  update(data: any): Observable<any> {
    const url = `${this.urlPathern}${this.apiPath}`;
    return this.http.put(url, data, this.headerOptions).pipe(
      tap(() => data),
      catchError(this.handleError),
    );
  }

  delete(data: any): Observable<any> {
    const id = typeof data === 'number' ? data : data.id;
    const url = `${this.urlPathern}${this.apiPath}/${id}`
    return this.http.delete<any>(url, this.headerOptions).pipe(
      tap(_ => console.log(`deleted element id=${id}`)),
      catchError(this.handleError)
    );
  }

  private jsonDataToEntityAll(jsonData: any[]): any[] {
    const data: any[] = [];
    jsonData.forEach(element => data.push(element as any));
    return data;
  }

  private jsonDataToEntity(jsonData: any): any {
    return jsonData as any;
  }

  private handleError(error: any[]): Observable<any> {
    console.log('erro na requisição => ', error);
    return throwError(error);
  }
}
