
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrudService } from '../crud.service';
import { URLS_DEFAULT } from '../../configs/urls_default';
@Injectable({
    providedIn: 'root'
})
export class MenuPreferencesService extends CrudService {
    constructor(http: HttpClient) {
        super(http);
        this.apiPath = URLS_DEFAULT.MENU_PREFERENCES;
    }
    
    getMenu(): Observable<any> {
        return this.findItem();
    }
}
