import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfFileComponent } from './pdf-file/pdf-file.component';
import { ExcelFileComponent } from './excel-file/excel-file.component';

@NgModule({
  declarations: [PdfFileComponent, ExcelFileComponent],
  imports: [
    CommonModule
  ],
  exports: [PdfFileComponent, ExcelFileComponent]
})
export class GenerateFilesModule { }
