import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DriverService } from 'src/app/services/drivers/driver-service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'cta-form-driver',
  templateUrl: './form-driver.component.html',
  styleUrls: ['./form-driver.component.scss']
})
export class FormDriverComponent implements OnInit {

  cadastrar: FormGroup;

  @Input() dispachForm: boolean;
  @Input() showButton: boolean;
  @Input() driver: any;
  @Output() validForm = new EventEmitter();

  user: any;
  distributor: any;
  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private userService: UsersService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {

    const user = sessionStorage.getItem('currentUser');
    const dataUser = JSON.parse(user);
    const distributor_id = dataUser[0]['id'];
    this.distributor = distributor_id

    this.cadastrar = this.fb.group({
      id: this.fb.control(null),
      name: this.fb.control(null, [Validators.required, Validators.minLength(3)]),
      cpf: this.fb.control(null, [Validators.minLength(11)]),
      login: this.fb.control(null, [Validators.required, Validators.minLength(4)]),
      password: this.fb.control(null, this.driver ? [Validators.minLength(4)] : [Validators.required, Validators.minLength(4)]),
      distributor: this.distributor
    })

    if (this.driver) {

      this.userService.getUserCategoryId(this.driver.id).subscribe(
        res => {

          this.user = res.results[0];
          console.log("USER IN FORM DRIVER", this.user);
        },
        err => {
          console.log("erro ao tentar buscar os dados de usuário do motorista em form driver component")
        }
      );
      setTimeout(() => {
        if(this.user){
          this.cadastrar.setValue({
            id: this.driver.id,
            name: this.driver.name ? this.driver.name : null,
            cpf: this.driver.cpf ? this.driver.cpf : null,
            login: this.user.login ? this.user.login : null,
            password: this.user.password ? this.user.password : null,
            distributor: this.distributor
          });
        }
      }, 2000);


    }

  }

  submitEmmiterButton = event => {
    this.validForm.emit(this.cadastrar.valid);
  }

  submitValues = () => {
    if (this.driver) {
      const dataDriver = {
        id: this.cadastrar.value.id,
        name: this.cadastrar.value.name,
        cpf: this.cadastrar.value.cpf,
        distributor: this.distributor,

      }
      this.driverService.updateDriverTrr(dataDriver).subscribe(
        res => {
          this.submitUser(res.results[0].id);
        },
        err => {
          console.log('err Ocorreu uma falha ao tentar editar o motorista', err);
          this.toastService.error("Ops! Ocorreu uma falha ao tentar editar o motorista")
        }
      );
    } else {
      const dataDriver = {
        name: this.cadastrar.value.name,
        cpf: this.cadastrar.value.cpf,
        distributor: this.distributor
      }
      this.driverService.createDriverTrr(dataDriver).subscribe(
        res => {
          this.submitUser(res.results[0].id);
        },
        err => {
          console.log('err Ocorreu uma falha ao tentar salvar o motorista', err);
          this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o motorista")
        }
      );
    }
  }

  submitUser = (idDriver) => {


    if (this.driver) {

      const dataUser = this.cadastrar.value.password ? {
        id: this.user.id,
        categoria_id: idDriver,
        login: this.cadastrar.value.login,
        password: this.cadastrar.value.password,
        name: this.cadastrar.value.name,
        category: 'MOTORISTA',

      } : {
        id: this.user.id,
        categoria_id: idDriver,
        login: this.cadastrar.value.login,
        name: this.cadastrar.value.name,
        category: 'MOTORISTA'
      }

      this.userService.updateUser(dataUser).subscribe(
        res => {
          this.toastService.success("Edição de motorista realizada conforme o solicitado")
        },
        err => {
          console.log('err');
          this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o usuário motorista")
        }
      );
    } else {
      const dataUser = {
        categoria_id: idDriver,
        login: this.cadastrar.value.login,
        password: this.cadastrar.value.password,
        name: this.cadastrar.value.name,
        category: 'MOTORISTA'
      }

      this.userService.createUser(dataUser).subscribe(
        res => {
          this.toastService.success("Motorista cadastrado com sucesso")
        },
        err => {
          console.log('err');
          this.toastService.error("Ops! Ocorreu uma falha ao tentar salvar o usuario do motorista")
        }
      );
    }
  }


  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['dispachForm']) {
      if (this.cadastrar && this.cadastrar.valid) {
        this.submitValues();
      }
    }
  }

  eventFire = event => {
    this.showButton = event;
  }

  dispachFormEvent = () => {
    this.dispachForm = true;
  }

  selectedEvents = (event, value) => {

  }

}


